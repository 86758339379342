import BtnDenunciarUnSiniestro from "./Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";
import Footer from "./Global/Footer/Footer";
import LegalesSeguros from "./LegalesSeguros/LegalesSeguros";
import { ModalContratarTecno } from "./ModalContratarTecno/ModalContratarTecno";

const FooterTecno = ({ showModal, handleModal }) => {
  return (
    <>
      <LegalesSeguros tipo="proteccion-inteligente" />
      <Footer />
      <BtnDenunciarUnSiniestro />
      <ModalContratarTecno estadoBoton={showModal} handleModal={handleModal} />
    </>
  );
};

export default FooterTecno;
