import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Container } from "react-bootstrap";
import { getCategoriesFaq, getSubcategoriesFaq } from "../../services/faqServices";
import Loading from "../Global/Loading/Loading";
import { CardsContenido } from "./CardsContenido";

export const ContenedorPreguntas = () => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);

  const handleCallApi = async () => {
    setLoading(true);

    await getCategoriesFaq()
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        setError(err);
      });

    await getSubcategoriesFaq()
      .then((res) => {
        setSubcategories(res.data);
      })
      .catch((err) => {
        setError(err);
      });

    setLoading(false);
  };

  useEffect(() => {
    handleCallApi();
  }, []);

  return (
    <Container className="contenedorContenedorPreguntas">
      {loading ? (
        <Loading className="mt-5 mb-5" />
      ) : (
        <section>
          {categories &&
            categories.length > 0 &&
            categories
              .sort((a, b) => a.attributes.Orden - b.attributes.Orden)
              .map((cat, index) => (
                <div className="itemsCardsContendo" key={index}>
                  <div className="itemsCardsContendo-title">
                    <h4>{cat.attributes.Nombre}</h4>
                  </div>
                  {
                    //ACA MAPEO LAS SUBCATS (FALTA FILTRAR POR CAT)
                  }
                  {subcategories &&
                    subcategories.length > 0 &&
                    subcategories
                      .sort((a, b) => a.attributes.Orden - b.attributes.Orden)
                      .filter((subcat) => subcat.attributes.Categoria.data.id === cat.id)
                      .map((subcat, index) => (
                        <div className="itemsCardsContendo-body">
                          <CardsContenido
                            cat={cat.attributes.Nombre}
                            key={index}
                            subtitle={subcat.attributes.Nombre}
                            description={subcat.attributes.Descripcion}
                            direccion={`/preguntas-frecuentes/${cat.attributes.Slug}/${subcat.attributes.Nombre}`}
                          />
                        </div>
                      ))}
                </div>
              ))}
        </section>
      )}
    </Container>
  );
};
