import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import IconCheck from "../../../../assets/images/VidaYsalud/Vida/Asistencias/IconCheck.svg";
import IconArrowBoton from "../../../../assets/images/VidaYsalud/Vida/Asistencias/3.svg";

import IconClose from "../../../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/IconClose.svg";

// Primera Columna
import IconPrincipal1 from "../../../../assets/images/VidaYsalud/Vida/Asistencias/primera/IconPrincipal1.svg";

import Btn1 from "../../../../assets/images/VidaYsalud/Vida/Asistencias/primera/1.svg";
import Btn2 from "../../../../assets/images/VidaYsalud/Vida/Asistencias/primera/2.png";

// Segunda Columna
import IconPrincipal2 from "../../../../assets/images/VidaYsalud/Vida/Asistencias/segunda/IconPrincipal2.svg";
import Btn4 from "../../../../assets/images/VidaYsalud/Vida/Asistencias/segunda/1.svg";
import Btn5 from "../../../../assets/images/VidaYsalud/Vida/Asistencias/segunda/ua1.png";

// Icons Modal
import LogoModal1 from "../../../../assets/images/VidaYsalud/Vida/Asistencias/modal/LogoModal1.png";
import LogoModal2 from "../../../../assets/images/VidaYsalud/Vida/Asistencias/modal/LogoModal2.png";
import AccordionAsistencias from "../../../AccordionAsistencias/AccordionAsistencias";
import { dataAsistencias } from "../../../../utils/resources/nuevasAsistencias";

export const Asistencias = ({ data }) => {
  const [contenidoModal, setContenidoModal] = useState({
    odontologiaYsalud: false,
    asistencias: false,
  });

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setContenidoModal({
      odontologiaYsalud: false,
      asistencias: false,
    });
    setShow(false);
  };
  const handleShow = (tipo) => {
    setContenidoModal({
      ...contenidoModal,
      [tipo]: true,
    });
    setShow(true);
  };

  return (
    <div>
      <section className="contenedorAsistenciasVida">
        <h2>Además, segun el plan contás siempre con estas asistencias</h2>
        <div className="contenedorAsistenciasVida-columnas">
          <div className="contenedorAsistenciasVida-columnas-primeraColumna">
            <div className="contenedorAsistenciasVida-columnas-primeraColumna-title">
              <h3>
                {" "}
                <img src={IconPrincipal1} alt="Icon" /> Asistencias de Salud y Odontología{" "}
              </h3>
            </div>
            <div className="contenedorAsistenciasVida-columnas-primeraColumna-items">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <img src={IconCheck} alt="Icon" /> Videollamada Médica
                  </Accordion.Header>
                  <Accordion.Body>
                    Incluye Video Consulta Médica con acceso a un profesional médico exclusivamente en la especialidad
                    de Clínica Médica.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <img src={IconCheck} alt="Icon" /> Descuentos del 50% en medicamentos recetados
                  </Accordion.Header>
                  <Accordion.Body>
                    El beneficio del 50% de descuento no es acumulativo a cualquier otro descuento relacionado con las
                    obras sociales, prepagas, etc.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <img src={IconCheck} alt="Icon" /> Reintegro de Medicamentos en caso de lesiones por accidente
                  </Accordion.Header>
                  <Accordion.Body>
                    En caso de accidentes, si el cliente sufriera lesiones físicas o traumatismos, y por tal motivo le
                    receten medicamentos, IGS a solicitud del cliente gestionará y cubrirá el costo de los medicamentos
                    recetados (ver condiciones).
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <img src={IconCheck} alt="Icon" /> Asistencia nutricional telefónica
                  </Accordion.Header>
                  <Accordion.Body>
                    Incluye consultas del cuadro nutricional de los alimentos, índice de grasa corporal, dietas y
                    referencia de nutricionistas, recomendaciones para llevar una alimentación sana: sugerencias para
                    diabéticos, vegetarianos, veganos, intolerancia a la lactosa.",
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <img src={IconCheck} alt="Icon" /> Asistencia Odontológica
                  </Accordion.Header>
                  <Accordion.Body>Incluye diagnóstico y examen médico, radiografías y extracciones.</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <img src={IconCheck} alt="Icon" /> Exámenes preventivos
                  </Accordion.Header>
                  <Accordion.Body>
                    Incluye un chequeo médico general por año.Además, contás con la posibilidad de realizarte exámenes
                    preventivos como mamografía y citología.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {/* <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Videollamada Médica
              </p>
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Descuentos del 50% en medicamentos recetados
              </p>{" "}
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Reintegro de Medicamentos en caso de lesiones por accidente
              </p>
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Asistencia nutricional telefónica
              </p>
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Asistencia Odontológica
              </p>{" "}
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Exámenes preventivos
              </p> */}
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Servicio programado de médico a domicilio.
              </p>{" "}
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Chequeo médico anual.
              </p>
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Examen preventivo de mamografía.
              </p>{" "}
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Examen preventivo de citología (Papanicolau).
              </p>
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Asistencia Psicológica Telefónica
              </p>{" "}
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Servicio de acompañante nocturno por internación
              </p>{" "}
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Alquiler Ortopedia
              </p>{" "}
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Internación Domiciliaria
              </p>{" "}
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Óptica Lentes recetados
              </p>
              {/* <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Servicio programado de médico a domicilio.
              </p>
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Servicio programado de enfermero a domicilio.
              </p>
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Descuentos del 50% en medicamentos recetados.
              </p>
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Chequeo médico anual.
              </p>
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Examen preventivo de mamografía.
              </p>
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Examen preventivo de citología (Papanicolau).
              </p>
              <p>
                {" "}
                <img src={IconCheck} alt="Icon" /> Asistencia odontológica ante emergencia o dolor agudo.
              </p> */}
            </div>
            <button className="contenedorAsistenciasVida-buttons" onClick={() => handleShow("odontologiaYsalud")}>
              <div>
                <img src={Btn1} alt="Icon" />
                <img src={Btn2} alt="Icon" className="iconMedio" />
                <p>
                  Teléfonos para <br /> solicitar asistencias
                </p>
              </div>
              <img src={IconArrowBoton} alt="Icon" />
            </button>
          </div>

          <div className="contenedorAsistenciasVida-columnas-segundaColumna">
            <div className="contenedorAsistenciasVida-columnas-segundaColumna-title">
              <img src={IconPrincipal2} alt="Icon" />
              <div>
                <h3> Asistencias en viaje de hasta 30 días </h3>
                <p className="fw-bold red-zs">(nacional e internacional)</p>
              </div>
            </div>
            <div className="contenedorAsistenciasVida-columnas-segundaColumna-items">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <img src={IconCheck} alt="Icon" /> Asistencia médica
                  </Accordion.Header>
                  <Accordion.Body>Tenés protección en caso de sufrir alguna enfermedad o accidente</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <img src={IconCheck} alt="Icon" /> Urgencia odontológica y oftalmológica
                  </Accordion.Header>
                  <Accordion.Body>
                    Contás con tratamiento de urgencia ante dolor, trauma, infección y extracción de la pieza dentaria
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <img src={IconCheck} alt="Icon" /> Prolongación de estadía por enfermedad o accidente
                  </Accordion.Header>
                  <Accordion.Body>
                    Te ayuda con los gastos de hotelería en caso que tengas que quedarte más tiempo del previsto por
                    alguna enfermedad o accidente. Plazo máximo diez (10) días
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <img src={IconCheck} alt="Icon" /> Reintegro de gasto por cancelación o interrupción de viaje por
                    COVID +
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        Gastos de alojamiento hasta por 14 días, en caso que decidieras cumplir la cuarentena dentro del
                        hotel en el que te alojabas al momento de contraer COVID.
                      </li>

                      <li>Gastos de alimentación durante el período de cuarentena de hasta 14 días.</li>
                      <li>Gastos de cancelación de viaje ante COVID Positivo.</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <img src={IconCheck} alt="Icon" /> Regreso anticipado
                  </Accordion.Header>
                  <Accordion.Body>
                    Si necesitas volver antes que finalice tu viaje por cuestiones médicas, fallecimiento de algún
                    familiar directo o alguna situación imprevista** que haya ocurrido en tu hogar, te reintegramos la
                    diferencia que tengas que pagar por la modificación de la fecha de regreso
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <img src={IconCheck} alt="Icon" /> Traslado de beneficiarios o familiares por ciertos motivos
                  </Accordion.Header>
                  <Accordion.Body>
                    En casos de emergencia, AXA Assistance organizará el traslado al centro asistencial más próximo para
                    que el beneficiario enfermo reciba atención médica. También incluye el traslado de un familiar
                    cercano en caso de hospitalización o aislamiento del titular del seguro
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    <img src={IconCheck} alt="Icon" /> Equipaje y pasaporte protegido
                  </Accordion.Header>
                  <Accordion.Body>
                    Te ayudamos con la localización de equipaje o la compensación económica por su extravío. Además, si
                    se pierde tu pasaporte te reintegran el gasto de reposición
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <button
              className="contenedorAsistenciasVida-buttons"
              onClick={() => handleShow("asistencias")}
              style={{ padding: "20px 10px" }}
            >
              <div>
                <img src={Btn4} alt="Icon" />
                <img src={Btn5} alt="Icon" className="iconMedio" style={{ width: "100px" }} />
                <p>
                  Teléfonos para <br /> solicitar asistencias
                </p>
              </div>
              <img src={IconArrowBoton} alt="Icon" />
            </button>
          </div>
        </div>
        <AccordionAsistencias data={dataAsistencias[0].vida} />
      </section>
      {contenidoModal.odontologiaYsalud && (
        <Modal show={show} onHide={handleClose} className="modalParaOpcionC" centered>
          <div className="headerPersonalizadoModalAsistencias">
            <h2>léfonos para solicitar asistencias</h2>
            <img src={IconClose} alt="Icon Close" onClick={handleClose} />
          </div>

          <div className="bodyPersonalizadoModalAsistencias">
            <div className="bodyPersonalizadoModalAsistencias-tel">
              <a href="tel:08004440313">0800 - 444 - 0313</a>
            </div>
            <div>
              <img src={LogoModal1} alt="Icon Logo" />
            </div>
          </div>
        </Modal>
      )}
      {contenidoModal.asistencias && (
        <Modal show={show} onHide={handleClose} className="modalParaOpcionC" centered>
          <div className="headerPersonalizadoModalAsistencias">
            <h2>Estos son los teléfonos para solicitar asistencia al viajero</h2>
            <img src={IconClose} alt="Icon Close" onClick={handleClose} />
          </div>

          <div className="bodyPersonalizadoModalAsistencias">
            <div className="bodyPersonalizadoModalAsistencias-tel">
              <div>
                <a href="tel:0800333148">0800 - 333 - 1489</a>
                <a href="tel:01143786037">011 - 4378 - 6037</a>
              </div>
            </div>
            <div>
              <img src={LogoModal2} alt="Icon Logo" />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
