import React from "react";
import { Link } from "react-router-dom";

import IconCheveronRight from "../../assets/images/PreguntasFrecuentes/IconCheveronRight.svg";
import IconPlusSm from "../../assets/images/PreguntasFrecuentes/IconPlusSm.svg";

export const CardsContenido = ({ cat, subtitle, description, direccion }) => {
  return (
    <div className="contenedorCardsContenidoPreguntasFrecuentes">
      <Link to={direccion} className="contenedorCardsContenidoPreguntasFrecuentes-body">
        <div className="d-flex justify-content-start">
          <div className="d-flex flex-grow-1">
            <img src={IconPlusSm} alt="Icon Arrow Plus" width={35} height={35} className="d-block" />
          </div>
          <div className="contenedorCardsContenidoPreguntasFrecuentes-description flex-grow-2 d-flex justify-content-center align-content-center align-items-start flex-column">
            <h5 className="mb-0 text-start"> {subtitle} </h5>
            {description && description !== "null" && <p className="mt-2"> {description} </p>}
          </div>
        </div>

        <div>
          <img src={IconCheveronRight} alt="Icon Arrow Right" />
        </div>
      </Link>
    </div>
  );
};
