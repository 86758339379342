import React from "react";
import { Accordion, Col } from "react-bootstrap";

export const CardNuestrosAportes = ({ imagen, titulo, parrafo, direccion }) => {
  return (
    <Col className="d-block pb-5">
      <div className="contenedorCardNuestrosAportes">
        <a href={direccion} target={"_blank"} style={{ textDecoration: "none" }} rel="noreferrer">
          <div className="contenedorCardNuestrosAportes-title">
            <img src={imagen} alt="Icon" />
          </div>
        </a>
        <div className="contenedorCardNuestrosAportes-body p-0">
          <Accordion>
            <Accordion.Item eventKey="0" style={{ border: "0" }}>
              <Accordion.Header>
                {" "}
                <h4> {titulo} </h4>
              </Accordion.Header>
              <Accordion.Body>
                <p> {parrafo} </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </Col>
  );
};
