import React from "react";
import arrowDropdown from "../../../../assets/images/icons/arrowDropdown.svg";
import { Link } from "react-router-dom";

import iconVidaDesplegable from "../../../../assets/images/icons/iconVidaDesplegable.svg";
import iconSeguroDesplegable from "../../../../assets/images/icons/iconSeguroDesplegable.svg";
import iconPhonoDesplegable from "../../../../assets/images/icons/iconPhonoDesplegable.svg";
import iconCashDesplegable from "../../../../assets/images/icons/iconCashDesplegable.svg";
import phoneIconDesplegable from "../../../../assets/images/icons/phoneIconDesplegable.svg";
import bicicleta from "../../../../assets/images/icons/bicicleta.png";

export const MenuDesplegableGrande = () => {
  return (
    <div className="menuDesplegadoHeader2">
      <div className="menuDesplegadoHeader2-header">
        <div className="menuDesplegadoHeader2-header-items-a">
          <Link to="/vida-y-salud">
            <div className="menuDesplegadoHeader2-header-items-a-img">
              <img src={iconVidaDesplegable} alt="Vida y salud" />
            </div>
            <div className="menuDesplegadoHeader2-header-items-a-title">
              <p>Vida y salud</p>
              <span>Protección para vos y tu familia</span>
            </div>
          </Link>
          <Link to="/hogar-y-negocio">
            <div className="menuDesplegadoHeader2-header-items-a-img">
              <img src={iconSeguroDesplegable} alt="Hogar y negocio" />
            </div>
            <div className="menuDesplegadoHeader2-header-items-a-title">
              <p>Hogar y negocio</p>
              <span>Protegé tus bienes más importantes</span>
            </div>
          </Link>
          <Link to="/tecnologia-protegida">
            <div className="menuDesplegadoHeader2-header-items-a-img">
              <img src={iconPhonoDesplegable} alt="Tecnología y entretenimiento" />
            </div>
            <div className="menuDesplegadoHeader2-header-items-a-title">
              <p>Tecnología y entretenimiento</p>
              <span>Protegé lo que más te gusta, vayas donde vayas.</span>
            </div>
          </Link>
        </div>
        <div className="menuDesplegadoHeader2-header-items-a">
          <Link to="/compras-y-gastos">
            <div className="menuDesplegadoHeader2-header-items-a-img">
              <img src={iconCashDesplegable} alt="Compras y gastos" />
            </div>
            <div className="menuDesplegadoHeader2-header-items-a-title">
              <p>Compras y gastos</p>
              <span>Seguros para sumar tranquilidad en tu día a día</span>
            </div>
          </Link>
          <Link to="/seguro-de-bici">
            <div className="menuDesplegadoHeader2-header-items-a-img">
              <img src={bicicleta} alt="Movilidad" style={{ width: "50px" }} />
            </div>
            <div className="menuDesplegadoHeader2-header-items-a-title">
              <p>Movilidad</p>
              <span>Asegurá tu bicicleta</span>
            </div>
          </Link>
        </div>

        {/*
            <div className="menuDesplegadoHeader2-header-items-b">
            <h4>Los más buscados</h4>
            <Link to="/vida-y-salud/vida">Vida</Link>
            <Link to="/hogar-y-negocio/negocio-protegido">Integral Comercio</Link>
            <Link to="/compras-y-gastos/compras-protegidas">Compras Protegidas</Link>
            <Link to="/compras-y-gastos/extracciones-protegidas">Extracciones Protegidas</Link>
            <Link to="/hogar-y-negocio/vivienda">Vivienda</Link>
            <Link to="/tecnologia-protegida/proteccion-movil">Protección Móvil</Link>
            <Link to="/vida-y-salud/accidentes-personales">Accidentes Personales</Link>
          </div>
            */}
      </div>

      <div className="menuDesplegadoHeader2-footer">
        <div className="menuDesplegadoHeader2-footer-a">
          <div className="menuDesplegadoHeader2-footer-a-img">
            <img src={phoneIconDesplegable} alt="Icon Phone" />
          </div>
          <div className="menuDesplegadoHeader2-footer-a-title">
            <h3>¿No sabes cuál es el mejor seguro para vos?</h3>
            <p>Hablá con nuestros asesores las 24 hs</p>
          </div>
        </div>
        <div className="menuDesplegadoHeader2-footer-b">
          <Link to="/contacto">Contactarme</Link>
        </div>
      </div>

      <img src={arrowDropdown} alt="" className="arrowArribaDesplegable" />
    </div>
  );
};
