import { getRequest } from "./apiService";

//GET NEWS CATEGORIES
export const getCategoryNews = async () => {
  const url = `/novedades-categorias`;
  const config = {
    headers: {
      Authorization: process.env.REACT_APP_API_TOKEN,
    },
  };

  const response = await getRequest(url, config);
  return response.data;
};

//GET NEWS
export const getNews = async () => {
  const url = `/posts?populate=*`;
  const config = {
    headers: {
      Authorization: process.env.REACT_APP_API_TOKEN,
    },
  };

  const response = await getRequest(url, config);
  return response.data;
};

//GET NEW
export const getNew = async (id) => {
  const url = `/posts/${id}?populate=*`;
  const config = {
    headers: {
      Authorization: process.env.REACT_APP_API_TOKEN,
    },
  };

  const response = await getRequest(url, config);
  return response.data;
};
