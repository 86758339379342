const SectionFooter = () => {
  return (
    <section>
      <div className="parrafo-footer text-md-start text-center p-md-0 p-4">
        <p>
          <b>Zurich Santander Seguros Argentina S.A.</b> CUIT 30-69896545-9, Av. Ing. Enrique Butty 240, 9.° Piso – CABA
          (C1001AFB). Registrada en Inspección General de Justicia bajo N° correlativo 1652779. <br /> Horario de
          atención: 9 a 18 h. Teléfono: 0810-333-2400.
          <br /> Dirección Gral. de Defensa y Protección al Consumidor: Para Consultas y/o denuncias{" "}
          <a
            href="https://www.buenosaires.gob.ar/defensaconsumidor/direccion-general"
            target={"_blank"}
            rel="noreferrer"
          >
            ingrese aquí
          </a>{" "}
          <br />
          <b>SERVICIO DE ATENCIÓN AL ASEGURADO</b>
          <br /> La entidad aseguradora dispone de un SERVICIO DE ATENCIÓN AL ASEGURADO que atenderá las consultas y
          reclamos que presenten los tomadores de seguros, asegurados, beneficiarios y/o derechohabientes.
          <br />
          RESPONSABLE: Samokec, Natalia Gabriela. <br />
          SUPLENTES: Fuentes Maria Jimena y Tomatis, Gonzalo Gastón.
          <br />
          TELÉFONO/INTERNO: 4341-3339/4341-3422 <br />
          CORREO ELECTRÓNICO DE CONTACTO:{" "}
          <a href="mailto:atencionalasegurado@zurich-santander.com.ar">
            atencionalasegurado@zurich-santander.com.ar
          </a>{" "}
          <br />
          Nº DE INSCRIPCIÓN DE LA COMPAÑÍA ANTE LA SSN: 0692
          <br />
          En caso de reclamo ante la entidad aseguradora no resuelto o desestimado, podrá denunciarlo a través de{" "}
          <a href="https://argentina.gob.ar/ssn" target={"_blank"} rel="noreferrer">
            https://argentina.gob.ar/ssn
          </a>
        </p>
      </div>
    </section>
  );
};

export default SectionFooter;
