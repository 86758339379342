import React from "react";
import { Container } from "react-bootstrap";

export const Portada = ({ portada, titulo, subtitulo, height }) => {
  return (
    <section
      className="ContenedorPortadaPageItem"
      style={{
        backgroundImage: `url(${portada})`,
        height: `${height && height}`,
      }}
    >
      <Container>
        <h2>{subtitulo}</h2>
        <h1 style={{ whiteSpace: "pre" }}> {titulo} </h1>
      </Container>
    </section>
  );
};
