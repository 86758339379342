import React from "react";
import BtnDenunciarUnSiniestro from "../Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";
import Footer from "../Global/Footer/Footer";
import Header from "../Global/Header/Header";
import { Portada } from "./Portada";
import { Link, useParams } from "react-router-dom";
import { getAllFaqs, getSubcategoriesFaq } from "../../services/faqServices";
import { useState } from "react";
import { useEffect } from "react";
import Loading from "../Global/Loading/Loading";
import { Accordion, Container } from "react-bootstrap";
import IconArrowVolver from "../../assets/images/PreguntasFrecuentes/IconArrowVolver.svg";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export const ContainerItem = () => {
  let { subcat } = useParams();

  const [faqs, setFaqs] = useState([]);
  const [, setSubCatFaq] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);

  const handleCallApi = async () => {
    setLoading(true);

    await getSubcategoriesFaq()
      .then((res) => {
        setSubCatFaq(res.data);
      })
      .catch((err) => {
        setError(err);
      });

    await getAllFaqs({ subcat })
      .then((res) => {
        setFaqs(res.data);
      })
      .catch((err) => {
        setError(err);
      });

    setLoading(false);
  };

  useEffect(() => {
    handleCallApi();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   setFilteredQuestions(faqs.filter((i) => i.attributes.Subcategoria.data.attributes.Slug === subcat));
  //   setFilteredSubCat(subCatFaq.filter((i) => i.attributes.Slug === subcat));
  // }, [subcat, faqs, subCatFaq]);

  // console.log(faqs);

  return (
    <>
      <Header />
      <Portada />

      {loading ? (
        <Loading className="mt-5 mb-5" />
      ) : (
        <Container>
          <section className="contenedorItemsPreguntasFrecuentes">
            <div className="contenedorItemsPreguntasFrecuentes-title">
              <Link to="/preguntas-frecuentes">
                {" "}
                <img src={IconArrowVolver} alt="Icon Volver" /> Volver
              </Link>
              {<h1>{subcat}</h1>}
            </div>
            <div className="contenedorItemsPreguntasFrecuentes-body">
              <div className="contenedorItemsPreguntasFrecuentes-body-items">
                {/* {filteredQuestions && filteredQuestions.length > 0 ? (
                  <Accordion>
                    {filteredQuestions.map((faq, index) => (
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>{faq.attributes.Pregunta}</Accordion.Header>
                        <Accordion.Body>
                          <ReactMarkdown children={faq.attributes.Respuesta} remarkPlugins={[remarkGfm]} />
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                ) : (
                  <p className="w-100 text-center pt-5 pb-5">No hay preguntas disponibles aún</p>
                )} */}

                {faqs.length > 0 ? (
                  <Accordion>
                    {faqs.map((faq, index) => (
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>{faq.attributes.Pregunta}</Accordion.Header>
                        <Accordion.Body>
                          <ReactMarkdown children={faq.attributes.Respuesta} remarkPlugins={[remarkGfm]} />
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                ) : (
                  <p className="w-100 text-center pt-5 pb-5">No hay preguntas disponibles aún</p>
                )}
              </div>
            </div>
          </section>
        </Container>
      )}

      <Footer />
      <BtnDenunciarUnSiniestro />
    </>
  );
};
