import { Col, Modal, Row } from "react-bootstrap";
import qrCliente from "../../assets/images/qrCliente.png";
import qrNoCliente from "../../assets/images/qrNoCliente.png";

import IconClose from "../../assets/images/TuveUnSiniestro/IconClose.svg";

export const ModalContratar = ({ estadoBoton, handleModal }) => {
  return (
    <div className="contenedorModal">
      <Modal show={estadoBoton} onHide={() => handleModal(false)} className="contenedorModal" centered>
        <div className="contenedorBodyModal">
          <div className="contenedorBodyModal-title text-center">
            <div className="text-center w-100">
              <h4 className="text-center w-100">Contratalo de forma simple y ágil </h4>
            </div>
            <img onClick={() => handleModal(false)} src={IconClose} alt="Icon Close" />
          </div>

          <Row className="pt-4">
            <Col md={6} className="text-center d-md-block d-none">
              <p className="fw-bold pb-3">Soy cliente Santander:</p>
              <p className="d-md-block d-none pb-2">
                hacelo en{" "}
                <a
                  href="https://www.santander.com.ar/banco/online/personas/canales-de-atencion/online-banking"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#333" }}
                >
                  Online Banking
                </a>{" "}
                <br></br>o desde la App
              </p>
              <img src={qrCliente} alt="QR" style={{ maxHeight: "180px" }} />
            </Col>
            <Col md={6} className="text-center">
              <p className="fw-bold pb-3">No soy cliente:</p>
              <p className="d-md-block d-none pb-2">
                <a
                  href="https://www.santander.com.ar/banco/online/personas"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#333" }}
                >
                  Hacete cliente
                </a>{" "}
                y conocé todo lo<br></br> que tenemos para vos:
              </p>
              <img src={qrNoCliente} alt="QR" style={{ maxHeight: "180px" }} />
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};
