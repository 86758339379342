import React, { useState } from "react";
import BannerContactate from "../Global/BannerContactate/BannerContactate";
import Reviews from "../Global/Reviews/Reviews";
import { Portada } from "../TecnologiaProtegida/PageItem/Portada";
import { Contenido } from "../TecnologiaProtegida/PageItem/Contenido";
import ImgPortada from "../../assets/images/SeguroDeBici/ImgPortada.svg";

import iconBici from "../../assets/images/SeguroDeBici/iconBici.svg";
import iconPeopleBici from "../../assets/images/SeguroDeBici/iconPeopleBici.svg";
import CardsServicios from "../Home/CardsServicios/CardsServicios";
import BtnAction from "../Global/BtnMasInformacion/BtnAction";
import { ModalContratar } from "../ModalContratar/ModalContratar";

export const ContenidoBici = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModal = (v) => {
    setShowModal(v);
  };

  const listaItems = [
    {
      icon: iconPeopleBici,
      title: "Cobertura por robo y/o hurto",
      parrafo: "Protección ante robo o hurto del objeto hasta la suma asegurada contratada.",
    },
    {
      icon: iconBici,
      title: "Cobertura por daño",
      parrafo:
        "Protección ante los daños materiales totales o parciales sufridos por el objeto como consecuencia de un accidente hasta la suma asegurada contratada.",
    },
  ];

  return (
    <div>
      <Portada portada={ImgPortada} titulo="Seguro de Bici" subtitulo="Movete con tranquilidad" />
      <Contenido opcion="b" UrlBtn="/" NombreBtn="Descargar Condiciones" ArrayItems={listaItems} />

      <div className="contenedorBtnPageItem">
        <BtnAction callback={() => handleModal(true)} texto="Cotizá ahora tu seguro" />
      </div>
      <div className="contenedorReviewsSeguroBici">
        <Reviews />
      </div>
      <div className="contenedorBannerContactateTecnologiaProtegida">
        <BannerContactate />
      </div>
      <div className="contenedorBannerServiciosBici">
        <h6>
          {" "}
          <b>Otros clientes también eligieron</b> estos productos
        </h6>
        <CardsServicios vida accidentes vivienda />
      </div>
      <ModalContratar estadoBoton={showModal} handleModal={handleModal} />
    </div>
  );
};
