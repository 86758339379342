import loading from "../../../assets/images/loading.svg";
const Loading = ({ className }) => {
  return (
    <div className={`w-100 d-flex justify-content-center align-items-center align-content-center ${className}`}>
      <div>
        <img alt='' src={loading} />
      </div>
    </div>
  );
};

export default Loading;
