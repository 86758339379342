import React from "react";
import { useForm } from "react-hook-form";
import { Container, Form } from "react-bootstrap";

import IconArrow from "../../assets/images/Escribenos/IconArrow.svg";

export const FormEscribenos = () => {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    // console.log(data);
  };
  return (
    <Container>
      <section className="contenedorFormEscribenos">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="contenedorFormEscribenos-inputs">
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control {...register("nombre", { required: true })} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Apellido</Form.Label>
              <Form.Control {...register("apellido", { required: true })} />
            </Form.Group>
          </div>
          <div className="contenedorFormEscribenos-inputs">
            <Form.Group>
              <Form.Label>Correo electrónico</Form.Label>
              <Form.Control {...register("email", { required: true })} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Teléfono</Form.Label>
              <Form.Control {...register("telefono", { required: true })} />
            </Form.Group>
          </div>

          <div className="contenedorFormEscribenos-textarea">
            <Form.Group>
              <Form.Label>Mensaje</Form.Label>
              <Form.Control as="textarea" {...register("mensaje", { required: true })} />
            </Form.Group>
          </div>

          <div className="contenedorFormEscribenos-submit">
            <button type="submit">
              Enviar mensaje <img src={IconArrow} alt="Icon Arrow" />{" "}
            </button>
          </div>
        </Form>
      </section>
    </Container>
  );
};
