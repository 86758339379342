import React from 'react';
import Reviews from "../components/Global/Reviews/Reviews";
import Footer from "../components/Global/Footer/Footer";
import Header from "../components//Global/Header/Header";
import BtnDenunciarUnSiniestro from "../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";
import { Portada } from '../components/TuveUnSiniestro/Portada';

export const TuveUnSiniestro = () => {
  return (
    <div>
      <Header />
      <Portada />
      <Reviews />
      <Footer />
      <BtnDenunciarUnSiniestro />
    </div>
  )
}
