import React, { useState } from "react";
import Header from "../../Global/Header/Header";
import { Portada } from "../../TecnologiaProtegida/PageItem/Portada";
import { StoreMobile } from "../../TecnologiaProtegida/PageItem/StoreMobile";
import CardsServicios from "../../Home/CardsServicios/CardsServicios";
import { Tablas } from "../../Global/Tablas/Tablas";
import { Breadcrumbs } from "../../Global/Breadcrumbs/Breadcrumbs";
import portadaEstatica from "../../../assets/images/TecnologiaProtegida/Portadas/PortadaProteccionMovil.svg";
import Icon1 from "../../../assets/images/TecnologiaProtegida/ProteccionMovil/Icon1.svg";
import Icon2 from "../../../assets/images/TecnologiaProtegida/ProteccionMovil/Icon2.svg";
import BtnAction from "../../Global/BtnMasInformacion/BtnAction";
import InfoSeguros from "../../InfoSeguros";
import FooterTecno from "../../FooterTecno";

export const ProteccionMovil = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModal = (v) => {
    setShowModal(v);
  };

  const datosApi = [
    {
      icono: Icon1,
      titulo: "Cobertura por robo del celular",
      toltip: "Protección ante robo o hurto del objeto hasta la suma asegurada contratada. ",
      mostrar1: true,
      mostrar2: true,
    },
    {
      icono: Icon2,
      titulo: "Cobertura ante daños accidentales que sufra el celular (*)",
      toltip:
        "Protección ante los daños materiales totales o parciales sufridos por el objeto como consecuencia de un accidente hasta la suma asegurada contratada.",
      mostrar1: false,
      mostrar2: true,
    },
  ];

  return (
    <>
      <Header />
      <Portada portada={portadaEstatica} titulo="Protección Móvil" subtitulo="Tecnología y entretenimiento" />
      <Tablas
        tituloNegrita="Tu smartphone protegido"
        tituloNormal="todos los días del año"
        TitulosColumnas1="Plan
          Robo"
        TitulosColumnas2="Plan Robo y Daño"
        arrayContenido={datosApi}
      />

      <div className="contenedorBreadcrumsInstanciado">
        <Breadcrumbs modalMostrar={true} title="Descargar Condiciones" url="/" />
      </div>
      <div className="contenedorBtnPageItem">
        <BtnAction callback={() => handleModal(true)} texto="Cotizá ahora tu seguro" />
      </div>
      <StoreMobile />
      <InfoSeguros />
      <div className="contenedorBannerServiciosBici">
        <h6>
          {" "}
          <b>Otros clientes también eligieron</b> estos productos
        </h6>
        <CardsServicios vivienda vida inteligente />
      </div>
      <FooterTecno showModal={showModal} handleModal={handleModal} />
    </>
  );
};
