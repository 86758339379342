import { Breadcrumbs } from "../../../Global/Breadcrumbs/Breadcrumbs";
import BtnAction from "../../../Global/BtnMasInformacion/BtnAction";
import InfoSeguros from "../../../InfoSeguros";

const InfoIntegral = ({ callback }) => {
  return (
    <>
      <div className="contenedorBreadcrumsCentrado">
        <Breadcrumbs modalMostrar={true} title="Descargar Condiciones" url="/" />
      </div>
      <div className="contenedorBtnPageItem">
        <BtnAction callback={callback} texto="Cotizá ahora tu seguro" />
      </div>
      <InfoSeguros />
    </>
  );
};

export default InfoIntegral;
