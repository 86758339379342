import BtnDenunciarUnSiniestro from "./Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";
import Footer from "./Global/Footer/Footer";
import { ModalContratar } from "./ModalContratar/ModalContratar";

const FooterAll = ({ showModal, handleModal }) => {
  return (
    <>
      <Footer />
      <BtnDenunciarUnSiniestro />
      <ModalContratar estadoBoton={showModal} handleModal={handleModal} />
    </>
  );
};

export default FooterAll;
