import React from "react";
import { Container } from "react-bootstrap";

import informe2020 from "../../pdf/informe-sostenibilidad-2020.pdf";
import informe2021 from "../../pdf/informe-sostenibilidad-2021.pdf";
import informe2022 from "../../pdf/informe-sostenibilidad-2022.pdf";

export const ParrafoPrincipal = () => {
  return (
    <Container>
      <section className="contenedorParrafoPrincipalSustentabilidad">
        <p>
          Desde Zurich Santander estamos comprometidos en promover el bienestar y desarrollo de las personas, generar
          una propuesta de valor más sostenible, promover la educación e inclusión financiera y laboral, buscando
          siempre la mejora continua de nuestras operaciones.
        </p>

        <div className="d-flex justify-content-center pt-5 pb-3">
          <a
            href={informe2022}
            className="text-center "
            target={"_blank"}
            style={{ color: "#000", fontSize: "20px" }}
            rel="noreferrer"
          >
            Informe de Sostenibilidad 2022
          </a>
        </div>

        <div className="d-flex justify-content-center pt-3 pb-3">
          <a
            href={informe2021}
            className="text-center "
            target={"_blank"}
            style={{ color: "#000", fontSize: "20px" }}
            rel="noreferrer"
          >
            Informe de Sostenibilidad 2021
          </a>
        </div>

        <div className="d-flex justify-content-center pt-3 pb-5 mb-3">
          <a
            href={informe2020}
            className="text-center "
            target={"_blank"}
            style={{ color: "#000", fontSize: "20px" }}
            rel="noreferrer"
          >
            Informe de Sostenibilidad 2020
          </a>
        </div>

        <h2 className="text-center">Compromiso Social</h2>
        <p>
          En Zurich Santander buscamos impactar positivamente en nuestra comunidad, a través de voluntariados y alianzas
          en apoyo social a instituciones y organizaciones no gubernamentales. En lo que va del 2022, alcanzamos{" "}
          <strong>
            408 horas totales de voluntariados y una participación que superó el 40% del total de colaboradores de la
            Compañía
          </strong>
          .
        </p>
      </section>
    </Container>
  );
};
