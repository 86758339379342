import React, { useState } from "react";
import Header from "../../Global/Header/Header";
import { Portada } from "../../TecnologiaProtegida/PageItem/Portada";
import { Contenido } from "../../TecnologiaProtegida/PageItem/Contenido";
import CardsServicios from "../../Home/CardsServicios/CardsServicios";
import portadaEstatica from "../../../assets/images/ComprasYgastos/ProteccionCartera/Portada.svg";
import Icon1 from "../../../assets/images/ComprasYgastos/ProteccionCartera/1.svg";
import Icon3 from "../../../assets/images/ComprasYgastos/ProteccionCartera/3.svg";
import Icon4 from "../../../assets/images/ComprasYgastos/ProteccionCartera/wallet.png";
import { Breadcrumbs } from "../../Global/Breadcrumbs/Breadcrumbs";
import LegalesSeguros from "../../LegalesSeguros/LegalesSeguros";
import AccordionAsistencias from "../../AccordionAsistencias/AccordionAsistencias";
import { dataAsistencias } from "../../../utils/resources/nuevasAsistencias";
import FooterAll from "../../FooterAll";
import InfoCompras from "../InformacionCompras";

export const ProteccionCarteras = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModal = (v) => {
    setShowModal(v);
  };

  const ArrayItems = [
    {
      title: "primero",
      contenido: [
        {
          icon: Icon1,
          title: "Cobertura por robo de tu cartera, bolso, mochila(1) y su contenido",
          parrafo: "Incluye celulares dentro del bolso hasta el límite de suma asegurada.",
        },
        {
          icon: Icon4,
          title: "Reintegro de gastos por reobtención de documentos",
          parrafo: "Consultar límites máximos al momento de la contratación.",
        },
        {
          icon: Icon3,
          title: "Reintegro de llaves de tu hogar",
          parrafo: "Reintegro de gastos por reobtención de llaves de tu hogar. Consultar límites máximos.",
        },
      ],
    },
  ];

  return (
    <>
      <Header />
      <Portada
        portada={portadaEstatica}
        titulo="Celular + Bolso"
        subtitulo="Lo que llevás en tu cartera está protegido"
      />
      <Contenido opcion="d" UrlBtn="/" NombreBtn="Descargar Condiciones" ArrayItems={ArrayItems} proteccion="cartera" />
      <AccordionAsistencias data={dataAsistencias[0].cartera} />
      <div className="contenedorBreadcrumsCentrado">
        <Breadcrumbs modalMostrar={false} title="Descargar Condiciones" url="/" />
      </div>
      <InfoCompras callback={() => handleModal(true)} />
      <div className="contenedorBannerServiciosBici">
        <h6>
          {" "}
          <b>Otros clientes también eligieron</b> estos productos
        </h6>
        <CardsServicios vivienda extracciones vida />
      </div>
      <LegalesSeguros tipo="proteccion-de-cartera" />
      <FooterAll showModal={showModal} handleModal={handleModal} />
    </>
  );
};
