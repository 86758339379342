import React from "react";
import Reviews from "../components/Global/Reviews/Reviews";
import Footer from "../components/Global/Footer/Footer";
import Header from "../components//Global/Header/Header";
import BtnDenunciarUnSiniestro from "../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";
import BannerContactate from "../components/Global/BannerContactate/BannerContactate";
import { Portada } from "../components/TecnologiaProtegida/Portada";
import { Cards } from "../components/TecnologiaProtegida/Cards";

import iconVivienda from "../assets/images/HogarYnegocio/iconVivienda.svg";
import iconNegocio from "../assets/images/HogarYnegocio/iconNegocio.svg";

export const HogarYnegocio = () => {
  const items = [
    {
      icono: iconVivienda,
      subtitulo: "Protegé tu casa y tus cosas",
      titulo: "Vivienda",
      url: "/hogar-y-negocio/vivienda",
    },
    {
      icono: iconNegocio,
      subtitulo: "La cobertura para tu negocio",
      titulo: "Negocio Protegido",
      url: "/hogar-y-negocio/negocio-protegido",
    },
  ];
  return (
    <div>
      <Header />
      <Portada subTitulo="Sentí la tranquilidad de tener protección" titulo="Hogar y Negocio" />
      <Cards items={items} />
      <Reviews />
      <div className="contenedorBannerContactateTecnologiaProtegida">
        <BannerContactate />
      </div>
      <Footer />
      <BtnDenunciarUnSiniestro />
    </div>
  );
};
