import React, { useState } from "react";
import Header from "../../Global/Header/Header";
import { Portada } from "../../TecnologiaProtegida/PageItem/Portada";
import { StoreMobile } from "../../TecnologiaProtegida/PageItem/StoreMobile";
import { BannerAzul } from "../../TecnologiaProtegida/PageItem/BannerAzul";
import CardsServicios from "../../Home/CardsServicios/CardsServicios";
import { Breadcrumbs } from "../../Global/Breadcrumbs/Breadcrumbs";
import { Contenido } from "../PageItem/Contenido";
import portadaEstatica from "../../../assets/images/TecnologiaProtegida/Portadas/PortadaArticulosDeportivos.svg";
import primerIcon from "../../../assets/images/TecnologiaProtegida/iconPages/articulosDeportivos/primero.svg";
import BtnAction from "../../Global/BtnMasInformacion/BtnAction";
import InfoSeguros from "../../InfoSeguros";
import FooterTecno from "../../FooterTecno";

export const ArticulosDeportivos = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModal = (v) => {
    setShowModal(v);
  };

  const ArrayItems = [
    {
      icon: primerIcon,
      title: "Cobertura por robo y/o hurto",
      parrafo: "Protección ante robo o hurto(1) del objeto hasta la suma asegurada contratada.",
    },
  ];

  return (
    <>
      <Header />
      <Portada portada={portadaEstatica} titulo="Artículos Deportivos" subtitulo="Tecnología y entretenimiento" />
      <Contenido opcion="b" ArrayItems={ArrayItems} />
      <div className="contenedorBreadcrumsCentrado">
        <Breadcrumbs modalMostrar={false} title="Descargar Condiciones" url="/" />
      </div>

      <div className="contenedorBtnPageItem d-flex justify-content-center flex-column">
        <div>
          <BtnAction callback={() => handleModal(true)} texto="Cotizá ahora tu seguro" />
        </div>
      </div>
      <StoreMobile />
      <BannerAzul />
      <InfoSeguros />
      <div className="contenedorBannerServiciosBici">
        <h6>
          {" "}
          <b>Otros clientes también eligieron</b> estos productos
        </h6>
        <CardsServicios vida accidentes vivienda />
      </div>

      <FooterTecno showModal={showModal} handleModal={handleModal} />
    </>
  );
};
