import React from "react";
import { Container } from "react-bootstrap";

import porqueElegirnos from "../../../assets/images/porqueElegirnos.svg";

import checkPorqueElegirnos from "../../../assets/images/icons/checkPorqueElegirnos.svg";
import globePorqueElegirnos from "../../../assets/images/icons/globePorqueElegirnos.svg";
import chatPorqueElegirnos from "../../../assets/images/icons/chatPorqueElegirnos.svg";
import BtnMasInformacion from "../../Global/BtnMasInformacion/BtnMasInformacion";

const PorqueElegirnos = () => {
  return (
    <Container id="container-porqueElegirnos">
      <section className="porqueElegirnos d-flex justify-content-md-start flex-md-row flex-column p-md-0 p-3">
        <div className="porqueElegirnos-text">
          <div className=" text-md-start text-center">
            <h3>¿Por qué elegir Zurich Santander?</h3>
            <p>
              Desde hace más de 10 años tenés a disposición seguros que se ajustan a tus necesidades. Zurich Insurance y
              Banco Santander, dos de los mayores conglomerados del mundo en los sectores asegurador y financiero
              formaron una alianza estratégica para proteger lo que más querés, creando Zurich Santander.
            </p>
          </div>
          <div className="porqueElegirnos-listCards">
            <div className="porqueElegirnos-card">
              <div className="porqueElegirnos-img">
                <img src={checkPorqueElegirnos} alt="Icon Check" />
              </div>
              <div className="porqueElegirnos-title">
                <h4>+1.400.000</h4>
                <p>Clientes confían en nosotros.</p>
              </div>
            </div>

            <div className="porqueElegirnos-card">
              <div className="porqueElegirnos-img">
                <img src={globePorqueElegirnos} alt="Icon Global" />
              </div>
              <div className="porqueElegirnos-title">
                <h4>80%</h4>
                <p>Nos recomendaría a amigos y familiares.</p>
              </div>
            </div>

            <div className="porqueElegirnos-card">
              <div className="porqueElegirnos-img">
                <img src={chatPorqueElegirnos} alt="Icon Chat" />
              </div>
              <div className="porqueElegirnos-title">
                <h4>95%</h4>
                <p>De los siniestros se responden en 24hs.</p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-md-start justify-content-center">
            <div>
              <BtnMasInformacion url="/nosotros" texto="Conocenos" />
            </div>
          </div>
        </div>
        <div className="porqueElegirnos-imagen mt-md-0 mt-4">
          <img src={porqueElegirnos} alt="Porque Elegirnos" />
        </div>
      </section>
    </Container>
  );
};

export default PorqueElegirnos;
