import Header from "../../../Global/Header/Header";
import { Portada } from "../../../TecnologiaProtegida/PageItem/Portada";
import portadaEstatica from "../../../../assets/images/HogarYnegocio/IntegralDelNegocio/Oficina/Portada.svg";
import { oficinas } from "../../../../utils/resources/coberturasyasistencias";
import ContenidoNegocio from "./ContenidoNegocio";

const Oficinas = () => {
  const ArrayItems = oficinas;

  return (
    <>
      <Header />
      <Portada portada={portadaEstatica} titulo="Oficina" subtitulo="La cobertura justa para tu negocio" />
      <ContenidoNegocio ArrayItems={ArrayItems} />
    </>
  );
};

export default Oficinas;
