import { getRequest } from "./apiService";

//GET CONDITIONS
export const getConditions = async () => {
  const url = `/cuadernillos?populate=*`;
  const config = {
    headers: {
      Authorization: process.env.REACT_APP_API_TOKEN,
    },
  };

  const response = await getRequest(url, config);
  return response.data;
};
