import { useState } from "react";
import Footer from "../components/Global/Footer/Footer";
import Header from "../components//Global/Header/Header";
import BtnDenunciarUnSiniestro from "../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";
import CardsServicios from "../components/Home/CardsServicios/CardsServicios";
import Reviews from "../components/Global/Reviews/Reviews";
import BannerContactate from "../components/Global/BannerContactate/BannerContactate";
import PorqueElegirnos from "../components/Home/PorqueElegirnos/PorqueElegirnos";
import NovedadesZurich from "../components/Home/NovedadesZurich/NovedadesZurich";
import HeaderTop from "../components/Home/HeaderTop/HeaderTop";
import { ModalMudanza } from "../components/Home/ModalMudanza/ModalMudanza";

const Home = () => {
  const [show, setShow] = useState({
    show: true,
  });

  const handleClose = () => {
    setShow({
      ...show,
      show: false,
    });
  };

  return (
    <div className="bg-white-zurich">
      <Header />

      <HeaderTop
        extract="Te damos la bienvenida a Zurich Santander"
        title="Somos una alianza estratégica pensada para vos."
        subtitle="#Sentiteprotegido"
        desc={`Conocé todo lo que tenemos para ofrecerte y encontrá la cobertura que mejor se adapte a tus necesidades.`}
        url="/vida-y-salud/vida"
        containerClass="welcome"
        sectionClass="text-center"
      />

      <HeaderTop
        title="Vos y tu familia, siempre protegidos"
        subtitle="Seguro de vida"
        desc={`Tu familia es lo más importante,\r\nprotegerla depende de vos.`}
        url="/vida-y-salud/vida"
        placeholder="Más información"
        containerClass="first"
        sectionClass="text-center"
      />

      <HeaderTop
        title="SENTITE protegido"
        subtitle="Seguro de hogar"
        desc={`Encontrá la cobertura perfecta, te cubrimos\r\nante cualquier tipo de siniestro o accidente.`}
        url="/hogar-y-negocio/vivienda"
        placeholder="Más información"
        containerClass="second"
        sectionClass="text-md-start text-center"
      />

      <CardsServicios negocio accidentes inteligente />
      <Reviews />
      <BannerContactate />
      <PorqueElegirnos />
      <NovedadesZurich />
      <Footer />
      <BtnDenunciarUnSiniestro />

      <ModalMudanza estadoBoton={show.show} cerrarModal={handleClose} />
    </div>
  );
};

export default Home;
