import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ItemNovedadZurich from "../ItemNovedadZurich/ItemNovedadZurich";

import { useState } from "react";
import { getNews } from "../../../services/newsServices";
import { useEffect } from "react";
import Loading from "../../Global/Loading/Loading";

const NovedadesZurich = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);

  const handleCallApi = async () => {
    setLoading(true);

    await getNews()
      .then((res) => {
        setNews(res.data);
      })
      .catch((err) => {
        setError(err);
      });

    setLoading(false);
  };

  useEffect(() => {
    handleCallApi();
  }, []);

  return news && news.length > 0 ? (
    <Container fluid="true" className="bg-novedadesZurich">
      <Container>
        <Row>
          <Col xxl={10} md={11} className="mx-auto">
            <section className="novedadesZurich">
              <h3 className="text-md-start text-center">
                Estas son las <span>novedades de Zurich Santander</span>
              </h3>

              <div className="novedadesZurich-items d-flex justify-content-md-between flex-md-row flex-column justify-content-center align-content-center align-items-center">
                {loading ? (
                  <Loading className="mt-5 mb-5" />
                ) : news && news.length > 0 ? (
                  news.map((n, index) => (
                    <ItemNovedadZurich
                      key={index}
                      imagen={`${n.attributes.Banner.data.attributes.url}`}
                      fecha={n?.attributes?.createdAt}
                      title={n.attributes.Titulo}
                      description={n.attributes.Abstract}
                      UrlLink={`/novedades/${n.attributes.Slug}-${n.id}`}
                    />
                  ))
                ) : (
                  <div>
                    <h3 className=" text-center">No hay novedades aún</h3>
                  </div>
                )}
              </div>
            </section>
          </Col>
        </Row>
      </Container>
    </Container>
  ) : (
    <></>
  );
};

export default NovedadesZurich;
