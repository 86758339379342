import React from "react";
import { Button } from "react-bootstrap";

import iconArrowBtn from "../../../assets/images/icons/icon-arrow-btn.svg";

const BtnAction = ({ callback, texto }) => {
  return (
    <Button onClick={callback} className="btnMasInformacion">
      {texto}
      <img src={iconArrowBtn} alt="Icon Arrow" />
    </Button>
  );
};

export default BtnAction;
