import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import iconWarningSiniestro from "../../../assets/images/icons/iconWarningSiniestro.svg";

const BannerContactate = ({ hideFaq }) => {
  return (
    <Container id="container-banner-contactate">
      <Row>
        <Col md={11} xs={11} className="mx-auto">
          <section className="banner-contactate">
            <div className="banner-contactate-card  d-flex justify-content-md-between justify-content-center flex-md-row flex-column">
              <div className="banner-contactate-card-1 text-md-start text-center d-flex justify-content-md-start justify-content-center align-content-center align-items-center flex-md-row flex-column">
                <div>
                  <img src={iconWarningSiniestro} alt="Icon Warning" />
                </div>
              </div>
              <div className="banner-contactate-card-2 text-md-start text-center">
                <p className="banner-contactate-card-2-title-blue text-center pb-2">¿Tuviste un siniestro?</p>
                <p className="banner-contactate-card-2-title text-center">
                  HACÉ TU DENUNCIA A TRAVÉS DE NUESTROS CANALES
                </p>
              </div>
              <div className="banner-contactate-card-3  pb-md-0 pb-4 pe-md-5 pe-0">
                <Link to="/tuve-un-siniestro">Cómo denunciar un siniestro</Link>
              </div>
            </div>
            {!hideFaq && (
              <div className="banner-contactate-footer-text">
                <p>
                  Si tenés dudas, podes consultar
                  <br className="d-md-none d-block" /> nuestra sección de{" "}
                  <Link to="/preguntas-frecuentes" className="link-default">
                    preguntas frecuentes
                  </Link>
                </p>
              </div>
            )}
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default BannerContactate;
