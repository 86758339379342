import { Col, Container, Row } from "react-bootstrap";

const LegalesSeguros = ({ tipo }) => {
  return (
    <Container>
      <Row>
        <Col md={8} xs={11} className="mx-auto pb-5">
          {tipo === "vida" && (
            <div className="text-legales">
              <p className="pt-2">
                Los Productos, Coberturas y Servicios de Asistencia pueden tener condiciones especiales, medidas de
                seguridad que tenés que cumplir, límites máximos de suma asegurada (monto máximo a abonar en caso de
                corresponder el pago del siniestro), límite en la cantidad de eventos cubiertos y exclusiones (hechos y
                situaciones que no están cubiertos por la póliza). Por favor, para más información, leé detenidamente
                los modelos de contrato de cada producto, que están a tu disposición en este sitio. Ante cualquier duda
                consultá términos, alcances, exclusiones y condiciones de las coberturas al 0810-333-2400.
              </p>

              <p className="pt-2">
                Ante cualquier duda consultá términos, alcances, exclusiones y condiciones de las coberturas al
                0810-333-2400.
              </p>

              <p className="pt-2">
                Agente Institorio inscripto en el Registro de Agentes Institorios bajo el nro. 139, de conformidad con
                la Resolución SSN ° 38052 del 20/12/2013: Banco Santander Argentina S.A.
              </p>

              <p className="pt-2">
                Empresa Aseguradora: Zurich Santander Seguros Argentina S.A., domicilio Ing. Enrique Butty 240, 9.° Piso
                - CABA (C1001AFB). CUIT: 30-69896545-9. Inscripto en la{" "}
                <a
                  href="https://www.argentina.gob.ar/superintendencia-de-seguros"
                  target="_blank"
                  rel="noreferrer"
                  className="link-default"
                >
                  Superintendencia de Seguros de la Nación (SSN)
                </a>{" "}
                mediante Nº 0692. Tel SSN 4338-4000 O 0800-666-8400, www.argentina.gob.ar/superintendencia-de-seguros.
                Registrada en Inspección General de Justicia bajo N° correlativo 1652779.
              </p>

              <p className="pt-2">
                Las coberturas adicionales que contrates están sujetas al pago de una prima adicional y tienen sus
                propios límites máximos de suma asegurada (monto máximo a abonar en caso de corresponder el pago del
                siniestro), que varían de acuerdo con la cobertura contratada.
              </p>

              <p className="pt-2">
                (1) De acuerdo con la Ley de Impuesto a las Ganancias - Decreto 59/2019. Esta comunicación no
                proporciona ningún tipo de recomendación, ni asesoramiento legal, fiscal, ni de otra clase, y nada de lo
                que en ella se incluye debe ser tomado como base para realizar contrataciones o tomar decisiones.
              </p>

              <p className="pt-2">
                (2) La edad máxima de cobertura para un trasplante cardiopulmonar es de 50 años. El Plan Básico "Vida
                Inicial" no cuenta con Asistencias.
              </p>

              <p className="pt-2">
                Se considera Select a todo aquel cliente que haya solicitado un paquete Platinum o Black o cuenta en
                Banca Privada con anterioridad al 01/12/2015 y mientras mantenga su calidad de tal. Así mismo se
                considerará también Select, hasta el 31/12/2017, a aquellos clientes a los que el Banco les haya
                informado individualmente tal calidad.
              </p>

              <p className="pt-2">
                Servicio de Asistencias brindado por Integral Group Solution S.A., CUIT 30-70919861-7, con domicilio R.
                Scalabrini Ortiz 3333, piso 2º, C.A.B.A. Las asistencias tienen límite de cantidad de servicios y límite
                máximo de monto cubierto por servicio. Consultá términos, alcances, exclusiones y condiciones de las
                prestaciones de asistencia llamando al 0800-444-0313.
              </p>
              <p className="pt-2">
                Servicio de Asistencia en Viaje es brindado por Universal Assistance S.A. CUIT 30-58146679-6, con
                domicilio AV. Córdoba 820, Piso 13, C.A.B.A. Las asistencias tienen límite de cantidad de servicios y
                límite máximo de monto cubierto por servicio. Consultá términos, alcances, exclusiones y condiciones de
                las prestaciones de asistencia llamando al 0800-333-1489 o (11) 4378-6037 (desde el interior de la
                República Argentina) o al +54-11 4378-6037 (desde el exterior de la República Argentina) o en{" "}
                <a href="https://www.zurichsantander.com.ar" target="_blank" rel="noreferrer" className="link-default">
                  www.zurichsantander.com.ar
                </a>
              </p>
            </div>
          )}
          {tipo === "integral-de-comercio" && (
            <div className="text-legales">
              <p>
                Los Productos, Coberturas y Servicios de Asistencia, pueden tener condiciones especiales, medidas de
                seguridad que tenés que cumplir, límites máximos de suma asegurada (monto máximo a abonar en caso de
                corresponder el pago del siniestro), límite en la cantidad de eventos cubiertos y exclusiones (hechos y
                situaciones que no están cubiertos por la póliza). Por favor, para más información, leé detenidamente
                los modelos de contrato de cada producto, que están a tu disposición en este sitio.
              </p>

              <p className="pt-2">
                Ante cualquier duda consultá términos, alcances, exclusiones y condiciones de las coberturas al
                0810-333-2400.
              </p>

              <p className="pt-2">
                Las coberturas adicionales que contrates están sujetas al pago de una prima adicional y tienen sus
                propios límites máximos de suma asegurada (monto máximo a abonar en caso de corresponder el pago del
                siniestro), que varían de acuerdo con la cobertura contratada.
              </p>

              <p className="pt-2">
                la Resolución SSN ° 38052 del 20/12/2013: Banco Santander Argentina S.A. Empresa Aseguradora: Zurich
                Santander Seguros Argentina S.A., domicilio Ing. Enrique Butty 240, 9.° Piso - CABA (C1001AFB). CUIT:
                30-69896545-9. Inscripto en la{" "}
                <a
                  href="https://www.argentina.gob.ar/superintendencia-de-seguros"
                  target="_blank"
                  rel="noreferrer"
                  className="link-default"
                >
                  Superintendencia de Seguros de la Nación (SSN)
                </a>{" "}
                mediante Nº 0692. Tel SSN 4338-4000 O 0800-666-8400, www.argentina.gob.ar/superintendencia-de-seguros.
                Registrada en Inspección General de Justicia bajo N° correlativo 1652779.
              </p>
              <p className="pt-2">
                Las coberturas adicionales que contrates están sujetas al pago de una prima adicional y tienen sus
                propios límites máximos de suma asegurada (monto máximo a abonar en caso de corresponder el pago del
                siniestro), que varían de acuerdo con la cobertura contratada.
              </p>
              <p className="pt-2">
                Servicio de Asistencias brindado por Integral Group Solution S.A., CUIT 30-70919861-7, con domicilio R.
                Scalabrini Ortiz 3333, piso 2º, C.A.B.A. Las asistencias tienen límite de cantidad de servicios y límite
                máximo de monto cubierto por servicio. Consultá términos, alcances, exclusiones y condiciones de las
                prestaciones de asistencia llamando al 0800-444-0313.
              </p>
            </div>
          )}
          {tipo === "proteccion-inteligente" && (
            <div className="text-legales">
              <p>
                Los Productos, Coberturas y Servicios de Asistencia ante Urgencias pueden tener condiciones especiales,
                medidas de seguridad que tenés que cumplir, límites máximos de suma asegurada (monto máximo a abonar en
                caso de corresponder el pago del siniestro), límite en la cantidad de eventos cubiertos y exclusiones
                (hechos y situaciones que no están cubiertos por la póliza). Por favor, para más información, leé
                detenidamente los modelos de contrato de cada producto, que están a tu disposición en este sitio.
              </p>

              <p className="pt-2">
                Ante cualquier duda consultá términos, alcances, exclusiones y condiciones de las coberturas al
                0810-333-2400.
              </p>

              <p className="pt-2">
                Agente Institorio inscripto en el Registro de Agentes Institorios bajo el nro. 139, de conformidad con
                la Resolución SSN ° 38052 del 20/12/2013: Banco Santander Argentina S.A.
              </p>

              <p className="pt-2">
                Empresa Aseguradora: Zurich Santander Seguros Argentina S.A., domicilio Ing. Enrique Butty 240, 9.° Piso
                - CABA (C1001AFB). CUIT: 30-69896545-9. Inscripto en la Superintendencia de Seguros de la Nación (SSN)
                mediante Nº 0692. Tel SSN 4338-4000 O 0800-666-8400. Registrada en Inspección General de Justicia bajo
                N° correlativo 1652779. Ámbito de Cobertura: República Argentina.
              </p>
              <p className="pt-2">
                (1) No cubre el robo de accesorios ni el extravío del objeto. (2) Se entiende por accidente cualquier
                causa externa, súbita e imprevista que no haya sido expresamente excluida en la póliza. No cubre daños
                por diferencias de tensión, daños ocasionados por el mismo uso del bien, daños estéticos, y otros.
              </p>
              <p className="pt-2">
                "Protección Inteligente" es un nombre comercial. En la documentación referida al producto se verá
                reflejado como "Protección". Disponible únicamente para clientes de Banco Santander Argentina S.A. que
                contraten el seguro desde la App Santander.
              </p>
            </div>
          )}
          {tipo === "proteccion-de-cartera" && (
            <div className="text-legales">
              <p className="pt-2">
                Los Productos, Coberturas y Servicios de Asistencia pueden tener condiciones especiales, medidas de
                seguridad que tenés que cumplir, límites máximos de suma asegurada (monto máximo a abonar en caso de
                corresponder el pago del siniestro), límite en la cantidad de eventos cubiertos y exclusiones (hechos y
                situaciones que no están cubiertos por la póliza). Por favor, para más información, leé detenidamente
                los modelos de contrato de cada producto, que están a tu disposición en este sitio. Ante cualquier duda
                consultá términos, alcances, exclusiones y condiciones de las coberturas al 0810-333-2400.
              </p>

              <p className="pt-2">
                Agente Institorio inscripto en el Registro de Agentes Institorios bajo el nro. 139, de conformidad con
                la Resolución SSN ° 38052 del 20/12/2013: Banco Santander Argentina S.A.
              </p>

              <p className="pt-2">
                Empresa Aseguradora: Zurich Santander Seguros Argentina S.A., domicilio Ing. Enrique Butty 240, 9.° Piso
                - CABA (C1001AFB). CUIT: 30-69896545-9. Inscripto en la{" "}
                <a
                  href="https://www.argentina.gob.ar/superintendencia-de-seguros"
                  target="_blank"
                  rel="noreferrer"
                  className="link-default"
                >
                  Superintendencia de Seguros de la Nación (SSN)
                </a>{" "}
                mediante Nº 0692. Tel SSN 4338-4000 O 0800-666-8400, www.argentina.gob.ar/superintendencia-de-seguros.
                Registrada en Inspección General de Justicia bajo N° correlativo 1652779.
              </p>

              <p className="pt-2">
                Servicio de Asistencias brindado por Integral Group Solution S.A., CUIT 30-70919861-7, con domicilio R.
                Scalabrini Ortiz 3333, piso 2º, C.A.B.A. Las asistencias tienen límite de cantidad de servicios y límite
                máximo de monto cubierto por servicio. Consultá términos, alcances, exclusiones y condiciones de las
                prestaciones de asistencia llamando al 0800-444-0313
              </p>

              <p className="pt-2">(1) Refiere a Cartera, portafolio, bolso, mochila o maletín. </p>
            </div>
          )}
          {tipo === "vivienda" && (
            <div className="text-legales">
              <p className="pt-2">
                Los Productos, Coberturas y Servicios de Asistencia pueden tener condiciones especiales, medidas de
                seguridad que tenés que cumplir, límites máximos de suma asegurada (monto máximo a abonar en caso de
                corresponder el pago del siniestro), límite en la cantidad de eventos cubiertos y exclusiones (hechos y
                situaciones que no están cubiertos por la póliza). Por favor, para más información, leé detenidamente
                los modelos de contrato de cada producto.
              </p>
              <p className="pt-2">
                Ante cualquier duda consultá términos, alcances, exclusiones y condiciones de las coberturas al
                0810-333-2400.
              </p>
              <p className="pt-2">
                Agente Institorio inscripto en el Registro de Agentes Institorios bajo el nro. 139, de conformidad con
                la Resolución SSN ° 38052 del 20/12/2013: Banco Santander Argentina S.A.
              </p>
              <p className="pt-2">
                Empresa Aseguradora: Zurich Santander Seguros Argentina S.A., domicilio Ing. Enrique Butty 240, 9.° Piso
                - CABA (C1001AFB). CUIT: 30-69896545-9. Inscripto en la{" "}
                <a
                  href="https://www.argentina.gob.ar/superintendencia-de-seguros"
                  target="_blank"
                  rel="noreferrer"
                  className="link-default"
                >
                  Superintendencia de Seguros de la Nación (SSN)
                </a>{" "}
                mediante Nº 0692. Tel SSN 4338-4000 O 0800-666-8400, www.argentina.gob.ar/superintendencia-de-seguros.
                Registrada en Inspección General de Justicia bajo N° correlativo 1652779.
              </p>
              <p className="pt-2">
                Las coberturas adicionales que contrates están sujetas al pago de una prima adicional y tienen sus
                propios límites máximos de suma asegurada (monto máximo a abonar en caso de corresponder el pago del
                siniestro), que varían de acuerdo a la cobertura contratada.
              </p>
              <p className="pt-2">
                Las coberturas adicionales que contrates están sujetas al pago de una prima adicional y tienen sus
                propios límites máximos de suma asegurada (monto máximo a abonar en caso de corresponder el pago del
                siniestro), que varían de acuerdo a la cobertura contratada.
              </p>
              <p className="pt-2">
                Servicio de Asistencias de mantenimiento y urgencia y el de cobertura adicional optativa de Mascotas
                brindadas por Integral Group Solution S.A., CUIT 30-70919861-7, con domicilio en R. Scalabrini Ortiz
                3333, piso 2º, C.A.B.A. Las asistencias tienen límite de cantidad de servicios y límite máximo de monto
                cubierto por servicio. Consultá términos, alcances, exclusiones y condiciones de las prestaciones de
                asistencia llamando al 0800-444-0313.
              </p>
              <p>
                Protección adicional para mascotas es válida para pólizas contratadas a partir del 26/11/2019. Si tu
                mascota tiene entre 1 a 10 años. Excluye a animales de exposición y de reproducción.
              </p>
            </div>
          )}
          {tipo === "gastos-protegidos" && (
            <div className="text-legales">
              <p>
                Los Productos, Coberturas y Servicios de Asistencia ante Urgencias pueden tener condiciones especiales,
                medidas de seguridad que tenés que cumplir, límites máximos de suma asegurada (monto máximo a abonar en
                caso de corresponder el pago del siniestro), límite en la cantidad de eventos cubiertos y exclusiones
                (hechos y situaciones que no están cubiertos por la póliza). Por favor, para más información, leé
                detenidamente los modelos de contrato de cada producto, que están a tu disposición en este sitio.
              </p>
              <p className="pt-2">
                Ante cualquier duda consultá términos, alcances, exclusiones y condiciones de las coberturas al
                0810-333-2400. Agente Institorio inscripto en el Registro de Agentes Institorios bajo el nro. 139, de
                conformidad con la Resolución SSN ° 38052 del 20/12/2013: Banco Santander Argentina S.A.
              </p>
              <p className="pt-2">
                Agente Institorio inscripto en el Registro de Agentes Institorios bajo el nro. 139, de conformidad con
                la Resolución SSN ° 38052 del 20/12/2013: Banco Santander Argentina S.A.
              </p>
              <p className="pt-2">
                Empresa Aseguradora: Zurich Santander Seguros Argentina S.A., domicilio Ing. Enrique Butty 240, 9.° Piso
                - CABA (C1001AFB). CUIT: 30-69896545-9. Inscripto en la{" "}
                <a
                  href="https://www.argentina.gob.ar/superintendencia-de-seguros"
                  target="_blank"
                  rel="noreferrer"
                  className="link-default"
                >
                  Superintendencia de Seguros de la Nación (SSN)
                </a>{" "}
                mediante Nº 0692. Tel SSN 4338-4000 O 0800-666-8400, www.argentina.gob.ar/superintendencia-de-seguros.
                Registrada en Inspección General de Justicia bajo N° correlativo 1652779.
              </p>
              <p className="pt-2">
                (1) Este seguro tiene un Periodo de Carencia, por lo que para tener cobertura todo siniestro tiene que
                haber ocurrido con posterioridad al vencimiento de dicho periodo. También, tiene un Periodo de Espera,
                que es el plazo mínimo que tiene que durar la situación de desempleo involuntario para que corresponda
                el pago. Podrás encontrar detallados ambos plazos en el frente de póliza.
              </p>
            </div>
          )}
          {tipo === "accidentes-personales" && (
            <div className="text-legales">
              <p>
                Los Productos, Coberturas y Servicios de Asistencia, pueden tener condiciones especiales, medidas de
                seguridad que tenés que cumplir, límites máximos de suma asegurada (monto máximo a abonar en caso de
                corresponder el pago del siniestro), límite en la cantidad de eventos cubiertos y exclusiones (hechos y
                situaciones que no están cubiertos por la póliza). Por favor, para más información, leé detenidamente
                los modelos de contrato de cada producto, que están a tu disposición en este sitio.
              </p>

              <p className="pt-2">
                Ante cualquier duda consultá términos, alcances, exclusiones y condiciones de las coberturas al
                0810-333-2400.
              </p>

              <p className="pt-2">
                Agente Institorio inscripto en el Registro de Agentes Institorios bajo el nro. 139, de conformidad con
                la Resolución SSN ° 38052 del 20/12/2013: Banco Santander Argentina S.A.
              </p>

              <p className="pt-2">
                Empresa Aseguradora: Zurich Santander Seguros Argentina S.A., domicilio Ing. Enrique Butty 240, 9.° Piso
                - CABA (C1001AFB). CUIT: 30-69896545-9. Inscripto en la{" "}
                <a
                  href="https://www.argentina.gob.ar/superintendencia-de-seguros"
                  target="_blank"
                  rel="noreferrer"
                  className="link-default"
                >
                  Superintendencia de Seguros de la Nación (SSN)
                </a>{" "}
                mediante Nº 0692. Tel SSN 4338-4000 O 0800-666-8400, www.argentina.gob.ar/superintendencia-de-seguros.
                Registrada en Inspección General de Justicia bajo N° correlativo 1652779.
              </p>

              <p className="pt-2">
                Las coberturas adicionales que contrates están sujetas al pago de una prima adicional y tienen sus
                propios límites máximos de suma asegurada (monto máximo a abonar en caso de corresponder el pago del
                siniestro), que varían de acuerdo a la cobertura contratada.
              </p>

              <p className="pt-2">
                Servicio de Asistencias brindado por Integral Group Solution S.A., CUIT 30-70919861-7, con domicilio R.
                Scalabrini Ortiz 3333, piso 2º, C.A.B.A. Las asistencias tienen límite de cantidad de servicios y límite
                máximo de monto cubierto por servicio. Consultá términos, alcances, exclusiones y condiciones de las
                prestaciones de asistencia llamando al 0800-444-0313.
              </p>
            </div>
          )}
          {tipo === "compras-protegidas" && (
            <div className="text-legales">
              <p>
                Los Productos, Coberturas y Servicios de Asistencia ante Urgencias pueden tener condiciones especiales,
                medidas de seguridad que tenés que cumplir, límites máximos de suma asegurada (monto máximo a abonar en
                caso de corresponder el pago del siniestro), límite en la cantidad de eventos cubiertos y exclusiones
                (hechos y situaciones que no están cubiertos por la póliza). Por favor, para más información, leé
                detenidamente los modelos de contrato de cada producto, que están a tu disposición en este sitio.
              </p>

              <p className="pt-2">
                Ante cualquier duda consultá términos, alcances, exclusiones y condiciones de las coberturas al
                0810-333-2400.
              </p>

              <p className="pt-2">
                Agente Institorio inscripto en el Registro de Agentes Institorios bajo el nro. 139, de conformidad con
                la Resolución SSN ° 38052 del 20/12/2013: Banco Santander Argentina S.A.
              </p>

              <p className="pt-2">
                Empresa Aseguradora: Zurich Santander Seguros Argentina S.A., domicilio Ing. Enrique Butty 240, 9.° Piso
                - CABA (C1001AFB). CUIT: 30-69896545-9. Inscripto en la{" "}
                <a
                  href="https://www.argentina.gob.ar/superintendencia-de-seguros"
                  target="_blank"
                  rel="noreferrer"
                  className="link-default"
                >
                  Superintendencia de Seguros de la Nación (SSN)
                </a>{" "}
                mediante Nº 0692. Tel SSN 4338-4000 O 0800-666-8400, www.argentina.gob.ar/superintendencia-de-seguros.
                Registrada en Inspección General de Justicia bajo N° correlativo 1652779.
              </p>

              <p className="pt-2">(1) Cubre hasta 4 hechos/situaciones por año.</p>
            </div>
          )}
          {tipo === "bici" && (
            <div className="text-legales">
              <p>
                Los Productos, Coberturas y Servicios de Asistencia ante Urgencias pueden tener condiciones especiales,
                medidas de seguridad que tenés que cumplir, límites máximos de suma asegurada (monto máximo a abonar en
                caso de corresponder el pago del siniestro), límite en la cantidad de eventos cubiertos y exclusiones
                (hechos y situaciones que no están cubiertos por la póliza). Por favor, para más información, leé
                detenidamente los modelos de contrato de cada producto, que están a tu disposición en este sitio.
              </p>

              <p className="pt-2">
                Ante cualquier duda consultá términos, alcances, exclusiones y condiciones de las coberturas al
                0810-333-2400.
              </p>

              <p className="pt-2">
                Agente Institorio inscripto en el Registro de Agentes Institorios bajo el nro. 139, de conformidad con
                la Resolución SSN ° 38052 del 20/12/2013: Banco Santander Argentina S.A.
              </p>

              <p className="pt-2">
                Empresa Aseguradora: Zurich Santander Seguros Argentina S.A., domicilio Ing. Enrique Butty 240, 9.° Piso
                - CABA (C1001AFB). CUIT: 30-69896545-9. Inscripto en la{" "}
                <a
                  href="https://www.argentina.gob.ar/superintendencia-de-seguros"
                  target="_blank"
                  rel="noreferrer"
                  className="link-default"
                >
                  Superintendencia de Seguros de la Nación (SSN)
                </a>{" "}
                mediante Nº 0692. Tel SSN 4338-4000 O 0800-666-8400. Registrada en Inspección General de Justicia bajo
                N° correlativo 1652779.
              </p>

              <p className="pt-2">Ámbito de Cobertura: República Argentina.</p>

              <p className="pt-2">
                (1) No cubre el robo de accesorios ni el extravío del objeto. (2) Se entiende por accidente cualquier
                causa externa, súbita e imprevista que no haya sido expresamente excluida en la póliza. No cubre daños
                por diferencias de tensión, daños ocasionados por el mismo uso del bien, daños estéticos, y otros.
              </p>

              <p className="pt-2">
                "Protección Inteligente" es un nombre comercial. En la documentación referida al producto se verá
                reflejado como "Protección". Disponible únicamente para clientes de Banco Santander Argentina S.A. que
                contraten el seguro desde la App Santander.
              </p>
            </div>
          )}
          {tipo === "extracciones-protegidas" && (
            <div className="text-legales">
              <p>
                Los Productos, Coberturas y Servicios de Asistencia ante Urgencias pueden tener condiciones especiales,
                medidas de seguridad que tenés que cumplir, límites máximos de suma asegurada (monto máximo a abonar en
                caso de corresponder el pago del siniestro), límite en la cantidad de eventos cubiertos y exclusiones
                (hechos y situaciones que no están cubiertos por la póliza). Por favor, para más información, leé
                detenidamente los modelos de contrato de cada producto, que están a tu disposición en este sitio.
              </p>

              <p className="pt-2">
                Ante cualquier duda consultá términos, alcances, exclusiones y condiciones de las coberturas al
                0810-333-2400.
              </p>

              <p className="pt-2">
                Agente Institorio inscripto en el Registro de Agentes Institorios bajo el nro. 139, de conformidad con
                la Resolución SSN ° 38052 del 20/12/2013: Banco Santander Argentina S.A.
              </p>

              <p className="pt-2">
                Empresa Aseguradora: Zurich Santander Seguros Argentina S.A., domicilio Ing. Enrique Butty 240, 9.° Piso
                - CABA (C1001AFB). CUIT: 30-69896545-9. Inscripto en la{" "}
                <a
                  href="https://www.argentina.gob.ar/superintendencia-de-seguros"
                  target="_blank"
                  rel="noreferrer"
                  className="link-default"
                >
                  Superintendencia de Seguros de la Nación (SSN)
                </a>{" "}
                mediante Nº 0692. Tel SSN 4338-4000 O 0800-666-8400, www.argentina.gob.ar/superintendencia-de-seguros.
                Registrada en Inspección General de Justicia bajo N° correlativo 1652779.
              </p>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default LegalesSeguros;
