import React from "react";

import PortadaImg from "../../../../assets/images/HogarYnegocio/Vivienda/AdicionalParaMascotas/Portada.svg";
import Icon1 from "../../../../assets/images/HogarYnegocio/Vivienda/AdicionalParaMascotas/1.svg";
import Icon2 from "../../../../assets/images/HogarYnegocio/Vivienda/AdicionalParaMascotas/2.svg";

export const AdicionalMascotas = () => {
  return (
    <>
      <section className="contenedorAdicionalMascotas">
        <div className="contenedorAdicionalMascotas-primeraColumna">
          <img src={PortadaImg} alt="Portada Ilustracion" />
        </div>
        <div className="contenedorAdicionalMascotas-segundaColumna">
          <h3 className="contenedorAdicionalMascotas-segundaColumna-title">Adicional para mascotas</h3>
          <p className="contenedorAdicionalMascotas-segundaColumna-parrafo">
            <b>¡Ahora tu mascota puede contar con nuestra protección!</b> <br />
            Porque ellos son parte de tu hogar, también podés cuidarlos con nuestra cobertura adicional dentro del
            Seguro de Vivienda:
          </p>

          <div className="contenedorAdicionalMascotas-segundaColumna-list">
            <div className="contenedorAdicionalMascotas-segundaColumna-list-card">
              <img src={Icon1} alt="Icon" />
              <div className="contenedorAdicionalMascotas-segundaColumna-list-card-b">
                <h5>Gastos Veterinarios</h5>
                <p>
                  Tu mascota cuenta con protección por lesiones corporales que haya sufrido. Nosotros te acompañamos
                  para afrontar estos gastos.
                </p>
              </div>
            </div>

            <div className="contenedorAdicionalMascotas-segundaColumna-list-card">
              <img src={Icon2} alt="Icon" />
              <div className="contenedorAdicionalMascotas-segundaColumna-list-card-b">
                <h5>Cobertura en caso de fallecimiento</h5>
                <p>En los momentos más difíciles, también vamos a estar ahí para ayudarte.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
