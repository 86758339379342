import { Modal } from "react-bootstrap";
import IconClose from "../../../assets/images/TuveUnSiniestro/IconClose.svg";
import modalMudanzaImg from "../../../assets/images/modalMudanza.png";

export const ModalMudanza = ({ estadoBoton, cerrarModal }) => {
  if (!estadoBoton) {
    return null;
  }

  return (
    <div className="contenedorModal">
      <Modal show={estadoBoton} onHide={cerrarModal} className="contenedorModal" centered>
        <div className="contenedorBodyModal ">
          <div className="contenedorBodyModal-title">
            <div>
              <h4 className="title_mudanza">¡Nos mudamos!</h4>
            </div>
            <img
              onClick={() => cerrarModal()}
              src={IconClose}
              alt="Icon Close"
              itemID="close"
              testId="close"
              data-testid="close"
            />
          </div>

          <div className="contenedorDinamico-formato-a d-md-flex flex-row justify-content-between">
            <div className="contenedorBodyModal-text">
              <p className="txt_mudanza pb-4">
                Queremos contarte que, a partir del <b>1 de abril</b>, la nueva dirección de <b>Zurich Santander</b>{" "}
                será Ing. Enrique Butty 240, piso 9° CABA (C1001AFB). Estaremos habilitando la atención al público en
                nuestras oficinas a partir del 21 de abril.
              </p>

              <h5 className="mudanza_subtitile">Te recordamos nuestros teléfonos de contacto:</h5>

              <ul className="pt-2">
                <li>
                  <p className="txt_mudanza">
                    <b>Denuncia de siniestro:</b> 0800-122-0905/06 o desde tu Online Banking Santander
                  </p>
                </li>
                <li>
                  <p className="txt_mudanza">
                    <b>Servicios de asistencias:</b> 0800-444-0313
                  </p>
                </li>
                <li>
                  <p className="txt_mudanza">
                    <b>Asesoramiento:</b> 4345-2400 o 0810-333-2400
                  </p>
                </li>
                <li>
                  <p className="txt_mudanza">
                    <b>Atención al Asegurado:</b> 4341-3339 / 4341-3422
                  </p>
                </li>
              </ul>
            </div>
            <div className="d-md-flex d-none">
              <img src={modalMudanzaImg} alt="Imagen Mudanza" className="img-fluid" style={{ minWidth: "150px" }} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
