import React, { useState } from "react";
import Header from "../../Global/Header/Header";
import { Portada } from "../../TecnologiaProtegida/PageItem/Portada";
import { Contenido } from "../../TecnologiaProtegida/PageItem/Contenido";
import CardsServicios from "../../Home/CardsServicios/CardsServicios";

import portadaEstatica from "../../../assets/images/HogarYnegocio/Vivienda/Portada.svg";
import Icon1 from "../../../assets/images/HogarYnegocio/Vivienda/Icons/1.svg";
import Icon2 from "../../../assets/images/HogarYnegocio/Vivienda/Icons/2.svg";
import Icon3 from "../../../assets/images/HogarYnegocio/Vivienda/Icons/3.svg";
import Icon4 from "../../../assets/images/HogarYnegocio/Vivienda/Icons/4.svg";
import Icon5 from "../../../assets/images/HogarYnegocio/Vivienda/Icons/5.svg";

import IconAdicionales1 from "../../../assets/images/HogarYnegocio/Vivienda/Icons/6.svg";
import IconAsistencia7 from "../../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/asistenciaIcons/5.png";
import IconAsistencia1 from "../../../assets/images/HogarYnegocio/Vivienda/Icons/7.svg";
import IconAsistencia4 from "../../../assets/images/HogarYnegocio/Vivienda/Icons/10.svg";
import IconAsistencia5 from "../../../assets/images/HogarYnegocio/Vivienda/Icons/11.svg";

import { Breadcrumbs } from "../../Global/Breadcrumbs/Breadcrumbs";

import { AdicionalMascotas } from "./Vivienda/AdicionalMascotas";
import { AsistenciaMascotas } from "./Vivienda/AsistenciaMascotas";
import BtnAction from "../../Global/BtnMasInformacion/BtnAction";
import LegalesSeguros from "../../LegalesSeguros/LegalesSeguros";
import AccordionAsistencias from "../../AccordionAsistencias/AccordionAsistencias";
import { dataAsistencias } from "../../../utils/resources/nuevasAsistencias";
import FooterAll from "../../FooterAll";
import InfoSeguros from "../../InfoSeguros";

export const Vivienda = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModal = (v) => {
    setShowModal(v);
  };

  const ArrayItems = [
    {
      title: "primero",
      contenido: [
        {
          icon: Icon1,
          title: "Indemnización en caso de incendio en tu hogar.",
          toltip:
            "Incendio en tu casa o departamento y de los bienes muebles que tenés dentro. También te cubre los daños por humo, remoción de escombros y gastos de hospedaje en caso que tu hogar quede inhabitable por el incendio.",
        },
        {
          icon: Icon2,
          title: "Cobertura por robo y/o hurto de tus cosas.",
          toltip:
            "También te cubre el robo de los bienes que lleves de vacaciones en República Argentina, y los daños a tu hogar provocados por tentativa de robo.",
        },
        {
          icon: Icon3,
          title: "Cobertura de responsabilidad civil.",
          toltip:
            "Protección frente a reclamos de terceros hasta límite de suma asegurada por daños ocasionados como consecuencia de hechos privados (no vinculados a la actividad profesional) dentro de la República Argentina y países limítrofes.",
        },
        {
          icon: Icon4,
          title: "Indemnización por pérdida de alimentos refrigerados.",
          toltip: "Cubre las pérdidas provocadas ante imprevistos (como cortes de luz prolongados, por ejemplo).",
        },
        {
          icon: Icon5,
          title: "Indemnización por daños en tu hogar.",
          toltip:
            "Cubre daños provocados por el impacto de un vehículo de un tercero y también daños por granizo, huracán, vendaval, ciclón, tornado y terremoto.",
        },
      ],
    },
    {
      title: "adicionales",
      contenido: [
        {
          icon: IconAdicionales1,
          title: "Cobertura por robo/hurto de Notebook/Netbook/Tablet.",
          toltip: " (Cobertura en la República Argentina).",
        },
        {
          icon: IconAdicionales1,
          title: "Indemnización por daños por accidente en Notebook/Netbook/Tablet.",
          toltip: "(Cobertura en la República Argentina).",
        },
        {
          icon: IconAdicionales1,
          title: "Cobertura por robo/hurto de equipo de fotografía/filmación.",
        },
        {
          icon: IconAdicionales1,
          title: "Indemnización por daños por accidente en equipo de fotografía/filmación.",
        },
        {
          icon: IconAdicionales1,
          title: "Cobertura de daños por accidente de TV/LCD/LED, Video, Audio y DVD. ",
          toltip: "Cobertura de daños por accidente de los electrónicos mencionados (1)",
        },
        {
          icon: IconAdicionales1,
          title: "Robo/hurto de joyas y objetos lujosos.",
        },
        {
          icon: IconAdicionales1,
          title:
            "Todos los vidrios colocados en posición vertical de tu vivienda están protegidos ante roturas o rajaduras.",
          toltip:
            "Cobertura sobre todos los vidrios colocados en posición vertical de la vivienda ante roturas o rajaduras.",
        },
      ],
    },
    {
      title: "asistencias",
      contenido: [
        {
          icon: IconAsistencia1,
          title: "Asistencia en Electrodomésticos.",
          toltip:
            "Brindamos ayuda en la instalación (retirada de embalaje, inspección del mismo por daños, desconexión del electrodoméstico viejo, nivelación, labores de instalación básica y puesta en marcha), y además orientación y asistencia telefónica para la solución de problemas de electrodomésticos (línea blanca, TV, Video y Audio).",
          nuevo: false,
        },
        {
          icon: IconAsistencia7,
          title: "Asistencias ante urgencias .",
          parrafo: (
            <>
              <ul>
                <li>Plomería</li>
                <li>Cerrajería</li>
                <li>Electricidad</li>
                <li>Gasista</li>
                <li>Cristalería</li>
                <li>Vigilancia</li>
              </ul>
            </>
          ),
          toltip:
            "Brindamos destape de cañerías fijas e instalación o cambio de grifos, instalación de cortinas, luminarias y/o lámparas apliques, y/o accesorios de baño (incluye perforaciones o método de fijación y la conexión de cables), reparación de filtraciones y humedad (incluye detección, diagnóstico, reparación y prueba de secado), y trabajos generales de carpintería.",
          nuevo: false,
        },
        // {
        //   icon: IconAsistencia2,
        //   title: "Asistencias ante urgencias .",
        //   toltip: "Destapaciones, Alquiler de grupo electrógeno por falta de suministro, Fumigaciones de plagas,	Mantenimiento de cortinas, Plomería, Gasista, Electricidad, Cristalería, Seguridad y vigilancia antes siniestros, Traslado de muebles.",
        //   nuevo: false,
        // },
        {
          icon: IconAsistencia4,
          title: "Servicio de ambulancia a domicilio ante urgencias.",
          toltip:
            "Servicio para vos y tu grupo familiar, siempre que alguno corra riesgo de vida. Incluye personal doméstico. El servicio se brindará únicamente en el domicilio asegurado. ¡Atención! Este servicio no incluye traslados previamente acordados.",
          nuevo: false,
        },
        {
          icon: IconAsistencia5,
          title: "Servicio de asesoramiento médico telefónico con profesionales.",
          toltip:
            "Mediphone es un servicio de información médica telefónica brindado por un exclusivo grupo de profesionales médicos que atenderán todas tus consultas.",
          nuevo: false,
        },
      ],
    },
  ];

  return (
    <>
      <Header />
      <Portada
        portada={portadaEstatica}
        titulo={`Tu hogar más protegido\n\rque nunca`}
        subtitulo="protegé tu casa y tus cosas"
      />
      <Contenido
        opcion="c"
        UrlBtn="/"
        NombreBtn="Descargar Condiciones"
        ArrayItems={ArrayItems}
        asistenciasEspeciales={true}
      />
      <AccordionAsistencias data={dataAsistencias[0].vivienda} />
      <div className="contenedorBreadcrumsCentrado">
        <Breadcrumbs modalMostrar={false} title="Descargar Condiciones" url="/" tipo="vivienda" />
      </div>

      <div className="contenedorBtnPageItem">
        <BtnAction callback={() => handleModal(true)} texto="Cotizá ahora tu seguro" />
      </div>
      <div className="espacioGrisSelect">{/*  <SantanderSelectVertical items={ArrayItemsCardSelect} />*/}</div>
      <AdicionalMascotas />
      <AsistenciaMascotas />
      <InfoSeguros />
      <div className="contenedorBannerServiciosBici">
        <h6>
          {" "}
          <b>Otros clientes también eligieron</b> estos productos
        </h6>
        <CardsServicios vida movil accidentes />
      </div>
      <LegalesSeguros tipo="vivienda" />
      <FooterAll showModal={showModal} handleModal={handleModal} />
    </>
  );
};
