import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../components/Global/Footer/Footer";
import Header from "../components/Global/Header/Header";
import BtnDenunciarUnSiniestro from "../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";

import nomina_de_sujetos_obligados from "../pdf/compliance/2024/nomina_de_sujetos_obligados.pdf";
import nomina_PEP from "../pdf/compliance/2025/nomina_PEP.pdf";
import personaFisica from "../pdf/compliance/2025/ZS248_Formulario de Prevención de Lavado de Activos_Persona Física_250317.pdf";
import personaJuridica from "../pdf/compliance/2025/ZS248_Formulario de Prevención de Lavado de Activos_Persona Juridica_250317.pdf";

export const PrevencionDeLavado = () => {
  return (
    <div>
      <Header />
      <Container>
        <section className="contenedorLegales">
          <h1>Prevención de Lavado de Activos y Financiación del Terrorismo</h1>

          <div>
            <p>
              La Ley N° 25.246 otorga a las empresas aseguradoras el carácter de sujeto obligado, debiendo observar en
              su operatoria las medidas y procedimientos definidos por la Unidad de Información Financiera (UIF). Dicha
              Unidad, establece los criterios, información y documentación que los clientes deben completar para su
              identificación. En cumplimiento de las exigencias mencionadas, a continuación, encontarás los formularios
              de identificación de cliente, para descargar en formato PDF editable que se debe remitir, junto al detalle
              de documentación que le fuera requerido por la entidad.
            </p>
            <h6>Formularios</h6>
            <p>
              <ul>
                <li>
                  <a
                    href={personaFisica}
                    className="text-center "
                    target={"_blank"}
                    style={{ textDecoration: "underline", fontWeight: "400" }}
                    rel="noreferrer"
                  >
                    Formulario Persona Física
                  </a>
                </li>
                <li>
                  <a
                    href={personaJuridica}
                    className="text-center "
                    target={"_blank"}
                    style={{ textDecoration: "underline", fontWeight: "400" }}
                    rel="noreferrer"
                  >
                    Formulario Persona Jurídica
                  </a>
                </li>
              </ul>
            </p>
            <h6>Normativa Vigente</h6>
            <p>
              <ul>
                <li>
                  <a
                    href={"https://servicios.infoleg.gob.ar/infolegInternet/anexos/385000-389999/386742/texact.htm"}
                    className="text-center "
                    target={"_blank"}
                    style={{ textDecoration: "underline", fontWeight: "400" }}
                    rel="noreferrer"
                  >
                    Resolución UIF N° 126/2023
                  </a>
                </li>
                <li>
                  <a
                    href={"https://servicios.infoleg.gob.ar/infolegInternet/anexos/380000-384999/380196/texact.htm"}
                    className="text-center "
                    target={"_blank"}
                    style={{ textDecoration: "underline", fontWeight: "400" }}
                    rel="noreferrer"
                  >
                    Resolución UIF N° 35/2023 y modificatorias
                  </a>
                </li>
                <li>
                  {" "}
                  <a
                    href={"https://servicios.infoleg.gob.ar/infolegInternet/anexos/60000-64999/62977/texact.htm"}
                    className="text-center "
                    target={"_blank"}
                    style={{ textDecoration: "underline", fontWeight: "400" }}
                    rel="noreferrer"
                  >
                    Ley N° 25. 246 y modificatorias
                  </a>
                </li>
                <li>
                  <a
                    href={nomina_PEP}
                    className="text-center "
                    target={"_blank"}
                    style={{ textDecoration: "underline", fontWeight: "400" }}
                    rel="noreferrer"
                  >
                    Nómina de personas expuestas políticamente (PEP)
                  </a>
                </li>
                <li>
                  <a
                    href={nomina_de_sujetos_obligados}
                    className="text-center "
                    target={"_blank"}
                    style={{ textDecoration: "underline", fontWeight: "400" }}
                    rel="noreferrer"
                  >
                    Nómina de Sujetos Obligados
                  </a>
                </li>
              </ul>
            </p>

            <h6>Contacto</h6>
            <p>
              Ante cualquier inquietud, podes dirigir tus consultas a la casilla de correo:{" "}
              <a
                href="mailto:compliance@zurich-santander.com.ar"
                className="text-center "
                target={"_blank"}
                style={{ textDecoration: "underline", fontWeight: "400" }}
                rel="noreferrer"
              >
                compliance@zurich-santander.com.ar
              </a>
            </p>
          </div>
        </section>
      </Container>
      <Footer />
      <BtnDenunciarUnSiniestro />
    </div>
  );
};
