import React from "react";

import IconBtn from "../../../../assets/images/VidaYsalud/Vida/Beneficiarios/IconBtn.svg";
import imgDesignaBeneficiario from "../../../../assets/images/VidaYsalud/Vida/Beneficiarios/imgDesignaBeneficiario.svg";

export const Beneficiarios = () => {
  return (
    <section className="cotenedorBeneficiariosVida">
      <div className="cotenedorBeneficiariosVida-1">
        <img src={imgDesignaBeneficiario} alt="Family" />
      </div>
      <div className="cotenedorBeneficiariosVida-2">
        <h4>Hacelo desde tu Online Banking Santander</h4>
        <h2>Designá a tus beneficiarios 100% online</h2>
        <p>
          Ingresá a Online Banking >> Seguros. Hacé click en los tres puntos verticales del seguro, seleccioná
          “Agregar/Modificar beneficiarios” ¡Y listo!
        </p>
      </div>
      <div className="cotenedorBeneficiariosVida-3">
        <a href="https://www2.personas.santander.com.ar/obp-webapp/angular/#!/login" target={"_blank"} rel="noreferrer">
          {" "}
          <img src={IconBtn} alt="Icon" /> Designar beneficiarios{" "}
        </a>
      </div>
    </section>
  );
};
