import React from "react";
import Reviews from "../components/Global/Reviews/Reviews";
import Footer from "../components/Global/Footer/Footer";
import Header from "../components//Global/Header/Header";
import BtnDenunciarUnSiniestro from "../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";
import BannerContactate from "../components/Global/BannerContactate/BannerContactate";
import { Portada } from "../components/TecnologiaProtegida/Portada";
import { Cards } from "../components/TecnologiaProtegida/Cards";

import iconSmartphoneproteccionMovil from "../assets/images/TecnologiaProtegida/iconSmartphoneproteccionMovil.svg";
import iconMusicaInstrumentosMusicales from "../assets/images/TecnologiaProtegida/iconMusicaInstrumentosMusicales.svg";
import iconGimnasioarticulosDeportivos from "../assets/images/TecnologiaProtegida/iconGimnasioarticulosDeportivos.svg";
import iconFotografiafotografia from "../assets/images/TecnologiaProtegida/iconFotografiafotografia.svg";
import iconNotebooknotebooks from "../assets/images/TecnologiaProtegida/iconNotebooknotebooks.svg";
import iconTablettablets from "../assets/images/TecnologiaProtegida/iconTablettablets.svg";
import iconGaminggaming from "../assets/images/TecnologiaProtegida/iconGaminggaming.svg";

import iconoAudio from "../assets/images/TecnologiaProtegida/iconoAudio.svg";

export const TecnologiaProtegida = () => {
  const items = [
    {
      icono: iconSmartphoneproteccionMovil,
      subtitulo: "Tu smartphone protegido",
      titulo: "Protección Móvil",
      url: "/tecnologia-protegida/proteccion-movil",
    },
    {
      icono: iconNotebooknotebooks,
      subtitulo: "ASEGURÁ LO QUE QUERÉS",
      titulo: "Notebooks",
      url: "/tecnologia-protegida/notebooks",
    },
    {
      icono: iconFotografiafotografia,
      subtitulo: "ASEGURÁ LO QUE QUERÉS",
      titulo: "Fotografía",
      url: "/tecnologia-protegida/fotografia",
    },

    {
      icono: iconTablettablets,
      subtitulo: "ASEGURÁ LO QUE QUERÉS",
      titulo: "Tablets",
      url: "/tecnologia-protegida/tablets",
    },
    {
      icono: iconGaminggaming,
      subtitulo: "ASEGURÁ LO QUE QUERÉS",
      titulo: "Videojuegos",
      url: "/tecnologia-protegida/gaming",
    },
    {
      icono: iconoAudio,
      subtitulo: "ASEGURÁ LO QUE QUERÉS",
      titulo: "Audio",
      url: "/tecnologia-protegida/audio",
    },
    {
      icono: iconMusicaInstrumentosMusicales,
      subtitulo: "ASEGURÁ LO QUE QUERÉS",
      titulo: "Instrumentos Musicales",
      url: "/tecnologia-protegida/instrumentos-musicales",
    },
    {
      icono: iconGimnasioarticulosDeportivos,
      subtitulo: "ASEGURÁ LO QUE QUERÉS",
      titulo: "Artículos Deportivos",
      url: "/tecnologia-protegida/articulos-deportivos",
    },
  ];
  return (
    <div>
      <Header />
      <Portada subTitulo="Protegé lo que más te gusta" titulo="Tecnología y Entretenimiento" />
      <Cards items={items} />
      <Reviews />
      <div className="contenedorBannerContactateTecnologiaProtegida">
        <BannerContactate />
      </div>
      <Footer />
      <BtnDenunciarUnSiniestro />
    </div>
  );
};
