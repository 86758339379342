import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../components/Global/Footer/Footer";
import Header from "../components//Global/Header/Header";
import BtnDenunciarUnSiniestro from "../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";

export const servicioDeAtencionAlAsegurado = () => {
  return (
    <div>
      <Header />
      <Container>
        <section className="contenedorLegales">
          <h1>Servicio de Atención al asegurado</h1>

          <div>
            <p>
              La entidad aseguradora dispone de un <b>SERVICIO DE ATENCIÓN AL ASEGURADO</b> que atenderá las consultas y
              reclamos que presenten los tomadores de seguros, asegurados, beneficiarios y/o derechohabientes.
            </p>
          </div>
          <div>
            <p>
              <b>RESPONSABLE:</b> Samokec Natalia Gabriela
            </p>
            <p>
              <b>SUPLENTES:</b> Fuentes, María Jimena y Tomatis, Gonzalo Gastón.
            </p>
            <p>
              <b>TELÉFONO/INTERNO:</b> 4341-3339/4341-3422.
            </p>
            <p>
              <b>CORREO ELECTRÓNICO DE CONTACTO:</b>{" "}
              <a href="mailto:atencionalasegurado@zurich-santander.com.ar" style={{ color: "#003399" }}>
                atencionalasegurado@zurich-santander.com.ar
              </a>
            </p>
            <p>Nº DE INSCRIPCIÓN DE LA COMPAÑÍA ANTE LA SSN: 0692.</p>
          </div>
          <div>
            <p>
              En caso de reclamo ante la entidad aseguradora no resuelto o desestimado, podrá denunciarlo a través de{" "}
              <a href="https://argentina.gob.ar/ssn" target={"_blank"} rel="noreferrer">
                https://argentina.gob.ar/ssn
              </a>
            </p>
          </div>
        </section>
      </Container>
      <Footer />
      <BtnDenunciarUnSiniestro />
    </div>
  );
};
