import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import IconArrowGrey from "../../../assets/images/Global/IconArrowGrey.svg";

import IconDangerRojo from "../../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/iconRequisitosRojoDanger.svg";
import IconDangerAzul from "../../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/iconDangerAzul.svg";
import IconClose from "../../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/IconClose.svg";
import iconRequisitos from "../../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/iconRequisitos.svg";
import IconCheckItems from "../../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/IconCheckItems.svg";
import IconCheveronRight from "../../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/IconCheveronRight.svg";

import separadorGris from "../../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/separadorGris.svg";

import { getConditions } from "../../../services/conditionsService";
import { useLocation } from "react-router-dom";

export const Breadcrumbs = ({ modalMostrar, title, url, opcionModal = true, tipo }) => {
  let location = useLocation();
  const [show, setShow] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [, setLoading] = useState(false);
  const [, setError] = useState(false);
  const [, setNewPath] = useState(location.pathname);

  useEffect(() => {
    if (location.pathname === "/") {
      setNewPath("home");
    } else {
      setNewPath(location.pathname.substring(location.pathname.lastIndexOf("/") + 1));
    }
  }, [location]);

  const handleClose = () => setShow(false);

  const handleCallApi = async () => {
    setLoading(true);

    await getConditions()
      .then((res) => {
        setConditions(res.data);
      })
      .catch((err) => {
        setError(err);
      });

    setLoading(false);
  };

  useEffect(() => {
    handleCallApi();
  }, []);

  // console.log(conditions);
  // console.log(newPath);

  return (
    <Container>
      <section className="contenedorBreadcrumbs">
        <ul className="d-flex justify-content-start flex-column align-items-start align-content-start">
          {/* {modalMostrar && (
            <li className="contenedorBreadcrumbs-blue">
              <button onClick={handleShow}>
                Condiciones y Exclusiones
                <img src={IconArrowBlue} alt="Icon Arrow" />
              </button>
            </li>
          )} */}
          {conditions &&
            conditions.length > 0 &&
            conditions
              // .filter((t) => t.attributes.Mostrar_En.data)

              .filter((c) => c.attributes.Mostrar_en.data.some((cond) => cond.attributes.Nombre === "vida"))
              .map((c, index) => (
                <li className="contenedorBreadcrumbs-grey mb-3">
                  <a
                    href={`${c.attributes.Archivo.data.attributes.url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {title}
                    <img src={IconArrowGrey} alt="Icon Arrow" />
                  </a>
                </li>
              ))}
          {/* <li className="contenedorBreadcrumbs-grey">
            <a href={tipo === "vivienda" ? folleto_vivienda : url}>
              {title}
              <img src={IconArrowGrey} alt="Icon Arrow" />
            </a>
          </li> */}
        </ul>
      </section>

      {opcionModal && (
        <Modal show={show} onHide={handleClose} className="modalParaOpcionC" centered>
          <div className="ModalContenidoOpcionC">
            <div className="ModalContenidoOpcionC-title">
              <h4>Condiciones y Exclusiones</h4>
              <img src={IconClose} alt="Icon Close" onClick={handleClose} />
            </div>
            <div className="ModalContenidoOpcionC-subtitle">
              <img src={IconDangerRojo} alt="Icon Danger" />
              <p>Actividades excluídas (en general)</p>
            </div>
            <div className="ModalContenidoOpcionC-body">
              <div className="ModalContenidoOpcionC-list">
                <p>
                  {" "}
                  <img src={IconDangerAzul} alt="Icon" /> Algodoneras
                </p>
                <p>
                  {" "}
                  <img src={IconDangerAzul} alt="Icon" /> Depósitos (cómo única actividad)
                </p>
                <p>
                  {" "}
                  <img src={IconDangerAzul} alt="Icon" /> Almacenes de más de 400 mt2
                </p>
                <p>
                  {" "}
                  <img src={IconDangerAzul} alt="Icon" /> Fábricas en general
                </p>
                <p>
                  {" "}
                  <img src={IconDangerAzul} alt="Icon" /> Industrias madereras, aserraderos, carpinterías
                </p>
                <p>
                  {" "}
                  <img src={IconDangerAzul} alt="Icon" /> Industrias plásticas y cacuchos
                </p>
              </div>
              <div className="ModalContenidoOpcionC-list">
                <p>
                  {" "}
                  <img src={IconDangerAzul} alt="Icon" /> Industrias químicas y petroquímicas
                </p>
                <p>
                  {" "}
                  <img src={IconDangerAzul} alt="Icon" /> Industrias textiles
                </p>
                <p>
                  {" "}
                  <img src={IconDangerAzul} alt="Icon" /> Mayoristas
                </p>
                <p>
                  {" "}
                  <img src={IconDangerAzul} alt="Icon" /> Metalúrgicas (primary metals - con hornos de fundición)
                </p>
                <p>
                  {" "}
                  <img src={IconDangerAzul} alt="Icon" /> Papeleras
                </p>
                <p>
                  {" "}
                  <img src={IconDangerAzul} alt="Icon" /> Terrenos, vías, siembras, bosques, animales
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {!opcionModal && (
        <Modal show={show} onHide={handleClose} className="modalParaOpcionC" centered>
          <div className="ModalContenidoOpcionC">
            <div className="ModalContenidoOpcionC-title">
              <h4>Condiciones y Exclusiones</h4>
              <img src={IconClose} alt="Icon Close" onClick={handleClose} />
            </div>
            <div className="ModalContenidoOpcionC-body">
              <div className="ModalContenidoOpcionC-list">
                <h6>
                  {" "}
                  <img src={iconRequisitos} alt="Icon" /> Requisitos
                </h6>
                <img src={separadorGris} alt="Icon" className="lineaSeparadoraBeadcroum" />
                <p>
                  {" "}
                  <img src={IconCheckItems} alt="Icon" /> Edad Mínima: 18 años
                </p>
                <p>
                  {" "}
                  <img src={IconCheckItems} alt="Icon" /> Edad Máxima: 60 años
                </p>
                <p>
                  {" "}
                  <img src={IconCheckItems} alt="Icon" /> Edad Máxima para cobertura de muerte únicamente: 65 años
                </p>
              </div>
              <div className="ModalContenidoOpcionC-list">
                <h6>
                  {" "}
                  <img src={IconDangerRojo} alt="Icon" /> No cubrimos
                </h6>
                <img src={separadorGris} alt="Icon" className="lineaSeparadoraBeadcroum" />
                <p>
                  {" "}
                  <img src={IconDangerAzul} alt="Icon" /> Accidentes en motos.
                </p>
                <p>
                  {" "}
                  <img src={IconDangerAzul} alt="Icon" /> Deportes o actividades de riesgo.
                </p>
                <p>
                  {" "}
                  <img src={IconDangerAzul} alt="Icon" /> Muerte o incapacidad permanente por enfermedades previas.
                </p>
              </div>
            </div>
            <div className="btnVerDetallesModal">
              <Link to="/">
                Ver detalles de exclusiones <img src={IconCheveronRight} alt="Icon" />{" "}
              </Link>
            </div>
          </div>
        </Modal>
      )}
    </Container>
  );
};
