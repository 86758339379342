import React from "react";
import { Link } from "react-router-dom";

const BtnDenunciarUnSiniestro = () => {
  return (
    <>
      <Link to="/tuve-un-siniestro" className="btn-denunciar-un-siniestro">
        Cómo denunciar un siniestro
      </Link>
    </>
  );
};

export default BtnDenunciarUnSiniestro;
