import React from "react";
import { Link } from "react-router-dom";
import IconArrowRightBtn from "../../../assets/images/icons/IconArrowRightBtn.svg";

const ItemNovedadZurich = ({ imagen, fecha, title, description, UrlLink }) => {
  return (
    <div className="novedadesZurich-item mb-4">
      {imagen && (
        <div className="novedadesZurich-item-header">
          <img src={imagen} alt={title} />
        </div>
      )}
      <div className="novedadesZurich-item-body">
        <span> {fecha} </span>
        <h4> {title} </h4>
        <p> {description} </p>
        <Link to={UrlLink}>
          Seguir leyendo <img src={IconArrowRightBtn} alt="Icon Arrow" />{" "}
        </Link>
      </div>
    </div>
  );
};

export default ItemNovedadZurich;
