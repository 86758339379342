import React from "react";
import IconArrowBtn from "../../assets/images/Contacto/IconArrowBtn.svg";

export const CardNotWithIcons = ({ titulo, parrafo, direccion, nombreBtn, target }) => {
  return (
    <div className="cardNotWitchIcons-card">
      <h6> {titulo} </h6>
      <p> {parrafo} </p>
      <a href={direccion} target={target && target}>
        {nombreBtn} <img src={IconArrowBtn} alt="Icon Arrow" />{" "}
      </a>
    </div>
  );
};
