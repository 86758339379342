import Header from "../../../Global/Header/Header";
import { Portada } from "../../../TecnologiaProtegida/PageItem/Portada";
import portadaEstatica from "../../../../assets/images/HogarYnegocio/IntegralDelNegocio/TiendaDeRopa/Portada.svg";
import { tiendasDeRopa } from "../../../../utils/resources/coberturasyasistencias";
import ContenidoNegocio from "./ContenidoNegocio";

const TiendasDeRopa = () => {
  const ArrayItems = tiendasDeRopa;

  return (
    <>
      <Header />
      <Portada portada={portadaEstatica} titulo="Tienda de Ropa" subtitulo="La cobertura justa para tu negocio" />
      <ContenidoNegocio ArrayItems={ArrayItems} />
    </>
  );
};

export default TiendasDeRopa;
