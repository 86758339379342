import React, { useState } from "react";
import Header from "../../Global/Header/Header";
import { Portada } from "../../TecnologiaProtegida/PageItem/Portada";
import { Contenido } from "../../TecnologiaProtegida/PageItem/Contenido";
import CardsServicios from "../../Home/CardsServicios/CardsServicios";
import portadaEstatica from "../../../assets/images/ComprasYgastos/ComprasProtegidas/Portada.svg";
import Icon1 from "../../../assets/images/ComprasYgastos/ComprasProtegidas/IconTarjeta.svg";
import { Breadcrumbs } from "../../Global/Breadcrumbs/Breadcrumbs";
import LegalesSeguros from "../../LegalesSeguros/LegalesSeguros";
import AccordionAsistencias from "../../AccordionAsistencias/AccordionAsistencias";
import { dataAsistencias } from "../../../utils/resources/nuevasAsistencias";
import FooterAll from "../../FooterAll";
import InfoCompras from "../InformacionCompras";

export const ComprasProtegidas = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModal = (v) => {
    setShowModal(v);
  };

  const ArrayItems = [
    {
      title: "primero",
      contenido: [
        {
          icon: Icon1,
          title: "Cobertura por robo y daños accidentales de los bienes muebles adquiridos con Tarjetas Santander",
          parrafo:
            "Cubre las compras realizadas con las Tarjetas Santander de crédito y débito. Incluye robos y daños accidentales que ocurran dentro de los 120 días de adquirido siempre que el bien haya sido comprado durante la vigencia de la póliza. ",
        },
      ],
    },
  ];

  return (
    <>
      <Header />
      <Portada
        portada={portadaEstatica}
        titulo="Compra protegida"
        subtitulo="Tus compras protegidas durante 120 días"
      />
      <Contenido opcion="d" UrlBtn="/" NombreBtn="Descargar Condiciones" ArrayItems={ArrayItems} proteccion="compras" />

      <AccordionAsistencias data={dataAsistencias[0].cartera} />

      <div className="contenedorBreadcrumsCentrado">
        <Breadcrumbs modalMostrar={false} title="Descargar Condiciones" url="/" />
      </div>
      <InfoCompras callback={() => handleModal(true)} />
      <div className="contenedorBannerServiciosBici">
        <h6>
          {" "}
          <b>Otros clientes también eligieron</b> estos productos
        </h6>
        <CardsServicios vivienda vida gastos />
      </div>
      <LegalesSeguros tipo="compras-protegidas" />

      <FooterAll showModal={showModal} handleModal={handleModal} />
    </>
  );
};
