import React from "react";
import { Link } from "react-router-dom";
import IconArrowRight from "../../assets/images/Novedades/IconArrowRight.svg";

export const ItemNovedades = ({ imagen, date, title, abstract, url }) => {
  return (
    <div className="cardItemNovedades">
      {imagen && (
        <div className="cardItemNovedades-img">
          <img src={imagen} alt={title} />
        </div>
      )}
      <div className="cardItemNovedades-body">
        <h5> {date} </h5>
        <h2> {title} </h2>
        <p> {abstract} </p>
        <Link to={url}>
          Seguir leyendo <img src={IconArrowRight} alt="Icon Arrow" />{" "}
        </Link>
      </div>
    </div>
  );
};
