import React from "react";
import { Container } from "react-bootstrap";
import { CardWithIcons } from "./CardWithIcons";

import iconCompu from "../../assets/images/Contacto/iconCompu.svg";
import iconPhone from "../../assets/images/Contacto/IconPhone.svg";

export const SectionConIconos = () => {
  return (
    <Container>
      <section className="contenedorCardConIconos">
        <CardWithIcons
          imagen={iconCompu}
          titulo="Online banking Santander"
          parrafo="Consultá, contratá y administrá tus seguros y beneficiarios."
          direccion="https://www2.personas.santander.com.ar/obp-webapp/angular/#!/login"
          target={"_blank"}
          nombreBoton="Ingresar"
        />
        <CardWithIcons
          imagen={iconPhone}
          titulo="Asesoramiento"
          parrafo="Asesoramiento sobre productos y servicios."
          texto={"4345-2400 ó 0810-333-2400"}
        />
        <CardWithIcons
          imagen={iconPhone}
          titulo="Asistencia"
          parrafo="Para solicitar alguno de los servicios de asistencias."
          texto={"0800-444-0313"}
        />
        {/* <CardWithIcons
          imagen={iconMail}
          titulo="Escribinos"
          parrafo="Envianos un mensaje con tu consuta."
          direccion="/escribenos"
          nombreBoton="Completá el formulario"
        /> */}
      </section>
    </Container>
  );
};
