import React from "react";
import { Accordion, Row, Col } from "react-bootstrap";

const AccordionAsistencias = ({ data }) => {
  console.log(data);
  return (
    <div className="p-5 contenedorAsistenciasVida-columnas-dropdown">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey={1} key={1}>
          <Accordion.Header className="h1-accordion">Ver Detalles</Accordion.Header>
          <Accordion.Body>
            <Row>
              {data.contents.map((content, contentIndex) => (
                <Col md={6} xs={11} className="mx-auto" key={contentIndex}>
                  <ul className="list-unstyled listadescargas">
                    {content.map((listItem, listItemIndex) => (
                      <li className={listItemIndex === 0 ? "pt-1" : ""} key={listItemIndex}>
                        {listItem.type === "header" ? <h6>{listItem.text}</h6> : null}
                        {listItem.type === "link" ? (
                          <a
                            href={listItem.href}
                            className="linkDescargaIcon"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {listItem.text}
                          </a>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default AccordionAsistencias;
