import { Col, Modal, Row } from "react-bootstrap";

import IconClose from "../../assets/images/TuveUnSiniestro/IconClose.svg";
import IconContrata from "../../assets/images/TecnologiaProtegida/Icono-contrataApp.png";
import Icon1 from "../../assets/images/TecnologiaProtegida/num/1.png";
import Icon2 from "../../assets/images/TecnologiaProtegida/num/2.png";
import Icon3 from "../../assets/images/TecnologiaProtegida/num/3.png";
import Icon4 from "../../assets/images/TecnologiaProtegida/num/4.png";
import Icon5 from "../../assets/images/TecnologiaProtegida/num/5.png";

export const ModalContratarTecno = ({ estadoBoton, handleModal }) => {
  return (
    <div className="contenedorModal">
      <Modal show={estadoBoton} onHide={() => handleModal(false)} className="contenedorModal" centered>
        <div className="contenedorBodyModal">
          <div className="contenedorBodyModal-title text-start">
            <div className="text-start w-100">
              <h4 className="text-start w-100">Contratá un seguro para tu tecnología </h4>
            </div>
            <img onClick={() => handleModal(false)} src={IconClose} alt="Icon Close" />
          </div>

          <Row className="pt-4">
            <Col md={3} className="text-center d-md-block d-none">
              <img src={IconContrata} alt="QR" style={{ maxHeight: "180px" }} />
            </Col>
            <Col md={8} className="text-center">
              <div className="modalTech">
                <Row>
                  <Col md={6}>
                    <div>
                      <div>
                        <div className="d-flex justify-content-start text-start flex-row mb-4 ">
                          <div style={{ minWidth: "60px" }}>
                            <img src={Icon1} alt="Santander" />
                          </div>

                          <p className="ml-0">
                            Ingresá a la <b>App</b>.
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex justify-content-start text-start flex-row mb-4">
                          <div style={{ minWidth: "60px" }}>
                            <img src={Icon2} alt="Santander" />
                          </div>

                          <p className="ml-0">
                            En la Sección <b>Seguros/Multiasistencias</b>, ubicado en el menú.
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex justify-content-start text-start flex-row mb-4 ">
                          <div style={{ minWidth: "60px" }}>
                            <img src={Icon3} alt="Santander" />
                          </div>

                          <p className="ml-0">
                            <b>Cotizár nuevo seguro</b>, y luego: <b>Protección inteligente</b>.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div>
                      <div className="d-flex justify-content-start text-start flex-row mb-4">
                        <div style={{ minWidth: "60px" }}>
                          <img src={Icon4} alt="Santander" />
                        </div>

                        <p className="ml-0">
                          Elegí entre los <b>planes disponibles</b> para tu equipo
                        </p>
                      </div>
                      <div className="d-flex justify-content-start text-start flex-row mb-4">
                        <div style={{ minWidth: "60px" }}>
                          <img src={Icon5} alt="Santander" />
                        </div>

                        <p className="ml-0">
                          <b>¡Confirmá contratación, y listo!</b> Ya podés disfrutar de los beneficios de estar
                          asegurado.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};
