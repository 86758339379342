import React from 'react';
import { FormEscribenos } from '../components/Escribenos/FormEscribenos';
import { TituloEscribenos } from '../components/Escribenos/TituloEscribenos';
import Footer from '../components/Global/Footer/Footer'
import Header from "../components//Global/Header/Header";
import BtnDenunciarUnSiniestro from '../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro';

import RectanguloEscribenos from "../assets/images/Escribenos/RectanguloEscribenos.svg";

export const Escribenos = () => {
  return (
    <>
        <Header />
        <TituloEscribenos />
        <FormEscribenos />
        <img src={RectanguloEscribenos} alt="Icon" className='imagenFlotandoEscribenos' />
        <Footer />
        <BtnDenunciarUnSiniestro />
    </>
  )
}
