import React, { useState } from "react";
import Header from "../../Global/Header/Header";
import { Portada } from "../../TecnologiaProtegida/PageItem/Portada";
import CardsServicios from "../../Home/CardsServicios/CardsServicios";
import portadaEstatica from "../../../assets/images/ComprasYgastos/GastosProtegidos/Portada.svg";
import { Tablas } from "../../Global/Tablas/Tablas";
import Icon1 from "../../../assets/images/ComprasYgastos/GastosProtegidos/Icon1.svg";
import Icon2 from "../../../assets/images/ComprasYgastos/GastosProtegidos/Icon2.svg";
import Icon3 from "../../../assets/images/ComprasYgastos/GastosProtegidos/Icon3.svg";
import { Breadcrumbs } from "../../Global/Breadcrumbs/Breadcrumbs";
import LegalesSeguros from "../../LegalesSeguros/LegalesSeguros";
import AccordionAsistencias from "../../AccordionAsistencias/AccordionAsistencias";
import { dataAsistencias } from "../../../utils/resources/nuevasAsistencias";
import FooterAll from "../../FooterAll";
import InfoCompras from "../InformacionCompras";

export const GastosProtegidos = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModal = (v) => {
    setShowModal(v);
  };

  const datosApi = [
    {
      icono: Icon1,
      titulo: "Cobertura ante desempleo involuntario",
      toltip:
        "Cubre hasta 6 pagos mensuales en caso de desempleo involuntario (despido sin causa, acción gremial o sindical y/o despido general, suspensión en el empleo por motivos no imputables al empleado, quiebra del empleador o cierre definitivo del establecimiento)",
      mostrar1: true,
      mostrar2: false,
    },
    {
      icono: Icon2,
      titulo: "Indemnización en caso de pérdida de la vida",
      toltip: "Se abonará a los beneficiarios el monto de la suma asegurada.",
      mostrar1: true,
      mostrar2: true,
    },
    {
      icono: Icon3,
      titulo: "Cobertura ante incapacidad como consecuencia de enfermedad o accidente",
      toltip:
        "Hasta 6 pagos mensuales, en caso de incapacidad total como consecuencia de una enfermedad o accidente que te impida ejercer toda actividad lucrativa por un período mayor a 30 días.",
      mostrar1: false,
      mostrar2: true,
    },
  ];

  return (
    <>
      <Header />
      <Portada portada={portadaEstatica} titulo="Gastos protegidos" subtitulo="Tranquilidad para vos y tu familia" />
      <Tablas
        //tituloNegrita="Cuidamos"
        //tituloNormal="tu dinero"
        TitulosColumnas1="Plan trabajador
          relación de dependencia"
        TitulosColumnas2="Plan
          autónomos
          o empleados estatales"
        arrayContenido={datosApi}
      />

      <AccordionAsistencias data={dataAsistencias[0].cartera} />

      <div className="contenedorBreadcrumsInstanciado">
        <Breadcrumbs modalMostrar={false} title="Descargar Condiciones" url="/" />
      </div>

      <InfoCompras callback={() => handleModal(true)} />
      <div className="contenedorBannerServiciosBici">
        <h6>
          {" "}
          <b>Otros clientes también eligieron</b> estos productos
        </h6>
        <CardsServicios compra vivienda vida />
      </div>
      <LegalesSeguros tipo="gastos-protegidos" />
      <FooterAll showModal={showModal} handleModal={handleModal} />
    </>
  );
};
