import Header from "../../../Global/Header/Header";
import { Portada } from "../../../TecnologiaProtegida/PageItem/Portada";

import portadaEstatica from "../../../../assets/images/HogarYnegocio/IntegralDelNegocio/LocalDeShoppings/Portada.svg";
import { consultoriosMedicos_localDeShoppings_otrosNegocios } from "../../../../utils/resources/coberturasyasistencias";

import ContenidoNegocio from "./ContenidoNegocio";

const LocalDeShoppings = () => {
  const ArrayItems = consultoriosMedicos_localDeShoppings_otrosNegocios;

  return (
    <>
      <Header />
      <Portada portada={portadaEstatica} titulo="Local de Shoppings" subtitulo="La cobertura justa para tu negocio" />
      <ContenidoNegocio ArrayItems={ArrayItems} />
    </>
  );
};
export default LocalDeShoppings;
