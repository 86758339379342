import React from 'react'
import { Container } from 'react-bootstrap'

export const Portada = ({titulo,subTitulo}) => {
  return (
    <Container>
        <section className='contenedorPortadaTecnologiaProtegida'>
            <h2> {subTitulo} </h2>
            <h3>{titulo}</h3>
        </section>
    </Container>
  )
}
