import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CardsItems from "../CardsItems/CardsItems";

import iconVida from "../../../assets/images/icons/iconVida.png";
import iconVivienda from "../../../assets/images/icons/iconVivienda.svg";
import iconAccidentesPersonales from "../../../assets/images/icons/iconAccidentesPersonales.svg";
import iconGastosProtegidos from "../../../assets/images/icons/iconGastosProtegidos.svg";
import iconCarteraProtegida from "../../../assets/images/icons/iconCarteraProtegida.png";
import iconComprasProtegidas from "../../../assets/images/icons/iconComprasProtegidas.png";
import iconSmartphone from "../../../assets/images/icons/iconSmartphone.png";
import iconNegocio from "../../../assets/images/HogarYnegocio/iconNegocio.svg";

const CardsServicios = ({
  vida,
  vivienda,
  accidentes,
  negocio,
  extracciones,
  movil,
  compra,
  gastos,
  cartera,
  inteligente,
}) => {
  return (
    <Container fluid className="cards-home">
      <Container>
        <Row>
          {vida && (
            <Col lg={4}>
              <CardsItems icono={iconVida} title="Vida" category="Vida y salud" direccion="/vida-y-salud/vida" />
            </Col>
          )}

          {vivienda && (
            <Col lg={4}>
              <CardsItems
                icono={iconVivienda}
                title="Vivienda"
                category="hogar y negocio"
                direccion="/hogar-y-negocio/vivienda"
              />
            </Col>
          )}

          {negocio && (
            <Col lg={4}>
              <CardsItems
                icono={iconNegocio}
                title="Negocio Protegido"
                category="hogar y negocio"
                direccion="/hogar-y-negocio/negocio-protegido"
              />
            </Col>
          )}

          {accidentes && (
            <Col lg={4}>
              <CardsItems
                icono={iconAccidentesPersonales}
                title={`Accidentes\n\rPersonales`}
                category="vida y salud"
                direccion="/vida-y-salud/accidentes-personales"
              />
            </Col>
          )}

          {extracciones && (
            <Col lg={4}>
              <CardsItems
                icono={iconGastosProtegidos}
                title={`Extracciones\n\rProtegidas`}
                category="hogar y negocio"
                direccion="/compras-y-gastos/extracciones-protegidas"
              />
            </Col>
          )}

          {movil && (
            <Col lg={4}>
              <CardsItems
                icono={iconSmartphone}
                title={`Protección\n\rMóvil`}
                category="Tecnología y entretenimiento"
                direccion="/tecnologia-protegida/proteccion-movil"
              />
            </Col>
          )}

          {compra && (
            <Col lg={4}>
              <CardsItems
                icono={iconComprasProtegidas}
                title={`Compra Protegida`}
                category="Compras y gastos"
                direccion="/compras-y-gastos/compra-protegida"
              />
            </Col>
          )}

          {gastos && (
            <Col lg={4}>
              <CardsItems
                icono={iconGastosProtegidos}
                title={`Gastos protegidos`}
                category="Compras y gastos"
                direccion="/compras-y-gastos/gastos-protegidos"
              />
            </Col>
          )}

          {inteligente && (
            <Col lg={4}>
              <CardsItems
                icono={iconGastosProtegidos}
                title={`Protección \n inteligente`}
                category="Tecnología y entretenimiento"
                direccion="/tecnologia-protegida"
              />
            </Col>
          )}

          {cartera && (
            <Col lg={4}>
              <CardsItems
                icono={iconCarteraProtegida}
                title={`Celular + Bolso`}
                category="Compras y gastos"
                direccion="/compras-y-gastos/proteccion-carteras"
              />
            </Col>
          )}
        </Row>
      </Container>
    </Container>
  );
};

export default CardsServicios;
