import React from 'react'
import { Container } from 'react-bootstrap'
import { CardsItems } from './CardsItems'



export const Cards = ({items}) => {
  return (
    <Container>
        <section className='contenedorCardsTecnologiaProtegida'>
            {
                items.map((item, index) => {
                    return <CardsItems
                        key={index}
                        icono={item.icono}
                        subtitulo={item.subtitulo}
                        titulo={item.titulo}
                        url={item.url}
                    />
                })

            }

        </section>
    </Container>
  )
}
