import React from "react";
import Reviews from "../components/Global/Reviews/Reviews";
import Footer from "../components/Global/Footer/Footer";
import Header from "../components//Global/Header/Header";
import BtnDenunciarUnSiniestro from "../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";
import BannerContactate from "../components/Global/BannerContactate/BannerContactate";
import { Portada } from "../components/TecnologiaProtegida/Portada";
import { Cards } from "../components/TecnologiaProtegida/Cards";

import iconComprasProtegidas from "../assets/images/ComprasYgastos/iconComprasProtegidas.svg";
import iconExtraccionesProtegidas from "../assets/images/ComprasYgastos/iconExtraccionesProtegidas.svg";
import iconGastosProtegidos from "../assets/images/ComprasYgastos/iconGastosProtegidos.svg";
import iconProteccionCarteras from "../assets/images/ComprasYgastos/iconProteccionCarteras.svg";

export const ComprasYgastos = () => {
  const items = [
    {
      icono: iconComprasProtegidas,
      subtitulo: "Cubrí tus consumos",
      titulo: "Compra Protegida",
      url: "/compras-y-gastos/compra-protegida",
    },
    {
      icono: iconExtraccionesProtegidas,
      subtitulo: "protegete vos, tu bolso y tu dinero",
      titulo: "Extracciones Protegidas",
      url: "/compras-y-gastos/extracciones-protegidas",
    },
    {
      icono: iconGastosProtegidos,
      subtitulo: "tranquilidad para la famlia",
      titulo: "Gastos Protegidos",
      url: "/compras-y-gastos/gastos-protegidos",
    },
    {
      icono: iconProteccionCarteras,
      subtitulo: "protegé tus cosas",
      titulo: "Celular + Bolso",
      url: "/compras-y-gastos/proteccion-carteras",
    },
  ];
  return (
    <div>
      <Header />
      <Portada subTitulo="" titulo="Compras y Gastos" />
      <Cards items={items} />
      <Reviews />
      <div className="contenedorBannerContactateTecnologiaProtegida">
        <BannerContactate />
      </div>

      <Footer />
      <BtnDenunciarUnSiniestro />
    </div>
  );
};
