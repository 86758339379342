import React from "react";
import { Accordion, Container } from "react-bootstrap";
import Footer from "../components/Global/Footer/Footer";
import Header from "../components//Global/Header/Header";
import BtnDenunciarUnSiniestro from "../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";
import conducta from "../pdf/gobiernoCorporativo/conducta.pdf";
import directorio from "../pdf/gobiernoCorporativo/directorio.pdf";
import manual_de_gobierno_corporativo from "../pdf/gobiernoCorporativo/manual_de_gobierno_corporativo.pdf";
import prevencion_de_fraude from "../pdf/gobiernoCorporativo/prevencion_de_fraude.pdf";
import informe_eecc from "../pdf/gobiernoCorporativo/MEMORIA_EECC_INFORME_WEB.pdf";
import informe_septiembre from "../pdf/gobiernoCorporativo/EECC_CON_INFORME_PARA_WEB_202409.pdf";
import informe_diciembre from "../pdf/gobiernoCorporativo/informe_diciembre.pdf";

export const gobiernoCorporativoySustentabilidad = () => {
  return (
    <div>
      <Header />
      <Container>
        <section className="contenedorLegales nomargin">
          <h1>Gobierno Corporativo y Sustentabilidad</h1>

          <div>
            <h6>Introducción y Antecedentes:</h6>
            <p>
              Esta sección expone los contenidos básicos de nuestro <b>Gobierno Corporativo</b> entendido como el
              conjunto de pautas técnicas, culturales y organizaciones que propendan a la correcta gestión de los
              riesgos asumidos por nuestra Organización con el fin último de velar por el beneficio de los asegurados,
              terceros interesados y la sustentabilidad de nuestra Entidad en el tiempo.
            </p>
            <p>
              Nuestro marco de <b>Gobierno Corporativo</b> se propone apoyar y mejorar la capacidad de los actores
              principales responsables de la gestión empresarial: Órgano de Administración, la Alta Gerencia y las
              personas claves con funciones de control. Esto busca generar una administración adecuada, prudente,
              objetiva en su toma de decisiones y, sobre todo, transparente de cara a todas las partes interesadas de
              nuestra Organización.
            </p>
          </div>

          <div>
            <h6>Reglamento de Gobierno Corporativo</h6>
            <p>
              El presente manual tiene por objetivo recopilar las normas correspondientes a Gobierno Corporativo del
              Grupo Zurich y propias de Zurich Santander Seguros Argentina S.A. (en adelante denominada indistintamente
              “Zurich Santander” o “la Cía.”) a fin de mantener actualizados los procedimientos de la Cía. para dar
              cumplimiento a la Resolución N° 1119/18 de la Superintendencia de Seguros de la Nación.
            </p>
            <p>
              Las políticas de Gobierno Corporativo del Grupo Zurich como así también las propias de Zurich Santander
              tienen como finalidad perseguir la transparencia y la independencia de las decisiones a tomar por el
              Órgano de Administración y la Alta Gerencia.
            </p>

            <p>
              •{" "}
              <a href={manual_de_gobierno_corporativo} target="_blank" rel="noreferrer">
                Manual de Gobierno Corporativo
              </a>{" "}
            </p>

            <p>
              •{" "}
              <a href={directorio} target="_blank" rel="noreferrer">
                Estructura del Órgano de Administración y de la Alta Gerencia
              </a>{" "}
            </p>

            <p className="cont_accordion">
              <Accordion className="pb-0 mb-0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="cardtitle">
                    {" "}
                    • <b className="ps-1"> Información Financiera</b>
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="cardtitle">
                          <b className="ps-3">2024</b>
                        </Accordion.Header>
                        <Accordion.Body className="pt-1 pb-0 mb-0">
                          <ul className="list-unstyled pb-0 mb-0">
                            <li>
                              <a href={informe_eecc} target="_blank" rel="noreferrer" className="link-pdf-gob">
                                Junio 2024 - Estados Contables Anuales
                              </a>
                            </li>
                            <li>
                              <a href={informe_septiembre} target="_blank" rel="noreferrer" className="link-pdf-gob">
                                Septiembre 2024 - Estados Contables Trimestrales
                              </a>
                            </li>
                            <li>
                              <a href={informe_diciembre} target="_blank" rel="noreferrer" className="link-pdf-gob">
                                Diciembre 2024 - Estados Contables Trimestrales
                              </a>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </p>

            <p>
              •{" "}
              <a href={conducta} target="_blank" rel="noreferrer">
                Código de Conducta
              </a>{" "}
            </p>

            <p>
              •{" "}
              <a href={prevencion_de_fraude} target="_blank" rel="noreferrer">
                Prevención al Fraude
              </a>{" "}
            </p>
          </div>

          {/* <div>
            <h6>Responsabilidad Social Empresaria</h6>
            <p>
              {" "}
              La estrategia de RSE y Sustentabilidad de Zurich Santander, alineada con nuestra estrategia Corporativa
              CARe, busca la creación de valor económico y social sustentable considerando el largo plazo con foco en:
            </p>
            <p>
              <b>1)</b> Generar soluciones de seguros y servicios basados en un dialogo cercano con nuestros clientes.{" "}
              <br />
              <b>2)</b> Generar conciencia Aseguradora en las audiencias con las que nos relacionamos. <br />
              <b>3)</b> Generar impactos materiales en las materias e iniciativas donde nos involucremos.
            </p>
            <p>
              {" "}
              Nuestro proceso de RSE esta guiado por los siguientes pilares de trabajo: Educación y toma de conciencia,
              Empleo Joven y su empoderamiento, Salud y Bienestar.
            </p>
            <p>
              {" "}
              Como parte del Grupo Zurich, contamos con el apoyo y guía de la Fundación Zurich y nuestra estrategia
              local guarda estrecha relación con las prioridades estratégicas establecidas por el grupo en nuestro
              aporte a un mundo mejor. A través de Zurich Fundation buscamos oportunidades para contribuir con
              Inversiones Sociales en proyectos y/o comunidades que tengan impacto social material y cuya razón de ser
              atienda los pilares estratégicos que hemos definido.
            </p>
            <p>
              {" "}
              Somos muy entusiastas en las posibilidades que tenemos de contribuir nuestro grano de arena para
              transformar realidades de forma positiva y sostenible.
            </p>
          </div>

          <div>
            <h6>
              Recomendaciones de Salud aportadas por el Instituto Alberto C.Taquini de Investigaciones en Medicina
              Traslacional
            </h6>
            <p>
              Nuestros clientes son lo más importante, es por ello que junto a los profesionales del Instituto Taquini,
              les brindamos recomendaciones para el cuidado de su salud, relacionadas a las siguientes temáticas:
            </p>
            <p>
              <b>1)</b> Hipertensión Arterial <br />
              <b>2)</b> Alimentación Saludable <br />
              <b>3)</b> Monóxido de Carbono
            </p>
          </div> */}
        </section>
      </Container>
      <Footer />
      <BtnDenunciarUnSiniestro />
    </div>
  );
};
