import Icon1 from "../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/primeroIcons/1.svg";
import Icon2 from "../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/primeroIcons/2.svg";
import Icon3 from "../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/primeroIcons/3.svg";
import Icon4 from "../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/primeroIcons/4.svg";
import Icon5 from "../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/primeroIcons/5.svg";
import Icon6 from "../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/primeroIcons/6.svg";
import Icon7 from "../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/primeroIcons/7.svg";
import Icon8 from "../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/primeroIcons/8.svg";
import iconCoberturaTecnologia from "../../assets/images/HogarYnegocio/IntegralDelNegocio/LocalDeShoppings/iconCoberturaTecnologia.svg";

import IconAsistencia1 from "../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/asistenciaIcons/1.svg";
import IconAsistencia3 from "../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/asistenciaIcons/3.svg";
import IconAsistencia5 from "../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/asistenciaIcons/5.png";

import IconAdicionales1 from "../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/adicionalIcons/1.svg";
import IconAdicionales2 from "../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/adicionalIcons/2.svg";

const cobertura1 = {
  icon: Icon1,
  title: "Indemnización en caso de incendio de los bienes muebles propios de la actividad",
  toltip:
    "Se cubren los daños sufridos por las cosas correspondientes a la actividad del comercio asegurado provocados por incendio: maquinarias, instalaciones, mercaderías y demás efectos correspondientes a la actividad del asegurado.",
};
const cobertura2 = {
  icon: Icon2,
  title: "Indemnización en caso de incendio del comercio",
  toltip:
    "Se cubre el daño al inmueble asegurado provocado por incendio. También te cubre remoción de escombros y gastos de alquiler en caso que el inmueble quede inoperable a causa del incendio (Incluido en Plan A).",
};
const cobertura3 = {
  icon: Icon3,
  title: "Cobertura por robo de los bienes muebles propios de la actividad",
  toltip:
    "Se cubren el robo de: maquinarias, instalaciones, mercaderías y demás efectos correspondientes a la actividad del asegurado.",
};
const cobertura4 = {
  icon: iconCoberturaTecnologia,
  title: "Cobertura para equipos electrónicos",
  toltip:
    "Se cubre el incendio, robo o accidente sufrido por: computadoras de escritorio, cajas registradoras electrónicas, centrales telefónicas, fax, fotocopiadoras, controladores fiscales, scanners, impresoras y aquellos que quieras proteger en particular.",
};
const cobertura5 = {
  icon: Icon5,
  title: "Indemnización por rotura o rajadura de vidrios",
  toltip: "Se cubren los vidrios colocados en posición vertical.",
};
const cobertura6 = {
  icon: Icon6,
  title: "Cobertura de responsabilidad civil",
  toltip:
    "Protección frente a reclamos de terceros hasta límite de suma asegurada por daños ocasionados como consecuencia del ejercicio de la actividad de tu comercio dentro de la República Argentina, desarrolladas dentro y/o fuera del/los locales especificado/s por hechos acaecidos dentro de la vigencia de la póliza.",
};
const cobertura7 = {
  icon: Icon7,
  title:
    "Indemnización por robo o destrucción de valores guardados en caja fuerte o registradora del comercio asegurado",
  toltip:
    "Cubre dinero en efectivo, cheques al portador, cupones de tarjeta de crédito y débito y demás valores especificados en póliza. Cuando el negocio cierra, cubre el dinero que quede a resguardo en caja fuerte de seguridad.",
};

const adicional1 = {
  icon: IconAdicionales1,
  title: "Indemnización por daños por roturas de cañerías causados a los bienes dentro del comercio",
  toltip:
    "Cubre filtraciones, desbordes o escapes como consecuencia de roturas u obstrucciones de cañerías (incluyendo tanques, bombas y accesorios de la instalación) que afecten bienes correspondientes a la actividad del comercio asegurado. No cubre inundación por fenómenos climáticos.",
};
const adicional2 = {
  icon: IconAdicionales2,
  title: "Cobertura ante incendio, robo o rotura accidental de notebooks",
  toltip:
    "Incluye notebooks, laptops y tablets siempre que se encuentren dentro del comercio asegurado. Para cobertura en tránsito, solicitalo a tu ejecutivo. Esto implicará un aumento en la cuota del seguro.",
};
const adicional4 = {
  icon: Icon4,
  title: "Cobertura ante incendio, robo o rotura accidental de notebooks",
  toltip:
    "Cubre el robo, destrucción por incendio o por accidente del medio de transporte (por ejemplo camión blindado) del dinero, cheques al portador y otros valores especificados en la póliza siempre que se encuentren en tránsito dentro de la República Argentina y en poder del asegurado o de sus empleados en relación de dependencia. La cobertura alcanza únicamente a los valores que tengan relación con la actividad específica o habitual, comercial o profesional del comercio asegurado.",
};
const adicional7 = {
  icon: Icon7,
  title:
    "Indemnización por robo o destrucción de valores guardados en caja fuerte o registradora del comercio asegurado",
  toltip:
    "Cubre dinero en efectivo, cheques al portador, cupones de tarjeta de crédito y débito y demás valores especificados en póliza. Cuando el negocio cierra, cubre el dinero que quede a resguardo en caja fuerte de seguridad.",
};
const adicional8 = {
  icon: Icon8,
  title: "Indemnización por robo de valores durante su transporte dentro del país",
  toltip:
    "Cubre el robo, destrucción por incendio o por accidente del medio de transporte (por ejemplo camión blindado) del dinero, cheques al portador y otros valores especificados en la póliza siempre que se encuentren en tránsito dentro de la República Argentina y en poder del asegurado o de sus empleados en relación de dependencia. La cobertura alcanza únicamente a los valores que tengan relación con la actividad específica o habitual, comercial o profesional del comercio asegurado.",
};
const asistencias = {
  title: "Asistencias",
  contenido: [
    {
      titulo: "Asesoría para tu comercio",
      contenido: [
        {
          icon: IconAsistencia1,
          title: "Consultoría Legal",
          parrafo: "Atención telefónica legal en temas civiles, penales, comerciales y laborales.",
        },
      ],
    },
    {
      titulo: "Servicios ante urgencias en tu negocio",
      contenido: [
        {
          icon: IconAsistencia5,
          title: "Asistencias ante urgencias ",
          parrafo: (
            <>
              <ul>
                <li>Destapaciones</li>
                <li>Alquiler de grupo electrógeno por falta de suministro</li>
                <li>Fumigaciones de plagas</li>
                <li>Mantenimiento de cortinas</li>
                <li>Plomería</li>
                <li>Gasista</li>
                <li>Electricidad</li>
                <li>Seguridad y vigilancia antes siniestros</li>
                <li>Traslado de muebles por inhabitalidad</li>
              </ul>
            </>
          ),
        },
        {
          icon: IconAsistencia3,
          title: "Emergencia médica empleados",
          parrafo:
            "Servicio de Urgencias para los empleados del negocio ante emergencia médica o accidente, incluyendo atención en el lugar y traslado al centro médico.",
        },
      ],
    },
  ],
};

export const consultoriosMedicos_localDeShoppings_otrosNegocios = [
  {
    title: "primero",
    contenido: [cobertura1, cobertura2, cobertura3, cobertura4, cobertura5, cobertura6],
  },
  {
    title: "adicionales",
    contenido: [adicional1, adicional7, adicional8, adicional4],
  },
  asistencias,
];

export const tiendasDeRopa = [
  {
    title: "primero",
    contenido: [cobertura1, cobertura2, cobertura3, cobertura4, cobertura5, cobertura6, cobertura7],
  },
  {
    title: "adicionales",
    contenido: [adicional1, adicional2, adicional8],
  },
  asistencias,
];

export const gastronomia = [
  {
    title: "primero",
    contenido: [cobertura1, cobertura2, cobertura3, cobertura4, cobertura5, cobertura6, cobertura7, adicional8],
  },
  {
    title: "adicionales",
    contenido: [adicional1, adicional2],
  },
  asistencias,
];

export const oficinas = [
  {
    title: "primero",
    contenido: [cobertura1, cobertura2, cobertura3, cobertura4, cobertura5, cobertura6, adicional4],
  },
  {
    title: "adicionales",
    contenido: [adicional1, adicional7, adicional8],
  },
  asistencias,
];
