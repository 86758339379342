import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export const TipsSostenibles = () => {
  return (
    <Container>
      <section className="contenedorAportesRealizados">
        <Row>
          <Col
            className="contenedorParrafoPrincipalSustentabilidad"
            style={{ marginBottom: "80px" }}
          >
            <h2>Nuestro equipo</h2>
            <p>
            <strong>Somos parte de una organización comprometida con la sociedad, con
              una cultura inclusiva y sustentable</strong>. Trabajamos profesionalmente
              en la transformación del cambio digital, ofreciéndote un modelo de
              trabajo ágil, con posibilidad de involucrarse en proyectos de gran
              variedad, transversales a toda la compañía y constantes desafíos.
            </p>

            <p>
              Contamos con un excelente clima de trabajo que te permitirá que
              vivas tu experiencia, acompañándote en tu desarrollo y bienestar
              junto a una interesante gama de beneficios pensados para vos y tu
              familia.
            </p>
            <p>
              <ul className="list-unstyled pt-4">
                <li>
                  Total colaboradores: <b style={{ color: "#003399" }}>115</b>{" "}
                </li>
                <li>
                  Mujeres: <b style={{ color: "#003399" }}>59</b>
                </li>
                <li>
                  Hombres: <b style={{ color: "#003399" }}>56</b>
                </li>
                <li>
                  Pasantes: <b style={{ color: "#003399" }}>5</b>
                </li>
                <li>
                  <b style={{ color: "#003399" }}>50% de mujeres</b> en el
                  Comité de Dirección
                </li>
                <li>
                  <b style={{ color: "#003399" }}>50% hombres</b> en el Comité
                  de Dirección
                </li>
              </ul>
            </p>

            <div className="contenedorTipsSostenibles-cards"></div>
          </Col>
        </Row>
      </section>
    </Container>
  );
};
