import React from 'react'

export const BadgeGris = () => {
  return (
    <section className='contenedorBadgeGrisVida'>
      <div>
        <p><b> Vos y tu familia están protegidos </b></p>
        <p>Con el plan de Vida Familia Protegida, estos beneficios también pueden ser usados por tu grupo familiar directo.</p>
      </div>
      <div>
        <p><b>¿Quiénes son tu grupo familiar directo?</b></p>
        <p>Cónyuge o conviviente.</p>
        <p>Hijos y/o dependientes menores de 18 años que dependan económicamente de vos y vivan en tu misma casa.</p>
      </div>
    </section>
  )
}
