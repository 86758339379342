import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../components/Global/Footer/Footer";
import Header from "../components/Global/Header/Header";
import BtnDenunciarUnSiniestro from "../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";
import iconQR from "../assets/images/aaip-isologo.png";

export const ProteccionDeDatos = () => {
  return (
    <div>
      <Header />
      <Container>
        <section className="contenedorLegales">
          <h1>Protección de datos personales</h1>

          <div>
            <p>
              Zurich Santander Todas las personas tienen derechos en relación con el modo en que se maneja su
              información personal. Durante el curso de nuestras actividades comerciales, recopilaremos, almacenaremos y
              procesaremos información personal. A continuación, explicamos cómo Zurich Santander Seguros Argentina
              S.A., en calidad de controlador de datos, recopila y maneja la información personal que sea compartida en
              esta página web.
            </p>

            <h6>¿Qué información personal recopilaremos?</h6>
            <p>
              Recopilaremos y procesaremos la información personal que nos es suministrada a través del llenado de
              formularios en nuestro sitio web por teléfono, correo electrónico, formularios completados por
              dispositivos móviles, llenado de formularios, inclusive en nuestro sitio web. También podemos recopilar
              información a partir de nuestro Agente Institorio. Podemos recopilar información personal con fines de
              verificación de capacidad económica a los fines de la suscripción, y/o proveedores de servicio de reclamos
              (por ejemplo: estudios liquidadores). Por titular de los datos personales deberá entenderse, en forma
              indistinta, a una persona humana o una persona jurídica. Toda la información requerida en este sitio es
              necesaria para que podamos establecer y administrar los contratos de seguro y prestar los servicios
              vinculados a estos contratos.
            </p>
            <p>
              Asimismo, recabamos y procesamos información con intereses legítimos, como, por ejemplo, para estar en
              condiciones de revisar y mejorar en forma continua nuestros servicios de seguros. Siempre garantizaremos
              que conservaremos la cantidad de información recopilada con fines de intereses legítimos y que limitaremos
              todo uso de la información al necesario. Este tipo de información personal que recopilamos incluye
              información personal básica (puede incluir, nombre, domicilio, dirección de correo electrónico, fecha y
              lugar de nacimiento, sexo, estado civil, nacionalidad, país de residencia), datos financieros y de empleo;
              y cuando recibimos un pedido para incluir a otras personas humanas en el arreglo, la información personal
              de dichas personas humanas. Además, podemos recopilar información sensible, como por ejemplo información
              médica o sanitaria para poder analizar en forma correcta y precisa la denuncia de ocurrencia de un
              siniestro, incluyendo los detalles de cualquier incidente que dé origen a un reclamo, así como información
              financiera relevante para el siniestro. Si nos proporcionaran información personal sobre otros titulares
              de datos personales a fin de proporcionar una cotización y/o un contrato de seguro y/o la prestación de
              servicios relacionados, se entiende que al hacerlo se han recibido previamente todos los permisos y
              consentimientos necesarios y, si fuera necesario, que todas las personas afectadas han sido informadas
              acerca del contenido de nuestra Política de Privacidad y de Protección de Datos Personales.
            </p>

            <p className="fw-bold">
              Zurich Santander Seguros Argentina S.A. protege la privacidad de cada persona de la siguiente forma:{" "}
            </p>

            <ul>
              <p>
                • Recopilando información en forma razonable y sólo recopilando información que necesitamos para prestar
                servicios de seguro. <br /> • Explicando por qué recabamos información personal y cómo la usaremos.{" "}
                <br />• Garantizando que la información personal que recopilamos y conservamos es correcta.
                <br /> • Conservando la información personal sólo durante el tiempo necesario y protegiéndola.
                <br />• Compartiendo la información personal sólo con compañías y organizaciones que la protegerán.{" "}
                <br />• No enviando la información personal al extranjero sin garantizar su seguridad. <br />•
                Garantizando que todos los derechos individuales puedan ser ejercidos en el marco de la legislación de
                Protección de Datos.
              </p>
            </ul>
          </div>

          <div>
            <h6>¿Cómo utilizamos la información personal?</h6>
            <p>
              Recabaremos y utilizaremos la información personal de la siguiente manera (i) cuando el procesamiento sea
              necesario respecto de la provisión de una cotización y/o de un contrato de seguro y/o de la prestación de
              servicios relacionados que han sido solicitados; y (ii) para dar cumplimiento a nuestras obligaciones
              legales y regulatorias.
            </p>
          </div>

          <div>
            <h6>¿Con quién compartimos la información personal?</h6>
            <p>
              Cuando sea necesario, compartiremos la información personal que nos es provista a efectos de proporcionar
              productos de seguro y de prestar los servicios solicitados con los tipos de organizaciones (“Receptores”)
              descriptos a continuación:
              <ul>
                • El Agente Institorio como intermediario involucrado. Zurich Insurance Group Ltd., y/o cualquiera de
                sus compañías afiliadas y/o con otras compañías pertenecientes al Grupo Zurich en Argentina. <br /> •
                Las reaseguradoras, los proveedores y prestadores de servicios. <br />• Las organizaciones dedicadas a
                encuestas e investigaciones. <br />• Los profesionales de la salud y organizaciones de asistencia
                médica.
              </ul>
              O también, con el objeto de dar cumplimiento a nuestros requerimientos legales o regulatorios, con los
              tipos de organizaciones descriptas a continuación:
              <ul>
                • Entidades regulatorias y jurídicas.
                <br /> • Autoridades gubernamentales o tributarias.
                <br /> • Organismos responsables de la aplicación de la ley, incluidos los investigadores.
              </ul>
            </p>
            <p>
              La información personal provista sólo estará disponible para las personas con una necesidad legítima de
              consultarla. Por ejemplo, sólo las personas involucradas en la gestión de siniestros podrán acceder a la
              información sensible recopilada y únicamente con dicho propósito. Se solicitará el consentimiento escrito
              antes de compartir los informes médicos u otros documentos de suscripción con cualquier otra aseguradora o
              intermediario de seguros.
            </p>
          </div>

          <div>
            <h6>¿Cómo transferimos la información personal a otros países? </h6>
            <p>
              En caso de resultar necesario, ya sea porque Zurich Santander Seguros Argentina S.A. pertenece a un grupo
              mundial, o por la naturaleza del negocio, podemos transferir información personal a otros países. Cuando
              transferimos información personal a países fuera de la República Argentina, nos aseguramos de que la misma
              esté protegida, que la transferencia sea lícita y realizada de acuerdo con la normativa aplicable. Lo
              haremos garantizando que exista una decisión de suficiencia relativa a las medidas de seguridad para la
              información personal de la Agencia de Acceso a la Información Pública, la Comisión Europea, o que la
              información personal esté protegida en forma adecuada mediante el uso de “cláusulas contractuales
              estándar” que hayan sido adoptadas o aprobadas por la República Argentina, u otras soluciones que cumplan
              con los requerimientos de las leyes argentinas y en su caso europeas de protección de datos. Los pedidos
              de copias del modelo utilizado para las “cláusulas contractuales estándar” se pueden realizar poniéndose
              en contacto con nuestro <strong>Responsable de Protección de Datos</strong>.
            </p>
          </div>

          <div>
            <h6>¿Cuánto tiempo conservamos los datos personales?</h6>
            <p>
              Conservaremos y procesaremos información personal durante el tiempo que sea necesario para alcanzar los
              objetivos para los cuales la información se recopiló originalmente. Estos períodos están sujetos a
              requerimientos legales, impositivos y regulatorios, o durante el tiempo necesario que nos permita
              gestionar nuestro negocio.
            </p>
          </div>

          <div>
            <h6>¿Qué ocurre si usted no nos proporciona información personal?</h6>
            <p>
              Si la información personal no nos fuera provista, no podremos proporcionar un contrato de seguro, una
              cobertura de póliza, ni evaluar los reclamos futuros para los servicios que han sido solicitados, por lo
              tanto, será obligatoria para poder brindar los servicios mencionados y se considera que los datos
              proporcionados son exactos y veraces.
            </p>
          </div>

          <div>
            <h6>¿Qué derechos a la protección de datos tienen los titulares de los datos?</h6>
            <p>
              Todos los titulares de los datos tienen los siguientes derechos conforme a las leyes de protección de
              datos:
              <ul>
                • Derecho de acceso a sus datos personales (mediante una solicitud de acceso del titular de los datos).{" "}
                <br /> • Derecho a disponer que se modifiquen los datos personales si son inexactos o incompletos.{" "}
                <br /> • En ciertas circunstancias, el derecho a disponer la eliminación de los datos personales. <br />{" "}
                • En ciertas circunstancias, el derecho a restringir el procesamiento de datos personales. <br /> •
                Derecho de portabilidad de datos, es decir, de obtener y reutilizar los datos personales para fines
                relacionados en distintos servicios. <br /> • Derecho a oponerse al procesamiento de datos personales.{" "}
                <br /> • Derecho a no estar sujeto a la toma de decisiones automatizadas (incluyendo la definición de
                perfiles) cuando genere un efecto legal o un efecto similarmente significativo sobre una persona humana.{" "}
                <br /> • Derecho a reclamar compensación por daños y perjuicios a raíz del incumplimiento de la
                legislación de protección de datos.
              </ul>
              Si estamos procesando información personal con consentimiento, el consentimiento se puede retirar en
              cualquier momento (el retiro del consentimiento no afectará la legalidad del procesamiento basado en un
              consentimiento previo al retiro). Estos derechos pueden ser ejercidos poniéndose en contacto con nuestro
              responsable de Protección de Datos.
            </p>
            <p>
              Con el fin de proteger la privacidad, se solicitará a los titulares de los datos que presenten constancias
              de identificación adecuadas antes de que podamos proceder a procesar solicitudes relacionadas con
              derechos.
            </p>
          </div>

          <div>
            <h6>Contacto de Protección de Datos </h6>
            <p>
              Todas las preguntas acerca del uso de información personal se deben efectuar a nuestro responsable de
              Protección de Datos: Emiliano Lucas Jramoy, presentando la solicitud respectiva en Ing. Enrique Butty 240,
              9.° Piso - CABA (C1001AFB), Argentina o con el envío de la solicitud por correo electrónico a la dirección
              datospersonales@zurich- santander.com.ar. En la solicitud deberá indicar: (i) nombre completo y, en su
              caso, documento que acredite la representación legal del titular; (ii) adjuntar copia simple de su
              documento nacional de identidad; (iii) indicar su correo electrónico y dirección postal que designe para
              notificaciones; (iv) indicar algún número telefónico de contacto; y (v) incluir una descripción clara y
              precisa de los datos personales respecto de los que busca ejercer el derecho de acceso, rectificación y
              cancelación y documentos que sustenten el cambio. Una vez cumplido con los recaudos antes detallados, y
              siempre que correspondiera hacer lugar al pedido, Zurich Santander Seguros Argentina S.A. le comunicará
              por el mismo medio si ha procedido a dar lugar a la misma o si rechaza el pedido. Zurich Santander Seguros
              Argentina S.A. cuenta con 10 (diez) días hábiles desde la recepción de la solicitud para enviar una
              respuesta en caso de tratarse de una solicitud de acceso, y 5 (cinco) días hábiles si se solicitara la
              rectificación, actualización o supresión de información personal. Asimismo, las inquietudes relacionadas
              con el procesamiento de información personal o con la insatisfacción con nuestro manejo de una solicitud
              relativa a los derechos de protección de datos pueden elevarse efectuando un reclamo a la Agencia de
              Acceso a la Información Pública. En este sentido, LA AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su
              carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos
              que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes de
              protección de datos personales.
            </p>
          </div>

          <div>
            <img src={iconQR} alt="AAIP" className="img-fliud" />
          </div>
        </section>
      </Container>
      <Footer />
      <BtnDenunciarUnSiniestro />
    </div>
  );
};
