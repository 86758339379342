import React from "react";
import Slider from "react-slick";
import { Container } from "react-bootstrap";

import starsReviews from "../../../assets/images/starsReviews.svg";
import CardReviews from "./CardReviews";

import fondoReviews from "../../../assets/images/fondoReviews.svg";
import { useEffect } from "react";
import { getTestimonials } from "../../../services/testimonalServices";
import { useState } from "react";
import Loading from "../Loading/Loading";
import { useLocation } from "react-router-dom";

const Reviews = () => {
  let location = useLocation();

  const [newPath, setNewPath] = useState(location.pathname);

  useEffect(() => {
    if (location.pathname === "/") {
      setNewPath("home");
    } else {
      setNewPath(location.pathname);
    }
  }, [location]);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);

  const handleCallApi = async () => {
    setLoading(true);

    await getTestimonials()
      .then((res) => {
        setTestimonials(res.data);
      })
      .catch((err) => {
        setError(err);
      });

    setLoading(false);
  };

  useEffect(() => {
    handleCallApi();
  }, []);

  return (
    <Container fluid="true" id="container-reviews">
      <Container className="text-center">
        <div className="container-reviews-header">
          <div className="container-reviews-title">
            <h3 className="subtitulo-gris">¡Estás en buenas manos!</h3>
            <h4>Así nos califican nuestros clientes</h4>
          </div>

          <div className="container-reviews-body d-flex justify-content-md-center align-content-center align-items-center flex-md-row flex-column">
            <div className="container-reviews-body-numbers">
              <p>4.5/5</p>
            </div>
            <div className="container-reviews-body-stars">
              <img src={starsReviews} alt="Stars Reviews" />
              <p>Calificación del cliente</p>
              <span>Fuente: Encuesta Siniestros pagos 2023</span>
            </div>
          </div>
        </div>

        {testimonials && testimonials.length > 0 && (
          <div className="container-reviews-slider">
            {loading ? (
              <Loading className="mt-5 mb-5" />
            ) : (
              <Slider {...settings}>
                {testimonials
                  // .filter((t) => t.attributes.Mostrar_En.data)

                  .filter((t) => t.attributes.Mostrar_En.data.some((cat) => cat.attributes.Nombre === newPath))
                  .map((t, index) => (
                    <CardReviews
                      key={index}
                      title={t.attributes.NombrePersona}
                      subtitle={t.attributes.Servicio}
                      image={`${t.attributes.FotoTestimonio.data.attributes.url}`}
                      description={t.attributes.Testimonio}
                    />
                  ))}
              </Slider>
            )}
          </div>
        )}
      </Container>
      <img src={fondoReviews} alt="Rectangulo" className="imagenBackgroundReview" />
    </Container>
  );
};

export default Reviews;
