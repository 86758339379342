import { getRequest } from "./apiService";

//GET FAQS
export const getAllFaqs = async ({ subcat }) => {
  const url = `/faqs?populate=*&filters[Subcategoria][Nombre][$eq]=${subcat}`;

  // const url = `/faqs?populate=*&page=1&pageSize=100&filters[$and][0][Subcategoria][Nombre][$eq]=${subcat}`;
  const config = {
    headers: {
      Authorization: process.env.REACT_APP_API_TOKEN,
    },
  };

  const response = await getRequest(url, config);
  return response.data;
};

export const getSingleFaq = async (id) => {
  const url = `/faqs/${id}?populate=*`;
  const config = {
    headers: {
      Authorization: process.env.REACT_APP_API_TOKEN,
    },
  };

  const response = await getRequest(url, config);
  return response.data;
};

//GET FAQ CATEGORIES
export const getCategoriesFaq = async () => {
  const url = `/faq-categorias?populate=*`;
  const config = {
    headers: {
      Authorization: process.env.REACT_APP_API_TOKEN,
    },
  };

  const response = await getRequest(url, config);
  return response.data;
};

//GET FAQ SUBCATEGORIES
export const getSubcategoriesFaq = async () => {
  const url = `/faq-subcategorias?populate=*`;
  const config = {
    headers: {
      Authorization: process.env.REACT_APP_API_TOKEN,
    },
  };

  const response = await getRequest(url, config);
  return response.data;
};
