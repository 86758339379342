import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ComprasProtegidas } from "../components/ComprasYgastos/pages/ComprasProtegidas";
import { ExtraccionesProtegidas } from "../components/ComprasYgastos/pages/ExtraccionesProtegidas";
import { ProteccionCarteras } from "../components/ComprasYgastos/pages/ProteccionCarteras";
import { GastosProtegidos } from "../components/ComprasYgastos/pages/GastosProtegidos";
import { IntegralDelNegocio } from "../components/HogarYnegocio/pages/IntegralDelNegocio";
import { Vivienda } from "../components/HogarYnegocio/pages/Vivienda";
import { ContainerItem } from "../components/PreguntasFrecuentes/ContainerItem";
import { ArticulosDeportivos } from "../components/TecnologiaProtegida/Pages/ArticulosDeportivos";
import { Audio } from "../components/TecnologiaProtegida/Pages/Audio";
import { Fotografia } from "../components/TecnologiaProtegida/Pages/Fotografia";
import { Gaming } from "../components/TecnologiaProtegida/Pages/Gaming";
import { InstrumentosMusicales } from "../components/TecnologiaProtegida/Pages/InstrumentosMusicales";
import { Notebooks } from "../components/TecnologiaProtegida/Pages/Notebooks";
import { ProteccionMovil } from "../components/TecnologiaProtegida/Pages/ProteccionMovil";
import { Tablets } from "../components/TecnologiaProtegida/Pages/Tablets";
import { ComprasYgastos } from "../pages/comprasYgastos";
import { Contacto } from "../pages/contacto";
import { ContratosDeAdhesion } from "../pages/contratosDeAdhesion";
import { Escribenos } from "../pages/escribenos";
import { gobiernoCorporativoySustentabilidad } from "../pages/gobiernoCorporativoySustentabilidad";
import { HogarYnegocio } from "../pages/hogarYnegocio";
import Home from "../pages/home";
import { Nosotros } from "../pages/nosotros";
import { Novedades } from "../pages/novedades";
import { NovedadesPost } from "../pages/novedadesPost";
import { PoliticasDePrivacidad } from "../pages/politicasDePrivacidad";
import { PreguntasFrecuentes } from "../pages/preguntasFrecuentes";
import { SeguroDeBici } from "../pages/seguroDeBici";
import { servicioDeAtencionAlAsegurado } from "../pages/servicioDeAtencionAlAsegurado";
import { Sostenibilidad } from "../pages/sostenibilidad";
import { TecnologiaProtegida } from "../pages/tecnologiaProtegida";
import { TuveUnSiniestro } from "../pages/tuveUnSiniestro";
import { VidaYsalud } from "../pages/vidaYsalud";
import { AccidentesPersonales } from "../components/VidaYsalud/pages/AccidentesPersonales";
import { Vida } from "../components/VidaYsalud/pages/Vida";
import OtrosNegocios from "../components/HogarYnegocio/pages/IntegralDelNegocio/OtrosNegocios";
import ConsultoriosMedicos from "../components/HogarYnegocio/pages/IntegralDelNegocio/ConsultoriosMedicos";
import LocalDeShoppings from "../components/HogarYnegocio/pages/IntegralDelNegocio/LocalDeShoppings";
import Oficinas from "../components/HogarYnegocio/pages/IntegralDelNegocio/Oficinas";
import TiendasDeRopa from "../components/HogarYnegocio/pages/IntegralDelNegocio/TiendasDeRopa";
import Gastronomia from "../components/HogarYnegocio/pages/IntegralDelNegocio/Gastronomia";
import ScrollToTop from "../hooks/ScrollToTop";
import { ProteccionDeDatos } from "../pages/proteccionDeDatos";
import { PrevencionDeLavado } from "../pages/prevencionDeLavado";

function RoutesBeforeLocation() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/nosotros" component={Nosotros} />
      <Route exact path="/contacto" component={Contacto} />
      <Route exact path="/escribenos" component={Escribenos} />
      <Route exact path="/sostenibilidad" component={Sostenibilidad} />
      <Route exact path="/politicas-de-privacidad" component={PoliticasDePrivacidad} />
      <Route exact path="/proteccion-de-datos" component={ProteccionDeDatos} />
      <Route exact path="/prevencion-de-lavado-de-activos" component={PrevencionDeLavado} />
      <Route exact path="/servicio-de-atencion-al-asegurado" component={servicioDeAtencionAlAsegurado} />
      <Route exact path="/gobierno-corporativo-y-sustentabilidad" component={gobiernoCorporativoySustentabilidad} />
      <Route exact path="/contratos-de-adhesion" component={ContratosDeAdhesion} />
      <Route exact path="/novedades" component={Novedades} />
      <Route exact path="/novedades/:post" component={NovedadesPost} />
      <Route exact path="/preguntas-frecuentes" component={PreguntasFrecuentes} />
      <Route exact path="/preguntas-frecuentes/:cat/:subcat" component={ContainerItem} />
      <Route exact path="/tuve-un-siniestro" component={TuveUnSiniestro} />
      <Route exact path="/tecnologia-protegida" component={TecnologiaProtegida} />
      <Route exact path="/tecnologia-protegida/proteccion-movil" component={ProteccionMovil} />
      <Route exact path="/tecnologia-protegida/instrumentos-musicales" component={InstrumentosMusicales} />
      <Route exact path="/tecnologia-protegida/articulos-deportivos" component={ArticulosDeportivos} />
      <Route exact path="/tecnologia-protegida/fotografia" component={Fotografia} />
      <Route exact path="/tecnologia-protegida/notebooks" component={Notebooks} />
      <Route exact path="/tecnologia-protegida/tablets" component={Tablets} />
      <Route exact path="/tecnologia-protegida/gaming" component={Gaming} />
      <Route exact path="/tecnologia-protegida/audio" component={Audio} />
      <Route exact path="/seguro-de-bici" component={SeguroDeBici} />
      <Route exact path="/hogar-y-negocio" component={HogarYnegocio} />
      <Route exact path="/hogar-y-negocio/vivienda" component={Vivienda} />
      <Route exact path="/hogar-y-negocio/negocio-protegido" component={IntegralDelNegocio} />
      <Route exact path="/hogar-y-negocio/negocio-protegido/gastronomia" component={Gastronomia} />
      <Route exact path="/hogar-y-negocio/negocio-protegido/tiendas-de-ropa" component={TiendasDeRopa} />
      <Route exact path="/hogar-y-negocio/negocio-protegido/oficinas" component={Oficinas} />
      <Route exact path="/hogar-y-negocio/negocio-protegido/local-de-shoppings" component={LocalDeShoppings} />
      <Route exact path="/hogar-y-negocio/negocio-protegido/consultorios-medicos" component={ConsultoriosMedicos} />
      <Route exact path="/hogar-y-negocio/negocio-protegido/otros-negocios" component={OtrosNegocios} />
      <Route exact path="/compras-y-gastos" component={ComprasYgastos} />
      <Route exact path="/compras-y-gastos/compra-protegida" component={ComprasProtegidas} />
      <Route exact path="/compras-y-gastos/extracciones-protegidas" component={ExtraccionesProtegidas} />
      <Route exact path="/compras-y-gastos/gastos-protegidos" component={GastosProtegidos} />
      <Route exact path="/compras-y-gastos/proteccion-carteras" component={ProteccionCarteras} />
      <Route exact path="/vida-y-salud" component={VidaYsalud} />
      <Route exact path="/vida-y-salud/vida" component={Vida} />
      <Route exact path="/vida-y-salud/accidentes-personales" component={AccidentesPersonales} />
      <Route exact path="*" component={Home} />
    </Switch>
  );
}

export default function Routes() {
  return (
    <Router basename="/zurich">
      <ScrollToTop />
      <RoutesBeforeLocation />
    </Router>
  );
}
