import React from "react";

import Icon1 from "../../../../assets/images/HogarYnegocio/Vivienda/AsistenciaMascotas/1.svg";
import Icon2 from "../../../../assets/images/HogarYnegocio/Vivienda/AsistenciaMascotas/2.svg";
import Icon3 from "../../../../assets/images/HogarYnegocio/Vivienda/AsistenciaMascotas/3.svg";
import Icon4 from "../../../../assets/images/HogarYnegocio/Vivienda/AsistenciaMascotas/4.svg";
import Icon5 from "../../../../assets/images/HogarYnegocio/Vivienda/AsistenciaMascotas/5.svg";
import Icon6 from "../../../../assets/images/HogarYnegocio/Vivienda/AsistenciaMascotas/6.svg";
import Icon7 from "../../../../assets/images/HogarYnegocio/Vivienda/AsistenciaMascotas/7.svg";
import Btn4 from "../../../../assets/images/VidaYsalud/Vida/Asistencias/segunda/1.svg";
import igs from "../../../../assets/images/Logo-IGS.png";

export const AsistenciaMascotas = () => {
  return (
    <div className="contenedorPersonalizadoAsistenciaMascotas">
      <section className="contenedorAsistenciaMascotas">
        <div className="contenedorAsistenciaMascotas-primeraColumna">
          <h5>
            {" "}
            <b>Asistencia</b> <br />
            para las mascotas
          </h5>
          <p>
            Actualizamos los montos de las asistencias para que vos y tu mejor amigo sigan disfrutando de todos los
            beneficios.
          </p>
        </div>
        <div className="contenedorAsistenciaMascotas-segundaColumna">
          <div className="contenedorAsistenciaMascotas-segundaColumna-card">
            <img src={Icon1} alt="Icon" />
            <h6>Vacunación</h6>
          </div>

          <div className="contenedorAsistenciaMascotas-segundaColumna-card">
            <img src={Icon2} alt="Icon" />
            <h6>Intervención Quirúrgica e Internación</h6>
          </div>

          <div className="contenedorAsistenciaMascotas-segundaColumna-card">
            <img src={Icon3} alt="Icon" />
            <h6>Consultas Veterinarias 24/7</h6>
          </div>

          <div className="contenedorAsistenciaMascotas-segundaColumna-card">
            <img src={Icon4} alt="Icon" />
            <h6>Urgencias Veterinarias</h6>
          </div>

          <div className="contenedorAsistenciaMascotas-segundaColumna-card">
            <img src={Icon5} alt="Icon" />
            <h6>Baño para tu mascota</h6>
          </div>

          <div className="contenedorAsistenciaMascotas-segundaColumna-card">
            <img src={Icon6} alt="Icon" />
            <h6>Chequeo General</h6>
          </div>

          <div className="contenedorAsistenciaMascotas-segundaColumna-card">
            <img src={Icon7} alt="Icon" />
            <h6>Descuento en alimentos y accesorios</h6>
          </div>

          {/* <div className='contenedorAsistenciaMascotas-segundaColumna-card'>
            <img src={Icon8} alt="Icon" />
            <h6 className='title-app-blue'>App de Iké</h6>
          </div> */}
        </div>
      </section>
      <section className="contenedorBagdeAsistencia">
        <div className="d-flex justify-content-startflex-row align-content-start align-items-start p-4 ">
          <div className="pe-4">
            <img src={Btn4} alt="Icon" />
          </div>
          <div className="pe-4">
            <img src={igs} alt="Icon" style={{ maxWidth: "100px" }} />
          </div>
          <p className="text-start">
            Podés contactarte con el <br /> servicio de asistencia al {"  "}
            <a href="tel:08001220894">0800-444-0313 .</a>{" "}
          </p>
        </div>
      </section>
    </div>
  );
};
