import React from 'react'
import { Container } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import iconPositivo from '../../../assets/images/Global/iconPositivo.svg'
import IconInformationCircle from '../../../assets/images/Global/IconInformationCircle.svg'
import iconCerrar from '../../../assets/images/Global/iconCerrar.svg'

export const Tablas = ({tituloNegrita='',tituloNormal='',TitulosColumnas1='', TitulosColumnas2='',arrayContenido,mostrarHeader=true}) => {
  return (
    <Container>
        <section className='cotenedorTablaGlobal'>

            {
                mostrarHeader && 
                <div className='cotenedorTablaGlobal-header'>
                    <div className='cotenedorTablaGlobal-header-titulo'>
                        <h5> <b>{tituloNegrita}</b>  {tituloNormal} </h5>
                    </div>
                    <div className='cotenedorTablaGlobal-header-primercuadro'>
                        <h5> {TitulosColumnas1} </h5>
                    </div>
                    <div className='cotenedorTablaGlobal-header-segundocuadro'>
                        <h5> {TitulosColumnas2} </h5>
                    </div>
                </div>
            }

            <div className='cotenedorTablaGlobal-body'>
                {
                    arrayContenido.map((item, index) => {

                        return (
                            <div className='cotenedorTablaGlobal-body-items' key={index}>
                                <div className='cotenedorTablaGlobal-body-1'>
                                    <img src={item.icono} alt="Icon" />
                                    <h5> {item.titulo} </h5>
                                    
                                    <OverlayTrigger
                                        key="right"
                                        placement="right"
                                        overlay={
                                            <Tooltip id='tooltip-right'>
                                                {item.toltip}
                                            </Tooltip>
                                        }
                                        >
                                        <img src={IconInformationCircle} alt="Icon" />
                                    </OverlayTrigger>
                                    {/* Toltip falta aca */}
                                </div>
                                <div className='cotenedorTablaGlobal-body-2'>
                                    {item.mostrar1 ? <img src={iconPositivo} alt="Icon" /> : <img src={iconCerrar} alt="Icon" />}
                                </div>
                                <div className='cotenedorTablaGlobal-body-3'>
                                    {item.mostrar2 ? <img src={iconPositivo} alt="Icon" /> : <img src={iconCerrar} alt="Icon" />}
                                </div>
                            </div>
                        )
                    })
                }
                

            </div>

        </section>
    </Container>
  )
}
