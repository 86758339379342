import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../components/Global/Footer/Footer";
import Header from "../components//Global/Header/Header";
import BtnDenunciarUnSiniestro from "../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";

export const PoliticasDePrivacidad = () => {
  return (
    <div>
      <Header />
      <Container>
        <section className="contenedorLegales">
          <h1>Políticas de Privacidad</h1>

          <div>
            <h6>Privacidad y legales</h6>
            <p>
              Desde el momento que usted accede a nuestra página WEB queda sujeto a los términos y condiciones que se
              describen a continuación:
            </p>
            <p>
              El acceso le atribuye la condición de usuario, aceptando automáticamente, en forma plena y sin reserva
              alguna todas las condiciones generales de uso de todos los servicios, productos y contenidos que contiene
              la presente página web. Los términos y condiciones aquí descritos pueden ser modificados o actualizados en
              cualquier momento y sin previo aviso. Cualquier modificación / actualización regirán desde su publicación
              en este sitio.
            </p>
          </div>

          <div>
            <h6>Acuerdo de Uso</h6>
            <p>
              Zurich Santander Seguros Argentina S.A. con domicilio legal en Ing. Enrique Butty 240, 9.° Piso - CABA
              (C1001AFB) son titulares del sitio web www.zssa.com.ar. En este sentido, Zurich Santander Seguros
              Argentina S.A. ha realizado su mejor esfuerzo a efectos de brindar a los usuarios la más completa y exacta
              información. No obstante, se pone en conocimiento de los usuarios que el material y la información
              contenidos en este sitio son provistos en el estado en que se encuentra y su veracidad o exactitud no se
              encuentra garantizada ni expresa ni implícitamente, por lo que Zurich Santander Seguros Argentina S.A.
              declina toda responsabilidad a su respecto. Dicha información no se encuentra libre de imprecisiones
              técnicas, errores tipográficos, referencias a productos o servicios discontinuados o no disponibles en el
              país de acceso u otros errores. La información y datos incluidos en esta página web puede ser susceptible
              de modificación sin previo aviso.
            </p>
            <p>
              Es responsabilidad del usuario la evaluación de la utilidad, exactitud o integralidad de la información
              presentada. La información contenida en este sitio no debe ni puede ser interpretada como asesoramiento
              legal, fiscal o profesional de ningún tipo ni podrá entenderse que dicha información implica la adopción
              de posiciones u opiniones respecto de ningún tema. En consecuencia, Zurich Santander Seguros Argentina
              S.A. no asume responsabilidad alguna al respecto. A los fines de la obtención de opiniones y/o
              asesoramiento el usuario debe consultar a profesionales de su confianza respecto de su situación
              particular.
            </p>
          </div>

          <div>
            <h6>Propiedad intelectual</h6>
            <p>
              Salvo indicación expresa en contrario, la información, las imágenes gráficas, el diseño, los isotipos,
              isologotipos o logotipos, las marcas registradas y el texto así como los productos ofrecidos incluidos en
              este sitio son de propiedad exclusiva de Zurich Santander Seguros Argentina S.A. o ha sido utilizada con
              la autorización de su titular. Nada de lo contenido en este sitio deberá interpretarse como concesión
              implícita o de cualquier manera, de la licencia o derecho de utilizar alguna marca registrada exhibida en
              esta página. En consecuencia, no pueden ser modificados, copiados, reproducidos o distribuidos,
              transmitidos, divulgados, licenciados, cedidos total o parcialmente, ni se encuentra autorizada la
              creación de trabajos derivados del uso de la información, el software, los productos o servicios incluidos
              en el sitio sin el previo consentimiento por escrito de Zurich Santander Seguros Argentina S.A.
            </p>
          </div>

          <div>
            <h6>Enlaces a otras páginas</h6>
            <p>
              Zurich Santander Seguros Argentina S.A. no asume responsabilidad alguna respecto de errores, fallas o
              imposibilidad de acceso a otros sitios o páginas en razón de hipervínculos u otras funciones de enlace
              incluidos en el sitio. Los sitios individualizados o conectados por hipervínculos u otras funciones de
              enlace se encuentran bajo la responsabilidad de terceros ajenos a Zurich Santander Seguros Argentina S.A.
              La creación de hipervínculos u otras funciones de enlace no implica la aprobación de su contenido ni la
              existencia de relación jurídica alguna entre Zurich Santander Seguros Argentina S.A. y los operadores de
              las páginas o sitios a las que el usuario accede por dicho medio. En consecuencia, Zurich Santander
              Seguros Argentina S.A. no puede ni debe monitorear, auditar o de otra forma controlar la información
              incluida en sitios o páginas de terceros por lo que no asume responsabilidad alguna a su respecto. Zurich
              Santander Seguros Argentina S.A. considera que la generación de hipervínculos es legal y se encuentra
              dentro de las expectativas propias y usuales de quienes acceden a la red global Internet siendo
              facilitados exclusivamente para comodidad del usuario. Por lo que, el acceso a los vínculos de cualquier
              otro sitio o página quedará bajo la exclusiva responsabilidad del usuario y Zurich Santander Seguros
              Argentina S.A. no asume responsabilidad alguna por los daños que pueda causar dicha vinculación. No
              obstante ello, Zurich Santander Seguros Argentina S.A. no autoriza sin su previo consentimiento por
              escrito la generación o creación de hipervínculos a este sitio.
            </p>
          </div>

          <div>
            <h6>Calidad del software</h6>
            <p>
              Zurich Santander Seguros Argentina S.A. garantiza que la información, el software o el material al que el
              usuario pudiere acceder a través de las páginas del sitio se encuentre libres de virus de cualquier
              naturaleza, incluyendo pero no limitándose a los virus conocidos como "gusanos", "Troyanos" "Hoax" u otros
              elementos que pudieren causar daños potenciales o reales a los usuarios o a su propiedad. En consecuencia
              Zurich Santander Seguros Argentina S.A. no se responsabiliza por ningún daño directo, indirecto,
              concausado u otro que pudiere resultar del uso, descarga, y/o consulta de información, datos, texto
              imágenes u otro material incluido en el sitio, sus páginas o las páginas o sitios conectados o
              relacionados por hipervínculos u otras funciones de enlace.
            </p>
          </div>

          <div>
            <h6>Uso de “Cookies”</h6>
            <p>
              En ocasión de hacer uso o acceder al sitio o a cualquiera de sus páginas el usuario asume la
              responsabilidad por toda transmisión ilícita de información o contraria a la moral y las buenas costumbres
              o de carácter injurioso, ofensivo, difamatorio, obsceno, pornográfico, o de otra forma objetable,
              incluyendo pero sin limitarse a transmisión de mensajes o información que en sí mismos constituyan o
              alienten conductas contrarias a la legislación vigente nacional o internacional o den lugar a acciones por
              responsabilidad civil. Asimismo, el usuario se obliga a no enviar, transmitir documentos o software que
              contengan virus de cualquier naturaleza, incluyendo pero no limitándose a los virus conocidos como
              "gusanos", "Troyanos" "Hoax" u otros elementos que pudieren causar daños al sitio o a sus usuarios y/o de
              alguna forma inhibir o restringir el uso del sitio por terceros. Salvo pacto expreso por escrito en
              contrario, el usuario no se encuentra autorizado a hacer uso de la información o el software contenidos en
              el sitio con fines comerciales.
            </p>
          </div>

          <div>
            <h6>Jurisdicción y legislación aplicable</h6>
            <p>
              El usuario acepta que todo conflicto de intereses o diferencia que pueda surgir como consecuencia del uso
              del sitio será resuelto de acuerdo con la legislación vigente en la República Argentina sometiéndose a la
              jurisdicción y competencia de los Tribunales Ordinarios de la Ciudad de Buenos Aires (República Argentina)
              renunciando expresamente a todo otro fuero o jurisdicción que pudiere corresponder.
            </p>
            <p>
              Valoramos su opinión sobre nuestros productos y servicios. Estamos a su disposición para resolver
              cualquier inconveniente referido a su póliza o la denuncia de siniestros. Por favor, comuníquese al
              0810-333-2400 o a los canales de Atención al Asegurado.
            </p>
            <p>
              Zurich Santander Seguros Argentina S.A. CUIT 30-69896545-9 S.A Ing. Enrique Butty 240, 9.° Piso - CABA
              (C1001AFB).
            </p>
          </div>
        </section>
      </Container>
      <Footer />
      <BtnDenunciarUnSiniestro />
    </div>
  );
};
