import React from 'react'

export const CardPaises = ({logo,titulo,parrafo}) => {
  return (
    <div className='contenedorCardPaises'>
        <div className='contenedorCardPaises-title'>
            <img src={logo} alt="Icon" />
            <h6>{titulo}</h6>
        </div>
        <div className='contenedorCardPaises-body'>
            <p> {parrafo} </p>
        </div>
    </div>
  )
}
