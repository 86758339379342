import React, { useState } from "react";
import BtnDenunciarUnSiniestro from "../../Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";
import Footer from "../../Global/Footer/Footer";
import Header from "../../Global/Header/Header";
import { Portada } from "../../TecnologiaProtegida/PageItem/Portada";
import { StoreMobile } from "../../TecnologiaProtegida/PageItem/StoreMobile";
import { BannerAzul } from "../../TecnologiaProtegida/PageItem/BannerAzul";
import CardsServicios from "../../Home/CardsServicios/CardsServicios";
import { Breadcrumbs } from "../../Global/Breadcrumbs/Breadcrumbs";
import { Contenido } from "../PageItem/Contenido";
import portadaEstatica from "../../../assets/images/TecnologiaProtegida/Portadas/PortadaGaming.svg";
import primerIcon from "../../../assets/images/TecnologiaProtegida/iconPages/gaming/primero.svg";
import segundoIcon from "../../../assets/images/TecnologiaProtegida/iconPages/gaming/segundo.svg";
import BtnAction from "../../Global/BtnMasInformacion/BtnAction";
import { ModalContratarTecno } from "../../ModalContratarTecno/ModalContratarTecno";
import LegalesSeguros from "../../LegalesSeguros/LegalesSeguros";
import { Col, Container, Row } from "react-bootstrap";
import InfoSeguros from "../../InfoSeguros";

export const Gaming = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModal = (v) => {
    setShowModal(v);
  };

  const ArrayItems = [
    {
      icon: segundoIcon,
      title: "Cobertura por robo y/o hurto",
      parrafo: "Protección ante robo o hurto(1) del objeto hasta la suma asegurada contratada.",
    },
    {
      icon: primerIcon,
      title: "Cobertura por daño",
      parrafo:
        "Protección ante los daños materiales(2) totales o parciales sufridos por el objeto como consecuencia de un accidente hasta la suma asegurada contratada.",
    },
  ];

  return (
    <>
      <Header />
      <Portada portada={portadaEstatica} titulo="Consolas de videojuegos" subtitulo="Tecnología y entretenimiento" />
      <Contenido opcion="b" ArrayItems={ArrayItems} />
      <div className="contenedorBreadcrumsCentrado">
        <Breadcrumbs modalMostrar={false} title="Descargar Condiciones" url="/" />
      </div>

      <div className="contenedorBtnPageItem d-flex justify-content-center flex-column">
        <div>
          <BtnAction callback={() => handleModal(true)} texto="Cotizá ahora tu seguro" />
        </div>
      </div>
      <StoreMobile />
      <BannerAzul />
      <InfoSeguros />
      <div className="contenedorBannerServiciosBici">
        <h6>
          {" "}
          <b>Otros clientes también eligieron</b> estos productos
        </h6>
        <CardsServicios vida accidentes vivienda />
      </div>
      <LegalesSeguros tipo="proteccion-inteligente" />
      <Container>
        <Row>
          <Col md={8} xs={11} className="mx-auto pb-5">
            <div className="text-legales pt-0">
              <p>
                "Consolas de Videojuegos" y “Protección Inteligente” son nombre comercial. En la documentación referida
                al producto se verá reflejado como "Protección". Disponible únicamente para clientes de Banco Santander
                Argentina S.A. que contraten el seguro desde la App Santander Río.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Footer />
      <BtnDenunciarUnSiniestro />
      <ModalContratarTecno estadoBoton={showModal} handleModal={handleModal} />
    </>
  );
};
