import React from 'react'
import { Container } from 'react-bootstrap'

import portada from '../../assets/images/Sostenibilidad/portada.svg'

export const Portada = () => {
  return (
    <Container>
      <section className='contenedorPortadaSostenibilidad'>
        <h1>Sostenibilidad</h1>
        <img src={portada} alt="Portada" />
      </section>
    </Container>
  )
}
