import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import DATAWEBFooter from "../../../assets/images/DATAWEBFooter.png";
import logoZurichFooter from "../../../assets/images/logoZurichFooter.svg";
import logoSSNFooter from "../../../assets/images/logoSSNFooter.svg";
import SectionFooter from "../../Footer/SectionFooter";

const Footer = () => {
  return (
    <Container fluid>
      <Container>
        {/* NavFooter */}
        <section>
          <div className="navbar-footer d-flex justify-content-md-start flex-md-row flex-column">
            <div className="columnas-navbar-footer text-md-start text-center">
              <h5>Seguros</h5>
              <ul>
                <li>
                  <Link to="/vida-y-salud">Vida y salud</Link>
                </li>
                <li>
                  <Link to="/hogar-y-negocio">Hogar y negocio</Link>
                </li>
                {/* <li>
                  <Link to="/vida-y-salud/accidentes-personales">Accidentes personales</Link>
                </li> */}
                <li>
                  <Link to="/tecnologia-protegida">Tecnología y entretenimiento</Link>
                </li>
                <li>
                  <Link to="/compras-y-gastos">Compras y gastos</Link>
                </li>
                <li>
                  <Link to="/seguro-de-bici">Movilidad</Link>
                </li>
              </ul>
            </div>

            <div className="columnas-navbar-footer  text-md-start text-center">
              <h5>Institucional</h5>
              <ul>
                <li>
                  <Link to="/nosotros">La Compañía</Link>
                </li>
                <li>
                  <Link to="/sostenibilidad">Sostenibilidad</Link>
                </li>
                <li>
                  <Link to="/gobierno-corporativo-y-sustentabilidad">Gobierno Corporativo</Link>
                </li>
                <li>
                  <Link to="/preguntas-frecuentes">Preguntas Frecuentes</Link>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="  https://www.linkedin.com/jobs/search/?currentJobId=3260134823&f_C=35485049&geoId=92000000"
                  >
                    RRHH
                  </a>
                </li>
                <li>
                  <Link to="/contacto">Contacto</Link>
                </li>
              </ul>
            </div>

            <div className="columnas-navbar-footer  text-md-start text-center">
              <h5>Legales</h5>
              <ul>
                <li>
                  <Link to="/politicas-de-privacidad">Privacidad</Link>
                </li>
                <li>
                  <Link to="/proteccion-de-datos">Protección de datos</Link>
                </li>
                <li>
                  <Link to="/prevencion-de-lavado-de-activos">Prevención de Lavado de Activos</Link>
                </li>
                {/* <li>
                  <Link to="/politicas-de-privacidad">Legales</Link>
                </li> */}
                <li>
                  <Link to="/servicio-de-atencion-al-asegurado">Servicio de atención al asegurado</Link>
                </li>
                <li>
                  <a href="https://www.zssa.com.ar/baja#!/" target={"_blank"} rel="noreferrer">
                    Baja de pólizas
                  </a>
                </li>
                <li>
                  <Link to="/contratos-de-adhesion">
                    Condiciones Contractuales - <br></br>Art. 38 Ley 24.240 / Res. 38708 art 25.2.2.F
                  </Link>
                </li>

                <li>
                  {" "}
                  <a href="https://autogestion.produccion.gob.ar/consumidores" target={"_blank"} rel="noreferrer">
                    Defensa de las y los Consumidores: <br></br> Para reclamos ingrese acá{" "}
                  </a>
                </li>
              </ul>
            </div>

            <div className="columnas-navbar-footer  text-md-start text-center">
              <a
                href="https://www.zssa.com.ar/sh/content/#!/arrepentimientodecompra"
                target={"_blank"}
                rel="noreferrer"
                style={{ textDecoration: "none" }}
                className="portal-agentes"
              >
                Me arrepentí de mi compra.
              </a>
              <p>No te preocupes, te ayudamos a que puedas darte de baja</p>
              <a href="https://www.zssa.com.ar/baja#!/" target={"_blank"} className="portal-agentes" rel="noreferrer">
                Quiero dar de baja mi seguro
              </a>
              <div className="pt-3">
                <a
                  href="https://www.santanderrioseguros.com.ar/agentes.do"
                  target={"_blank"}
                  className="portal-agentes"
                  rel="noreferrer"
                >
                  Portal Agentes
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* datosFooter */}

        <section className="text-md-start text-center">
          <div className="card-datos-footer d-flex justify-content-md-around justify-content-center flex-md-row flex-column">
            <div className="item-datos-footer text-center">
              <h4>Nº de inscripción en SSN</h4>
              <p>0692</p>
            </div>

            <div className="item-datos-footer text-center">
              <h4>Organismo de Control</h4>
              <a href={"https://www.argentina.gob.ar/superintendencia-de-seguros"} target="_blank" rel="noreferrer">
                www.ssn.gob.ar
              </a>
            </div>

            <div className="item-datos-footer text-center">
              <img src={logoSSNFooter} alt="SSN" />
            </div>
          </div>
        </section>

        {/* parrafoFooter */}
        <SectionFooter />
      </Container>

      {/* copyFooter */}

      <Container fluid className="copy-footer">
        <Container className="d-flex justify-content-md-between justify-content-center flex-md-row flex-column">
          <div className="pb-md-0 pb-4">
            <img src={logoZurichFooter} alt="Logo Zurich" />
          </div>

          <div className="pb-md-0 pb-4">
            <p>{new Date().getFullYear()} - Todos los derechos reservados</p>
          </div>

          <div className="pb-md-0 pb-4">
            <a href="http://qr.afip.gob.ar/?qr=xBQPupI9O8F9dxUFPCX-TQ,," target="_F960AFIPInfo">
              <img src={DATAWEBFooter} alt="DataWeb" />
            </a>
          </div>
        </Container>
      </Container>
    </Container>
  );
};

export default Footer;
