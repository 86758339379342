import React from "react";

const CardReviews = ({ title, subtitle, image, description }) => {
  return (
    <article className="card-review-container">
      <div className="card-review-header">
        {image && (
          <div className="card-review-image">
            <img src={image} alt="Persons" />
          </div>
        )}
        <div className="card-review-title">
          <h4> {title} </h4>
          <p> {subtitle} </p>
        </div>
      </div>
      <div className="card-review-body">
        <p> {description} </p>
      </div>
    </article>
  );
};

export default CardReviews;
