import React from "react";
import { Container } from "react-bootstrap";
import { CardPaises } from "./CardPaises";

import click from "../../assets/images/Nosotros/icons/click.svg";
import clock from "../../assets/images/Nosotros/icons/clock.svg";
import desktop from "../../assets/images/Nosotros/icons/desktop.svg";
import email from "../../assets/images/Nosotros/icons/email.svg";
import people from "../../assets/images/Nosotros/icons/people.svg";
import person from "../../assets/images/Nosotros/icons/person.svg";

import paises from "../../assets/images/Nosotros/paises.png";

export const Mapa = () => {
  return (
    <Container>
      <section className="contenedorMapa">
        <div className="contenedorMapa-mapa">
          <img src={paises} alt="Paises" />
        </div>
        <div className="contenedorMapa-cards">
          <h4>
            Menos trámites. <br />
            <b>Más tiempo libre para disfrutar.</b>
          </h4>
          <div className="contenedorTarjetas">
            <CardPaises
              logo={people}
              titulo="+ 1.400.000"
              parrafo="Se parte de una comunidad de clientes
              que viven más tranquilos."
            />
            <CardPaises logo={email} titulo="95 %" parrafo="De los siniestros denunciados se responden en 24hs." />
            <CardPaises
              logo={desktop}
              titulo="1 de cada 4"
              parrafo="Clientes realiza su denuncia de siniestros por la App Santander."
            />
            <CardPaises
              logo={click}
              titulo="81 %"
              parrafo="Es la calificación de nuestros clientes por la gestión de sus siniestros."
            />
            <CardPaises logo={person} titulo="1 de cada 2" parrafo="Clientes denuncia sus siniestros digitalmente." />
            <CardPaises logo={clock} titulo="24 hs" parrafo="Es el tiempo promedio en responder una denuncia." />
          </div>
          {/* <div className="bannerEstamosEntreUnosPocos">
            <div className="bannerEstamosEntreUnosPocos-img">
              <img src={entreunospocos} alt="Icon Entre unos pocos" />
            </div>
            <div className="bannerEstamosEntreUnosPocos-title">
              <h6>Estamos entre unos pocos</h6>
              <p>
                {" "}
                <b>Solo 6 de 188 aseguradoras</b> tienen una calificación <br />
                de riesgo AAA en Argentina
              </p>
            </div>
          </div> */}
        </div>
      </section>
    </Container>
  );
};
