import React, { useEffect, useState } from "react";
import Footer from "../components/Global/Footer/Footer";
import Header from "../components//Global/Header/Header";
import BannerContactate from "../components/Global/BannerContactate/BannerContactate";
import BtnDenunciarUnSiniestro from "../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";
import { GrillaNovedades } from "../components/Novedades/GrillaNovedades";
import { Portada } from "../components/Novedades/Portada";
import portada from "../assets/images/Novedades/portada.png";
import { getNews } from "../services/newsServices";

export const Novedades = () => {
  const [news, setNews] = useState([]);
  const [, setLoading] = useState(false);
  const [, setError] = useState(false);

  const handleCallApi = async () => {
    setLoading(true);

    await getNews()
      .then((res) => {
        setNews(res.data);
      })
      .catch((err) => {
        setError(err);
      });

    setLoading(false);
  };

  useEffect(() => {
    handleCallApi();
  }, []);

  return (
    <>
      <Header />
      <Portada
        titulo="Quo consectetur unde placeat facere repellat nam"
        parrafo="Laborum sint laborum ut ex. A debitis sint quasi eum asperiores qui optio. Cupiditate dolores neque facere et cumque reiciendis debitis quo exercitationem."
        imagen={portada}
        dirreccion="/"
      />
      <GrillaNovedades items={news} />
      <div className="sectionContactoNosotros">
        <BannerContactate />
      </div>
      <Footer />
      <BtnDenunciarUnSiniestro />
    </>
  );
};
