import React from "react";

import HeaderTabla01 from "../../../../assets/images/VidaYsalud/Vida/HeaderTabla01.svg";
import HeaderTabla02 from "../../../../assets/images/VidaYsalud/Vida/HeaderTabla02.svg";

export const HeaderPrimeraTabla = () => {
  return (
    <div className="cotenedorHeaderTablaSola">
      <div className="cotenedorHeaderTablaSola-titulo">
        <h5>
          {" "}
          Tu familia es lo más <br />
          importante,
          <b>
            {" "}
            protegerla <br />
            depende de vos.
          </b>
        </h5>
      </div>
      <div className="cotenedorHeaderTablaSola-primercuadro">
        <img src={HeaderTabla01} alt="Icon Header" />
      </div>
      <div className="cotenedorHeaderTablaSola-segundocuadro">
        <img src={HeaderTabla02} alt="Icon Header" />
      </div>
    </div>
  );
};
