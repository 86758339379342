import React from "react";
import arrowDropdown from "../../../../assets/images/icons/arrowDropdown.svg";
import { Link } from "react-router-dom";

export const MenuDesplegableChico = () => {
  return (
    <div className="menuDesplegadoHeader1">
      <img src={arrowDropdown} alt="Icon" />
      <Link to="/nosotros">
        Nosotros
        <span>Quienes hacen Zurich Santander</span>
      </Link>
      <Link to="/sostenibilidad">
        Sostenibilidad
        <span>Error id reprehenderit</span>
      </Link>
    </div>
  );
};
