import React from "react";
import { Link } from "react-router-dom";

import iconArrowBtn from "../../../assets/images/icons/icon-arrow-btn.svg";

const BtnMasInformacion = ({ url, texto, target }) => {
  return (
    <Link to={url} target={target && target} className="btnMasInformacion">
      {texto}
      <img src={iconArrowBtn} alt="Icon Arrow" />
    </Link>
  );
};

export default BtnMasInformacion;
