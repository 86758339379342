import "./styles/styles.scss";
import { AppProvider } from "./router/AppProvider";
import Routes from "./router/routes";

function App() {
  return (
    <AppProvider>
      <Routes />
    </AppProvider>
  );
}

export default App;
