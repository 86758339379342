import React, { useState } from "react";
import { Container, Dropdown, DropdownButton } from "react-bootstrap";
import { ModalSiniestro } from "./ModalSiniestro";

import ProductosaccidentesPersonales from "../../assets/images/TuveUnSiniestro/Icons/ProductosaccidentesPersonales.svg";
import Productoscartera from "../../assets/images/TuveUnSiniestro/Icons/Productoscartera.svg";
import ProductoscompraProtegida from "../../assets/images/TuveUnSiniestro/Icons/ProductoscompraProtegida.svg";
import ProductosgastosProtegidos from "../../assets/images/TuveUnSiniestro/Icons/ProductosgastosProtegidos.svg";
import ProductosintegralDeComercio from "../../assets/images/TuveUnSiniestro/Icons/ProductosintegralDeComercio.svg";
import ProductosproteccionDePagos from "../../assets/images/TuveUnSiniestro/Icons/ProductosproteccionDePagos.svg";
import ProductosproteccionInteligente from "../../assets/images/TuveUnSiniestro/Icons/ProductosproteccionInteligente.svg";
import ProductosProteccionMoviil from "../../assets/images/TuveUnSiniestro/Icons/ProductosproteccionInteligente.svg";
import Productosvida from "../../assets/images/TuveUnSiniestro/Icons/Productosvida.svg";
import SiniestroVivienda from "../../assets/images/TuveUnSiniestro/Icons/SiniestroVivienda.svg";

import accidentesPersonales from "../../pdf/siniestros/accidentesPersonales.pdf";
import compraProtegida from "../../pdf/siniestros/compraProtegida.pdf";
import extraccionesProtegidas from "../../pdf/siniestros/extraccionesProtegidas.pdf";
import gastosProtegidos from "../../pdf/siniestros/gastosProtegidos.pdf";
import negocioProtegido from "../../pdf/siniestros/negocioProtegido.pdf";
import proteccionCartera from "../../pdf/siniestros/proteccionCartera.pdf";
import proteccionDePagos from "../../pdf/siniestros/proteccionDePagos.pdf";
import proteccionInteligente from "../../pdf/siniestros/proteccionInteligente.pdf";
import proteccionMovil from "../../pdf/siniestros/proteccionMovil.pdf";
import proteccionSaludFemenina from "../../pdf/siniestros/proteccionSaludFemenina.pdf";
import proteccionSaluMasculina from "../../pdf/siniestros/proteccionSaludMasculina.pdf";
import roboDeCajero from "../../pdf/siniestros/roboDeCajero.pdf";
import vida from "../../pdf/siniestros/vida.pdf";
import vivienda from "../../pdf/siniestros/vivienda.pdf";

import PortadaImagen from "../../assets/images/TuveUnSiniestro/Portadas/Portada.svg";

export const Portada = () => {
  const [modalSiniestro, setModalSiniestro] = useState({
    show: false,
    Icono: "",
    Titulo: "",
    URLdocumentacion: "",
    formato: "",
  });

  const handleClose = () => {
    setModalSiniestro({
      ...modalSiniestro,
      show: false,
    });
  };
  const mostrarArticuloModal = (icono, titulo, url, formato) => {
    setModalSiniestro({
      show: true,
      Icono: icono,
      Titulo: titulo,
      URLdocumentacion: url,
      formato: formato,
    });
  };

  return (
    <Container>
      <section className="contenedorPortadaTuveUnSiniestro">
        <div className="contenedorPortadaTuveUnSiniestro-columnas text-center mb-md-5">
          <img src={PortadaImagen} alt="Portada" />
        </div>
        <div className="contenedorPortadaTuveUnSiniestro-columnas">
          <div className="contenedorPortadaTuveUnSiniestro-title">
            <h2>Hacé tu denuncia a través de nuestros canales</h2>
            <h1>¿Tuviste un siniestro?</h1>
            <h3>Seleccioná tu seguro y seguí los pasos para realizar tu denuncia</h3>
          </div>
          <div className="contenedorPortadaTuveUnSiniestro-dropdown">
            <DropdownButton id="dropdown-selecciona-tu-seguro" title="Seleccioná tu seguro">
              <Dropdown.Item
                href="#"
                onClick={() =>
                  mostrarArticuloModal(
                    ProductosaccidentesPersonales,
                    "accidentes personales",
                    accidentesPersonales,
                    "tipoC"
                  )
                }
              >
                Accidentes Personales
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={() =>
                  mostrarArticuloModal(ProductoscompraProtegida, "compra protegida", compraProtegida, "tipoA")
                }
              >
                Compra Protegida
              </Dropdown.Item>

              <Dropdown.Item
                href="#"
                onClick={() =>
                  mostrarArticuloModal(
                    ProductosproteccionDePagos,
                    "extracciones protegidas",
                    extraccionesProtegidas,
                    "tipoA"
                  )
                }
              >
                Extracciones Protegidas
              </Dropdown.Item>

              <Dropdown.Item
                href="#"
                onClick={() =>
                  mostrarArticuloModal(ProductosgastosProtegidos, "gastos protegidos", gastosProtegidos, "tipoC")
                }
              >
                Gastos Protegidos
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={() =>
                  mostrarArticuloModal(ProductosintegralDeComercio, "negocio protegido", negocioProtegido, "tipoC")
                }
              >
                Negocio Protegido
              </Dropdown.Item>

              <Dropdown.Item
                href="#"
                onClick={() => mostrarArticuloModal(Productoscartera, "protección cartera", proteccionCartera, "tipoA")}
              >
                Celular + Bolso
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={() =>
                  mostrarArticuloModal(
                    ProductosproteccionInteligente,
                    "protección inteligente",
                    proteccionInteligente,
                    "tipoA"
                  )
                }
              >
                Protección Inteligente
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={() =>
                  mostrarArticuloModal(ProductosProteccionMoviil, "protección móvil", proteccionMovil, "tipoC")
                }
              >
                Protección Móvil
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={() =>
                  mostrarArticuloModal(Productosvida, "protección salud femenina", proteccionSaludFemenina, "tipoC")
                }
              >
                Protección Salud Femenina
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={() =>
                  mostrarArticuloModal(Productosvida, "protección salud masculina", proteccionSaluMasculina, "tipoC")
                }
              >
                Protección Salud Masculina
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={() =>
                  mostrarArticuloModal(ProductosproteccionDePagos, "Protección de pagos", proteccionDePagos, "tipoC")
                }
              >
                Protección de Pagos
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={() =>
                  mostrarArticuloModal(ProductosproteccionDePagos, "robo en cajero", roboDeCajero, "tipoA")
                }
              >
                Robo en Cajero
              </Dropdown.Item>

              <Dropdown.Item href="#" onClick={() => mostrarArticuloModal(Productosvida, "vida", vida, "tipoC")}>
                Vida
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={() => mostrarArticuloModal(SiniestroVivienda, "vivienda", vivienda, "tipoA")}
              >
                Vivienda
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>

        <ModalSiniestro
          estadoBoton={modalSiniestro.show}
          cerrarModal={handleClose}
          Icono={modalSiniestro.Icono}
          Titulo={modalSiniestro.Titulo}
          URLdocumentacion={modalSiniestro.URLdocumentacion}
          formato={modalSiniestro.formato}
        />
      </section>
    </Container>
  );
};
