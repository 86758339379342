import React from "react";
import { Container } from "react-bootstrap";

import iconPeople from "../../../assets/images/TecnologiaProtegida/iconPeople.svg";
import iconAppleStore from "../../../assets/images/TecnologiaProtegida/iconAppleStore.svg";
import iconPlayStore from "../../../assets/images/TecnologiaProtegida/iconPlayStore.svg";

export const StoreMobile = () => {
  return (
    <Container>
      <section className="contenedorStoreMobileProtegida">
        <div className="contenedorStoreMobileProtegida-people">
          <img src={iconPeople} alt="Icon People" />
        </div>
        <div className="contenedorStoreMobileProtegida-title">
          <h4>Contratá desde la app</h4>
          <p>Asegurá desde la App Santander, de manera sencilla y en pocos pasos</p>
        </div>
        <div className="contenedorStoreMobileProtegida-mobile">
          <img src={iconAppleStore} alt="Icon Apple Store" />
          <img src={iconPlayStore} alt="Icon Play Store" />
        </div>
      </section>
    </Container>
  );
};
