import { useState } from "react";
import { Navbar, Nav, Container, Modal } from "react-bootstrap";

import logo from "../../../assets/images/logoZurich.svg";

import { MenuDesplegableGrande } from "./MenuDesplegableGrande/MenuDesplegableGrande";
import { MenuDesplegableChico } from "./MenuDesplegableChico/MenuDesplegableChico";
import { Link } from "react-router-dom";

const Header = () => {
  const [estadoModal, setEstadoModal] = useState(false);

  const handleShow = () => setEstadoModal(true);
  const handleClose = () => setEstadoModal(false);

  const [estadoModal2, setEstadoModal2] = useState(false);

  const handleShow2 = () => setEstadoModal2(true);
  const handleClose2 = () => setEstadoModal2(false);

  return (
    <Navbar fluid="true" className="navbar-zurich" expand="lg" fixed="top">
      <Container>
        <Navbar.Brand>
          <a href="/">
            <img src={logo} alt="Zurich - Santander" />
          </a>
        </Navbar.Brand>
        <Nav.Link
          href="https://www.zurichsantander.com.ar/mis-seguros"
          className="navbar-btn-ingresar d-block d-md-none little "
          target="_blank"
        >
          Mis Seguros
        </Nav.Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="flex-row-reverse">
          <Nav className="align-items-center">
            <Nav.Link href="#" className="itemConMenuDesplegable2 d-none d-lg-block correcionHoverMenu">
              <span>Productos</span>
              <MenuDesplegableGrande />
            </Nav.Link>
            <Nav.Link href="#" className="itemConMenuDesplegable2 d-block d-lg-none">
              <span onClick={handleShow}>Productos</span>

              <Modal show={estadoModal} onHide={handleClose}>
                <button
                  type="button"
                  className="btn-close close-personalizado-1"
                  aria-label="Close"
                  onClick={() => handleClose()}
                ></button>
                <MenuDesplegableGrande />
              </Modal>
            </Nav.Link>

            <Nav.Link href="#" className="itemConMenuDesplegable1 d-none d-lg-block correcionHoverMenu">
              <Link to="/nosotros" className="nav-link">
                <span>La Compañía</span>
              </Link>
              {/* <MenuDesplegableChico /> */}
            </Nav.Link>
            <Nav.Link href="#" className="itemConMenuDesplegable1 d-block d-lg-none">
              <span onClick={handleShow2}>La Compañía</span>
              <Modal show={estadoModal2} onHide={handleClose2}>
                <button
                  type="button"
                  className="btn-close close-personalizado-2"
                  aria-label="Close"
                  onClick={() => handleClose2()}
                ></button>
                <MenuDesplegableChico />
              </Modal>
            </Nav.Link>

            <Link to="/contacto" className="nav-link">
              Contacto
            </Link>
            <Link to="/preguntas-frecuentes" className="nav-link">
              Preguntas frecuentes
            </Link>
            {/* <Nav.Link href="#" className="icon-search">
              <img src={IconSearch} alt="Search" />
            </Nav.Link> */}
            <Nav.Link
              href="https://www.zurichsantander.com.ar/mis-seguros"
              className="navbar-btn-ingresar d-md-block d-none"
              target="_blank"
            >
              Mis Seguros
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
