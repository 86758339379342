import React from 'react'
import Footer from '../components/Global/Footer/Footer'
import Header from "../components//Global/Header/Header";
import BtnDenunciarUnSiniestro from '../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro'
import Reviews from '../components/Global/Reviews/Reviews'
import { BannerCv } from '../components/Nosotros/BannerCv'
import { Mapa } from '../components/Nosotros/Mapa'
import { Necesidades } from '../components/Nosotros/Necesidades'
import { NuestroProposito } from '../components/Nosotros/NuestroProposito'
import { Portada } from '../components/Nosotros/Portada'

export const Nosotros = () => {
  return (
    <>
        <Header />

        <Portada />
        <Necesidades />
        <Mapa />
        <Reviews />
        <NuestroProposito />
        <BannerCv />
        
        <Footer />
        <BtnDenunciarUnSiniestro />
    </>
  )
}
