import React from 'react'
import BtnMasInformacion from "../Global/BtnMasInformacion/BtnMasInformacion";

export const CardsItems = ({icono,subtitulo,titulo,url}) => {
  return (
    <div className='containerCardTecnologiaProtegida'>
      <div className='containerCardTecnologiaProtegida-img'>
        <img src={icono} alt={titulo} />
      </div>
      <div className='containerCardTecnologiaProtegida-body'>
        <h5> {subtitulo} </h5>
        <h3> {titulo} </h3>
        <div className='containerCardTecnologiaProtegida-body-a'>
          <BtnMasInformacion url={url} texto="Más información" />
        </div>
      </div>
    </div>
  )
}
