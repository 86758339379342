import React from 'react'
import { Container } from 'react-bootstrap'


export const TituloEscribenos = () => {
  return (
    <Container className='contenedorPersonalizadoEscribenos'>
        <section className='contenedorTituloEscribenos'>
            <h1>Escribinos</h1>
            <p>Envianos un mensaje con tu consuta</p>
        </section>
    </Container>
  )
}
