import React from "react";
import Footer from "../components/Global/Footer/Footer";
import Header from "../components//Global/Header/Header";

import { TituloContacto } from "../components/Contacto/TituloContacto";
import { SectionConIconos } from "../components/Contacto/SectionConIconos";
import { SectionSinIconos } from "../components/Contacto/SectionSinIconos";
import BtnDenunciarUnSiniestro from "../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";

import fondoRojo from "../assets/images/Contacto/fondoRojo.svg";
import BannerContactate from "../components/Global/BannerContactate/BannerContactate";

export const Contacto = () => {
  return (
    <>
      <Header />
      <TituloContacto />
      <SectionConIconos />
      {/* <HaceTuDenuncia /> */}
      <BannerContactate hideFaq />
      <SectionSinIconos />
      <img src={fondoRojo} alt="Icon" className="imagenFlotandoContacto" />
      <Footer />
      <BtnDenunciarUnSiniestro />
    </>
  );
};
