import React from "react";

export const BannerAzul = () => {
  return (
    <></>
    // <Container>
    //     <section className='ContenedorBannerAzul'>
    //         <img src={ImageCamera} alt="Icon" />
    //         <p>De no haber encontrado en la lista lo que querés asegurar ¡ponemos a tu disposición un lugar para dejarnos tu sugerencia! <Link to="/">Hacé click acá</Link></p>
    //     </section>
    // </Container>
  );
};
