import React from "react";
import { Container } from "react-bootstrap";

import cv from "../../assets/images/icons/IconoTrabajaConNos.svg";

export const BannerCv = () => {
  return (
    <Container>
      <section className="contenedorBannerCv">
        <div className="contenedorBannerCv-img">
          <img src={cv} alt="Icon" />
        </div>
        <div className="contenedorBannerCv-title">
          <h3>oferta laboral</h3>
          <h2>¿Querés trabajar con nosotros?</h2>
          <p>Sumate al equipo de Zurich Santander. Mirá nuestras búsquedas.</p>
        </div>
        <div className="contenedorBannerCv-btn">
          <a
            href={"https://www.linkedin.com/jobs/search/?currentJobId=3260134823&f_C=35485049&geoId=92000000"}
            target={"_blank"}
            rel="noreferrer"
          >
            Postularme
          </a>
        </div>
      </section>
    </Container>
  );
};
