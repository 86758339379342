import { Contenido } from "../../../TecnologiaProtegida/PageItem/Contenido";
import CardsServicios from "../../../Home/CardsServicios/CardsServicios";
import LegalesSeguros from "../../../LegalesSeguros/LegalesSeguros";
import AccordionAsistencias from "../../../AccordionAsistencias/AccordionAsistencias";
import { dataAsistencias } from "../../../../utils/resources/nuevasAsistencias";
import FooterAll from "../../../FooterAll";
import InfoIntegral from "./InfoIntegral";
import { useState } from "react";

const ContenidoNegocio = ({ ArrayItems }) => {
  const [showModal, setShowModal] = useState(false);

  const handleModal = (v) => {
    setShowModal(v);
  };

  return (
    <>
      <Contenido
        opcion="c"
        UrlBtn="/"
        NombreBtn="Descargar Condiciones"
        ArrayItems={ArrayItems}
        tituloProteccion="negocio"
      />
      <AccordionAsistencias data={dataAsistencias[0].negocio} />
      <InfoIntegral callback={() => handleModal(true)} />
      <div className="contenedorBannerServiciosBici">
        <h6>
          {" "}
          <b>Otros clientes también eligieron</b> estos productos
        </h6>
        <CardsServicios vida accidentes vivienda />
      </div>
      <LegalesSeguros tipo="integral-de-comercio" />
      <FooterAll showModal={showModal} handleModal={handleModal} />
    </>
  );
};

export default ContenidoNegocio;
