import React, { useState } from "react";
import Header from "../../Global/Header/Header";
import { Portada } from "../../TecnologiaProtegida/PageItem/Portada";
import CardsServicios from "../../Home/CardsServicios/CardsServicios";
import { Tablas } from "../../Global/Tablas/Tablas";
import { HeaderPrimeraTabla } from "./Vida/HeaderPrimeraTabla";
import { BadgeGris } from "./Vida/BadgeGris";
import { Asistencias } from "./Vida/Asistencias";
import { Breadcrumbs } from "../../Global/Breadcrumbs/Breadcrumbs";
import { SantanderSelect } from "../../Global/SantanderSelect/SantanderSelect";

import portadaEstatica from "../../../assets/images/VidaYsalud/Vida/Portada.svg";
import icon1 from "../../../assets/images/VidaYsalud/Vida/IconsTabla/1.svg";
import icon2 from "../../../assets/images/VidaYsalud/Vida/IconsTabla/2.svg";
import icon3 from "../../../assets/images/VidaYsalud/Vida/IconsTabla/3.svg";
import icon4 from "../../../assets/images/VidaYsalud/Vida/IconsTabla/4.svg";
import icon5 from "../../../assets/images/VidaYsalud/Vida/IconsTabla/5.svg";
import icon6 from "../../../assets/images/VidaYsalud/Vida/IconsTabla/6.svg";
import icon7 from "../../../assets/images/VidaYsalud/Vida/IconsTabla/7.svg";
import icon8 from "../../../assets/images/VidaYsalud/Vida/IconsTabla/8.svg";

// Icons Cards Santander Select
import iconSelect6 from "../../../assets/images/VidaYsalud/Vida/IconsCardSelect/6.svg";
import iconSelect8 from "../../../assets/images/VidaYsalud/Vida/IconsCardSelect/8.svg";
import { Beneficiarios } from "./Vida/Beneficiarios";
import BtnAction from "../../Global/BtnMasInformacion/BtnAction";
import LegalesSeguros from "../../LegalesSeguros/LegalesSeguros";
import { Col, Container, Row } from "react-bootstrap";
import { dataAsistencias } from "../../../utils/resources/nuevasAsistencias";
import FooterAll from "../../FooterAll";
import InfoSeguros from "../../InfoSeguros";

export const Vida = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModal = (v) => {
    setShowModal(v);
  };

  const ArrayItemsCardSelect = [
    // {
    //   icono: iconSelect1,
    //   parrafo: "Descuento del 50% en lentes recetados.",
    //   title: "Asistencia exclusiva para clientes Santander Select",
    // },
    // {
    //   icono: iconSelect2,
    //   parrafo: "Descuento del 50% en alquiler de artículos ortopédicos.",
    //   title: "Asistencia exclusiva para clientes Santander Select",
    // },
    // {
    //   icono: iconSelect3,
    //   parrafo: "Descuento del 50% en alquiler de cama ortopédica.",
    //   title: "Asistencia exclusiva para clientes Santander Select",
    // },
    // {
    //   icono: iconSelect4,
    //   parrafo: "Descuento del 50% en internación domiciliaria.",
    //   title: "Asistencia exclusiva para clientes Santander Select",
    // },
    // {
    //   icono: iconSelect5,
    //   parrafo: "Servicio de limpieza en caso de internación.",
    //   title: "Asistencia exclusiva para clientes Santander Select",
    // },
    {
      icono: iconSelect6,
      parrafo: "Contratar mayores sumas aseguradas a tu medida.",
      title: "Beneficio exclusivo para clientes Santander Select",
    },
    // {
    //   icono: iconSelect7,
    //   parrafo: "Realización de examen médico a domicilio.",
    //   title: "Asistencia exclusiva para clientes Santander Select",
    // },
    {
      icono: iconSelect8,
      parrafo: "Mayor agilidad en la liquidación de siniestros.",
      title: "Beneficio exclusivo para clientes Santander Select",
    },
  ];

  const datosApi1 = [
    {
      icono: icon1,
      titulo: "Indemnización en caso de pérdida de la vida",
      toltip: "Edad máxima de contratación: hasta los 65 años. Protección hasta los 75 años).",
      mostrar1: true,
      mostrar2: true,
    },
    {
      icono: icon2,
      titulo: "Doble indemnización por pérdida de la vida a consecuencia de un accidente",
      toltip: "Edad máxima de contratación: hasta los 60 años. Protección hasta los 65 años.",
      mostrar1: true,
      mostrar2: true,
    },
    {
      icono: icon3,
      titulo: "Indemnización en caso de pérdidas físicas parciales por accidente",
      toltip:
        "Es toda lesión corporal producida directa y exclusivamente por causas externas, violentas y fortuitas ajenas a toda otra causa e independientes de la voluntad del Asegurado. Cuando las pérdidas físicas se hayan experimentado dentro de los ciento ochenta (180) días del suceso o accidente.",
      mostrar1: true,
      mostrar2: true,
    },
    {
      icono: icon4,
      titulo: "Cobertura ante invalidez total y permanente",
      toltip:
        "Cuando como consecuencia de una enfermedad o accidente, el asegurado no pueda desempeñar por cuenta propia o en relación de dependencia cualquier actividad remunerativa. La incapacidad debe ser total y definitiva.",
      mostrar1: true,
      mostrar2: true,
    },
    {
      icono: icon5,
      titulo: "Indemnización por enfermedades críticas",
      toltip:
        "Edad máxima de contratación: hasta los 60 años. Se cubre la sobrevivencia de la enfermedad. Protección hasta los 65 años. Enfermedades cubiertas: Cáncer, Apoplejía, Operación de “By-Pass”, Insuficiencia Renal, Parálisis, Distrofia Muscular o Esclerosis Múltiple.",
      mostrar1: true,
      mostrar2: true,
    },
    {
      icono: icon6,
      titulo: "Indemnización en caso de trasplantes de órganos",
      toltip:
        "Edad máxima de contratación: hasta los 60 años. Protección hasta los 65 años. Cubre los siguientes trasplantes: Páncreas, Corazón, Corazón-Pulmón(2), Pulmón, Riñón, Hígado, Médula, Córnea.",
      mostrar1: true,
      mostrar2: true,
    },
    {
      icono: icon7,
      titulo: "Servicios Adicionales incluidos en todos los planes",
      toltip:
        "Cobertura en todo el mundo. Flexibilidad para seleccionar y modificar beneficiarios y el capital asegurado cuando lo desees. También podés deducir las primas de la base imponible del impuesto a las ganancias, hasta $24.000 anuales, con el tope que fije la normativa aplicable.",
      mostrar1: true,
      mostrar2: true,
    },
    {
      icono: icon8,
      titulo: "Extensión de la cobertura a tus hijos menores y a esposa/o",
      toltip:
        "Con el plan familiar, podés extender la cobertura que contrates a tus hijos menores de 21 años. El beneficio extensible a los hijos resultará el 10% del capital asegurado hasta un máximo de $200.000.",
      mostrar1: false,
      mostrar2: true,
    },
  ];

  return (
    <>
      <Header />
      <Portada portada={portadaEstatica} titulo="Seguro de vida" subtitulo="Vos y tu familia, siempre protegidos" />
      <HeaderPrimeraTabla />
      <div className="contenedorSegundaTablaVida">
        <Tablas arrayContenido={datosApi1} mostrarHeader={false} />
      </div>
      {/* <HeaderSegundaTabla />
      <div className="contenedorSegundaTablaVida">
        <Tablas arrayContenido={datosApi2} mostrarHeader={false} />
      </div> */}

      <div className="contenedorBreadcrumbsVida">
        <Breadcrumbs modalMostrar={true} title="Descargar Condiciones" url="/" opcionModal={false} />
      </div>

      <Asistencias data={dataAsistencias[0].vida} />
      <BadgeGris />
      <div className="contenedorBtnPageItem">
        <BtnAction callback={() => handleModal(true)} texto="Cotizá ahora tu seguro" />
      </div>
      <div>
        <Container>
          <Row>
            <Col>
              <h5 className="text-center pt-5 pb-0">
                Además, con el Seguro de Vida, podés deducir hasta $42.921(1) del impuesto a las ganancias.
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="contenedorItemsGrisVidaPage">
        <SantanderSelect items={ArrayItemsCardSelect} />
      </div>
      <Beneficiarios />
      <InfoSeguros />
      <div className="contenedorBannerServiciosBici">
        <h6>
          {" "}
          <b>Otros clientes también eligieron</b> estos productos
        </h6>
        <CardsServicios vivienda accidentes extracciones />
      </div>
      <LegalesSeguros tipo="vida" />
      <FooterAll showModal={showModal} handleModal={handleModal} />
    </>
  );
};
