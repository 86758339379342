import BannerContactate from "./Global/BannerContactate/BannerContactate";
import Reviews from "./Global/Reviews/Reviews";

const InfoSeguros = () => {
  return (
    <>
      <div className="contenedorReviewsPagesItems">
        <Reviews />
      </div>
      <div className="contenedorBannerContactateTecnologiaProtegida">
        <BannerContactate />
      </div>
    </>
  );
};

export default InfoSeguros;
