import React from 'react';
import { Container } from "react-bootstrap";

export const Necesidades = () => {
  return (
    <Container>
      <section className='contenedorNecesidades'>
        <h3>¿Sabías que desde 2011 tenés a tu disposición seguros que se ajustan a tus necesidades?</h3>
        <p>El líder mundial en seguros Zurich y el líder bancario en latinoamerica Banco <br/>
          Santander formaron una propuesta innovadora para proteger lo que más querés, <br/>
          creando <b>Zurich Santander.</b>  Ahora podés estar tranquilo, contás con nosotros.</p>
      </section>
    </Container>
    
  )
}
