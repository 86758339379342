import React from "react";
import { Container } from "react-bootstrap";
import { ItemNovedades } from "./ItemNovedades";

export const GrillaNovedades = ({ items }) => {
  return (
    <Container>
      <section className="contenedorGrillaNovedades">
        {items.map((item) => (
          <ItemNovedades
            key={item.id}
            imagen={`${item.attributes.Banner.data.attributes.url}`}
            date={item?.attributes?.createdAt}
            title={item.attributes.Titulo}
            abstract={item.attributes.Abstract}
            url={`/novedades/${item.attributes.Slug}-${item.id}`}
          />
        ))}
      </section>
    </Container>
  );
};
