import React, { useState } from "react";
export const UserProvider = React.createContext();

export const AppProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  return (
    <UserProvider.Provider
      value={{
        userData,
        setUserData,
      }}
    >
      {children}
    </UserProvider.Provider>
  );
};
