import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CardNuestrosAportes } from "./CardNuestrosAportes";

import cuidarte from "../../assets/images/Sostenibilidad/nuevas/cuidarte.jpg";
import programaEmpujar from "../../assets/images/Sostenibilidad/nuevas/programa-empujar.jpg";
import programaTuFuturo from "../../assets/images/Sostenibilidad/nuevas/programa-tu-futuro.jpg";
import vida1 from "../../assets/images/Sostenibilidad/nuevas/vidas1.jpg";
import todavia from "../../assets/images/Sostenibilidad/nuevas/todavia.JPG";

export const NuestrosAportes = () => {
  return (
    <Container>
      <section className="contenedorAportesRealizados">
        <h3>Nuestros aportes realizados</h3>
        <div className="contenedorAportesRealizados-cards row">
          <CardNuestrosAportes
            imagen={vida1}
            titulo="Transformar Vidas"
            parrafo="En el marco de esta acción entregamos más de 100.000 platos de comida,
beneficiando a 40 Organizaciones Sociales de Base (OSB) del Conurbano
Bonaerense, que asisten de forma directa e integral a niños, niñas, adolescentes y
familias atravesadas por la vulnerabilidad social."
          />

          <CardNuestrosAportes
            imagen={programaEmpujar}
            titulo="Programa Empujar"
            parrafo="Zurich Santander es uno de los principales colaboradores de este programa que
tiene como objetivo central potenciar oportunidades laborales capacitando
mediante el desarrollo personal y social a jóvenes con deseos de progresar. En
2020 comenzamos a trabajar codo a codo con la fundación Pléroma y, hasta el
momento, hemos ayudado a más de 500 chicos y chicas a capacitarse y tener
oportunidades en el mundo laboral. "
            direccion="https://fundacionempujar.org/"
          />

          <CardNuestrosAportes
            imagen={programaTuFuturo}
            titulo="Programa Tu Futuro"
            parrafo="Zurich Santander participa activamente en este programa realizado por la
organización Forge. El programa tiene como fin motivar a jóvenes
económicamente vulnerables para que accedan a una vida de calidad a través
del trabajo, el aprendizaje continuo y el compromiso con la comunidad. El
Programa se focaliza en el desarrollo de habilidades socioemocionales y
digitales clave para los trabajos del futuro y la adaptación a escenarios
cambiantes"
            direccion="https://fondationforge.org/"
          />

          <CardNuestrosAportes
            imagen={cuidarte}
            titulo="Cuidarte Ayuda"
            parrafo="Iniciamos una campaña donde por cada seguro de Accidentes Personales
contratado durante el mes de octubre o hasta alcanzar las ventas de 7000 nuevas
pólizas, el equivalente a un tercio de la primera cuota fue donado a la Asociación
Conciencia. Los clientes tuvieron la posibilidad de votar por 3 alternartivas: Entrega
de alimentos, Elementos de higiene o Material didáctico educativ"
            direccion="https://conciencia.org/"
          />

          <CardNuestrosAportes
            imagen={todavia}
            titulo="Todavía es Tiempo"
            parrafo="Durante el año 2022 realizamos distintas actividades de voluntariado junto a la
Fundación Todavía es Tiempo, quienes promueven el desarrollo integral de niños,
adolescentes y jóvenes en situaciones vulnerables, a través de la asistencia,
contención y educación, ayudándolos a potenciar sus capacidades para crear
futuros con mas oportunidades"
            direccion="https://fundaciontodaviaestiempo.org.ar/"
          />
        </div>

        <h3>Mecenazgo</h3>

        <Row>
          <Col className="contenedorParrafoPrincipalSustentabilidad">
            <p>
              A través del programa de financiamiento del Ministerio de Cultura de la Ciudad de Buenos Aires,
              participamos del Régimen de Promoción Cultura, el cual nos permite financiar proyectos culturales
              destinando parte de lo que nos corresponde pagar como ingresos brutos a distintos proyectos presentados
              dentro de este marco. Todos los meses seleccionamos proyectos en temáticas culturales, sociales o
              ambientales, que luego son evaluados y aprobados para poder realizar los aportes.
            </p>
          </Col>
        </Row>
      </section>
    </Container>
  );
};
