import React, { useEffect, useState } from "react";
import BtnDenunciarUnSiniestro from "../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";
import Footer from "../components/Global/Footer/Footer";
import Header from "../components/Global/Header/Header";
import BannerContactate from "../components/Global/BannerContactate/BannerContactate";
import { Contenido } from "../components/NovedadesPost/Contenido";
import { TituloPost } from "../components/NovedadesPost/TituloPost";
import { useParams } from "react-router-dom";
import { getNew } from "../services/newsServices";
import Loading from "../components/Global/Loading/Loading";

export const NovedadesPost = () => {
  let { post } = useParams();

  const [newP, setNewP] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleCallApi = async () => {
    setLoading(true);

    let id = post.split(/[- ]+/).pop();

    await getNew(id)
      .then((res) => {
        setNewP(res.data);
      })
      .catch((err) => {
        setError(err);
      });

    setLoading(false);
  };

  useEffect(() => {
    handleCallApi();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Header />
      {loading ? (
        <div>
          <Loading />
        </div>
      ) : !error ? (
        <>
          <TituloPost
            title={newP?.attributes?.Titulo}
            date={newP?.attributes?.updatedAt}
            cat={newP?.attributes?.novedades_categorias.data}
          />
          <Contenido
            abstract={newP?.attributes?.abstract}
            description={newP?.attributes?.Description}
            image={`${newP?.attributes?.Banner.data.attributes.url}`}
          />
        </>
      ) : (
        <div className="d-flex justify-content-center pt-5 mt-5 pb-5 mt-4">No existe la nota seleccionada</div>
      )}
      <div className="sectionContactoNosotros">
        <BannerContactate />
      </div>
      <Footer />
      <BtnDenunciarUnSiniestro />
    </div>
  );
};
