import React from 'react'
import { Container } from 'react-bootstrap'

export const Copy = () => {
  return (
    <Container>
        <section className='contenedorCopyPreguntasFrecuentes'>
            <p>La información aquí contenida es meramente ilustrativa y no sustituye, no modifica ni amplía a las Condiciones Generales, Particulares y demás documentación que forma parte de la Póliza.</p>
            <p>Por eso, recomendamos leer detenidamente toda tu Póliza para conocer el alcance de la cobertura contratada, ya que los Productos, Coberturas y Servicios de Asistencia ante Urgencias pueden tener condiciones especiales, medidas de seguridad que tenés que cumplir, límites máximos de suma asegurada (monto máximo a abonar en caso de corresponder el pago del siniestro), límite en la cantidad de eventos cubiertos y exclusiones (hechos y situaciones que no están cubiertos por la póliza).</p>
        </section>
    </Container>
  )
}
