import React from "react";

import IconArrow from "../../assets/images/Contacto/IconArrow.svg";

export const CardWithIcons = ({ imagen, titulo, parrafo, direccion, nombreBoton, target, texto }) => {
  return (
    <div className="cardWithIconsContacto">
      <img src={imagen} alt={titulo} />
      <h2> {titulo} </h2>
      <p> {parrafo} </p>
      {direccion && (
        <a href={direccion} target={target && target}>
          {" "}
          {nombreBoton} <img src={IconArrow} alt="Icon Arrow" />{" "}
        </a>
      )}
      {texto && <p className="mb-0">{texto}</p>}
    </div>
  );
};
