import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import IconArrowLeft from "../../assets/images/NovedadesPost/IconArrowLeft.svg";

export const TituloPost = ({ title, date, cat }) => {
  return (
    <Container>
      <section className="contenedorTituloPost">
        <div className="contenedorTituloPost-time">
          <Link to="/novedades">
            {" "}
            <img src={IconArrowLeft} alt="Icon Arrow Left" /> Volver atrás
          </Link>
          <span>{date}</span>
        </div>
        <div className="contenedorTituloPost-title">
          <h1>{title}</h1>
        </div>
        <div className="contenedorTituloPost-tags">
          {cat && cat.length > 0 && cat.map((c) => <span>{c.attributes.Nombre}</span>)}
        </div>
      </section>
    </Container>
  );
};
