import React from 'react';
import Footer from '../components/Global/Footer/Footer'
import Header from "../components//Global/Header/Header";
import BtnDenunciarUnSiniestro from '../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro'
import { NuestrosAportes } from '../components/Sostenibilidad/NuestrosAportes';
import { ParrafoPrincipal } from '../components/Sostenibilidad/ParrafoPrincipal';
import { Portada } from '../components/Sostenibilidad/Portada';
import { TipsSostenibles } from '../components/Sostenibilidad/TipsSostenibles';
import { TrabajamosJuntos } from '../components/Sostenibilidad/TrabajamosJuntos';
import Reviews from '../components/Global/Reviews/Reviews'

export const Sostenibilidad = () => {
  return (
    <>
        <Header />
        <Portada />
        <ParrafoPrincipal />
        <NuestrosAportes />
        <TrabajamosJuntos />
        <TipsSostenibles />
        <Reviews />
        <Footer />
        <BtnDenunciarUnSiniestro />
    </>
  )
}
