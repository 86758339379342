import Montos_Asistencias_Vivienda_Basico_MAYO_JUNIO from "../../pdf/asistencias/Montos_Asistencias_Vivienda_Basico.pdf";
import Montos_Asistencias_Vivienda_Select_MAYO_JUNIO from "../../pdf/asistencias/Montos_Asistencias_Vivienda_Select.pdf";
import Seguro_Vivienda_Asistencias_Web_240506 from "../../pdf/asistencias/vivienda/21_Seguro_Vivienda_Asistencias_Web_240506.pdf";
import Seguro_Vivienda_Mascotas_Asistencias_Web_240506 from "../../pdf/asistencias/vivienda/21_Seguro_Vivienda_Mascotas_Asistencias_Web_240506.pdf";
import Montos_Mascotas_MAYO_JUNIO_2024 from "../../pdf/asistencias/Montos_Mascotas.pdf";
import Cuadernillo_Seguro_de_Negocio_Protegido_Asistencias_Web_240108 from "../../pdf/asistencias/Cuadernillo_Seguro_de_Negocio_Protegido_Asistencias_Web_240108.pdf";
import Montos_Asistencias_Negocio_Protegido from "../../pdf/asistencias/Montos_Asistencias_Negocio_Protegido.pdf";
import AP_Asistencias_Web_240506 from "../../pdf/asistencias/01_AP_Asistencias_Web_240506.pdf";
import Montos_Asistencias_AP from "../../pdf/asistencias/Montos_Accidentes_Personales.pdf";
import Protegida_Asistencias_Web_240109 from "../../pdf/asistencias/Familia_Protegida_Asistencias_Web_240109.pdf";
import Montos_Asistencias_Vida_BASICO_MARZO from "../../pdf/asistencias/Montos_Asistencias_Vida_Basico.pdf";
import Montos_Asistencias_Vida_Select_MARZO from "../../pdf/asistencias/Montos_Asistencias_Vida_Select.pdf";
import Montos_Asistencias_Proteccion_Cartera_BASICO_MAYO_JUNIO24 from "../../pdf/asistencias/Montos_Asistencias_Proteccion_Cartera.pdf";
import Seguro_de_Proteccion_Cartera_Asistencias_Web_240109 from "../../pdf/asistencias/Seguro_de_Proteccion_Cartera_Asistencias_Web_240109.pdf";

import seguro_de_vida_familia_protegida from "../../pdf/asistencias/seguro_de_vida_familia_protegida.pdf";
import seguro_de_vida_familia_protegida_select from "../../pdf/asistencias/seguro_de_vida_familia_protegida_select.pdf";
import seguro_de_vida_a_tu_medida_asistencias from "../../pdf/asistencias/seguro_de_vida_a_tu_medida_asistencias.pdf";
import seguro_de_vida_a_tu_medida_asistencias_select from "../../pdf/asistencias/seguro_de_vida_a_tu_medida_asistencias_select.pdf";

export const dataAsistencias = [
  {
    cartera: {
      contents: [
        [
          { type: "header", text: "" },
          { type: "link", text: "Asistencias", href: Seguro_de_Proteccion_Cartera_Asistencias_Web_240109 },
        ],
        [
          { type: "header", text: "" },
          { type: "link", text: "Montos y eventos", href: Montos_Asistencias_Proteccion_Cartera_BASICO_MAYO_JUNIO24 },
        ],
      ],
    },

    vivienda: {
      contents: [
        [
          { type: "header", text: "Asistencias" },
          { type: "link", text: "Vivienda", href: Seguro_Vivienda_Asistencias_Web_240506 },
          { type: "link", text: "Mascotas", href: Seguro_Vivienda_Mascotas_Asistencias_Web_240506 },
        ],
        [
          { type: "header", text: "Montos y eventos" },
          { type: "link", text: "Vivienda", href: Montos_Asistencias_Vivienda_Basico_MAYO_JUNIO },
          { type: "link", text: "Vivienda Select", href: Montos_Asistencias_Vivienda_Select_MAYO_JUNIO },
          { type: "link", text: "Mascotas", href: Montos_Mascotas_MAYO_JUNIO_2024 },
        ],
      ],
    },

    negocio: {
      contents: [
        [
          { type: "header", text: "" },
          { type: "link", text: "Asistencias", href: Cuadernillo_Seguro_de_Negocio_Protegido_Asistencias_Web_240108 },
        ],
        [
          { type: "header", text: "" },
          { type: "link", text: "Montos y eventos", href: Montos_Asistencias_Negocio_Protegido },
        ],
      ],
    },

    accidentesPersonales: {
      contents: [
        [
          { type: "header", text: "" },
          { type: "link", text: "Asistencias", href: AP_Asistencias_Web_240506 },
        ],
        [
          { type: "header", text: "" },
          { type: "link", text: "Montos y eventos", href: Montos_Asistencias_AP },
        ],
      ],
    },

    vida: {
      contents: [
        [
          { type: "header", text: "Asistencias de vida" },
          { type: "link", text: "Vida", href: Protegida_Asistencias_Web_240109 },
          { type: "header", text: "Asistencias en viaje" },
          { type: "link", text: "Vida a tu Medida", href: seguro_de_vida_a_tu_medida_asistencias },
          { type: "link", text: "Familia protegida", href: seguro_de_vida_familia_protegida },
          {
            type: "link",
            text: "Vida a tu Medida Select",
            href: seguro_de_vida_a_tu_medida_asistencias_select,
          },
          { type: "link", text: "Familia Protegida Select", href: seguro_de_vida_familia_protegida_select },
        ],
        [
          { type: "header", text: "Montos y eventos" },
          { type: "link", text: "Vida", href: Montos_Asistencias_Vida_BASICO_MARZO },
          { type: "link", text: "Vida Select", href: Montos_Asistencias_Vida_Select_MARZO },
        ],
      ],
    },
  },
];
