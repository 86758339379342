import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Footer from "../components/Global/Footer/Footer";
import Header from "../components//Global/Header/Header";
import BtnDenunciarUnSiniestro from "../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";
import { getContracts } from "../services/contractsServices";
import Loading from "../components/Global/Loading/Loading";

export const ContratosDeAdhesion = () => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);

  const handleCallApi = async () => {
    setLoading(true);
    await getContracts()
      .then((res) => {
        setContracts(res.data);

        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    handleCallApi();
  }, []);

  return (
    <div>
      <Header />
      <Container>
        <section className="contenedorLegales">
          <h1>
            Condiciones Contractuales - <br></br>Art. 38 Ley 24.240 / Res. 38708
            art 25.2.2.F
          </h1>

          <h7>
            Las claúsulas aplicables a tu póliza dependerán del plan contratado
          </h7>

          <div className="listadoParaDescargarLegales">
            {loading ? (
              <div className="d-flex justify-content-center align-content-center align-items-center pt-3 pb-3">
                <Loading className="mt-5 mb-5" />
              </div>
            ) : (
              <>
                {contracts &&
                  contracts.length > 0 &&
                  contracts
                    .sort((a, b) => a.attributes.Orden - b.attributes.Orden)
                    .map((contract, index) => (
                      <p key={index}>
                        <a
                          target="_blank"
                          href={`${contract.attributes.Archivo.data.attributes.url}`}
                          rel="noreferrer"
                        >
                          {contract.attributes.Nombre}
                        </a>
                      </p>
                    ))}
              </>
            )}
          </div>
        </section>
      </Container>
      <Footer />
      <BtnDenunciarUnSiniestro />
    </div>
  );
};
