import React from 'react';
import { Container } from "react-bootstrap";
import { Link } from 'react-router-dom';

import IconArrow from '../../assets/images/Novedades/IconArrow.svg';

export const Portada = ({titulo,parrafo,imagen,dirreccion}) => {
  return (
    <Container className="fondoPortadaDestacada" style={{backgroundImage: `url(${imagen})` }} >
      <section className='contenedorPortadaNovedades'>
        <h1> {titulo} </h1>
        <p> {parrafo} </p>
        <Link to={dirreccion} >Seguir leyendo <img src={IconArrow} alt="Icon Arrow" /> </Link>
      </section>
    </Container>
  )
}
