import React from 'react'
import { Container } from 'react-bootstrap'

export const TituloContacto = () => {
  return (
    <Container className="contenedorTituloContacto contenedorPersonalizadoContacto">
        <h1>Conocé los canales de atención que te ofrece <br/>
        Zurich Santander</h1>
    </Container>
    
  )
}
