import BtnAction from "../Global/BtnMasInformacion/BtnAction";
import InfoSeguros from "../InfoSeguros";

const InformacionCompras = ({ handleModal }) => {
  return (
    <>
      <div className="contenedorBtnPageItem">
        <BtnAction callback={handleModal} texto="Cotizá ahora tu seguro" />
      </div>
      <InfoSeguros />
    </>
  );
};

export default InformacionCompras;
