import { getRequest } from "./apiService";

//GET CONTRACTS
export const getContracts = async () => {
  const url = `/contratos-de-adhesions?populate=*`;
  const config = {
    headers: {
      Authorization: process.env.REACT_APP_API_TOKEN,
    },
  };

  const response = await getRequest(url, config);
  return response.data;
};
