import React from "react";
import { Container } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

export const Contenido = ({ abstract, description, image }) => {
  return (
    <Container>
      <section className="contenedorContenidoSiglePost">
        {image && <img src={image} alt="Portada" />}
        <div className="cotenedorDescriptionSinglePost">
          <p className="contenedorContenidoSiglePost-extract">{abstract}</p>
          <p className="contenedorContenidoSiglePost-description">
            <ReactMarkdown children={description} escapeHtml={false} />
          </p>
          <div className="contenedorRedesSinglePost"></div>
        </div>
      </section>
    </Container>
  );
};
