import React from 'react';
import Footer from '../components/Global/Footer/Footer'
import Header from "../components//Global/Header/Header";
import BtnDenunciarUnSiniestro from '../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro'
import { Portada } from '../components/PreguntasFrecuentes/Portada';
import { ContenedorPreguntas } from '../components/PreguntasFrecuentes/ContenedorPreguntas';
import { Copy } from '../components/PreguntasFrecuentes/Copy';

export const PreguntasFrecuentes = () => {
  return (
    <div>
        <Header />
        <Portada />
        <ContenedorPreguntas />
        <Copy />
        <Footer />
        <BtnDenunciarUnSiniestro />
    </div>
  )
}
