import Header from "../../Global/Header/Header";
import { Portada } from "../../TecnologiaProtegida/PageItem/Portada";
import { Contenido } from "../../TecnologiaProtegida/PageItem/Contenido";
import CardsServicios from "../../Home/CardsServicios/CardsServicios";
import portadaEstatica from "../../../assets/images/ComprasYgastos/ExtraccionesProtegidas/Portada.svg";
import Icon1 from "../../../assets/images/ComprasYgastos/ExtraccionesProtegidas/1.svg";
import Icon5 from "../../../assets/images/ComprasYgastos/ExtraccionesProtegidas/5.svg";
import { Breadcrumbs } from "../../Global/Breadcrumbs/Breadcrumbs";
import LegalesSeguros from "../../LegalesSeguros/LegalesSeguros";
import AccordionAsistencias from "../../AccordionAsistencias/AccordionAsistencias";
import { dataAsistencias } from "../../../utils/resources/nuevasAsistencias";
import FooterAll from "../../FooterAll";
import InformacionCompras from "../InformacionCompras";
import { useState } from "react";

export const ExtraccionesProtegidas = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModal = (v) => {
    setShowModal(v);
  };

  const ArrayItems = [
    {
      title: "primero",
      contenido: [
        {
          icon: Icon1,
          title: "Dinero extraído",
          parrafo:
            "Ante robo del monto extraído en cajeros automáticos, ventanilla o entidades no financieras, hasta dos eventos al año.",
        },
        {
          icon: Icon5,
          title: "Muerte accidental o invalidez total y permanente en ocasión de asalto.",
          parrafo: "Cuando el hecho ocurra como consecuencia de robo cubierto y dentro de los 30 días de ocurrido.",
        },
        {
          icon: Icon5,
          title: "Protección de bienes personales",
          parrafo: "Por robo de cartera, bolso, mochila y su contenido, hasta dos eventos al año.",
        },
        // {
        //   icon: Icon4,
        //   title: "Traslado en taxi o remis a la comisaría más cercana",
        //   parrafo:
        //     "Con la cobertura de robo en cajeros te brindamos diferentes servicios ante ciertas urgencias que puedas necesitar.",
        // },
      ],
    },
    {
      title: "CUANDO?",
      contenido: [
        {
          icon: Icon5,
          title: "¿Cuándo?",
          parrafo:
            "Cuando sufras un robo durante o después de haber hecho una extracción de efectivo por ventanilla, cajeros automáticos o entidades no financieras como farmacias y supermercados.",
        },
        {
          icon: Icon5,
          title: "¿Dónde?",
          parrafo:
            "En Argentina y cualquier parte del mundo. Hasta 500 metros alrededor del lugar de extracción y dentro de las dos horas de efectuada la misma. Las 24hs, todo el año.",
        },
        // {
        //   icon: Icon3,
        //   title: "¿Qué?",
        //   parrafo:
        //     "En caso de sufrir robo al realizar extracciones de efectivo, te cubre hasta $80.000 de la suma extraída de tu tarjeta Santander o tus adicionales. Las 24hs, todo el año.",
        // },
      ],
    },
  ];

  return (
    <>
      <Header />
      <Portada
        portada={portadaEstatica}
        titulo="Extracciones protegidas"
        subtitulo="Tus extracciones están protegidas"
      />
      <Contenido opcion="d" UrlBtn="/" NombreBtn="Descargar Condiciones" ArrayItems={ArrayItems} />

      <AccordionAsistencias data={dataAsistencias[0].cartera} />

      <div className="contenedorBreadcrumsCentrado">
        <Breadcrumbs modalMostrar={false} title="Descargar Condiciones" url="/" />
      </div>
      <InformacionCompras callback={() => handleModal(true)} />
      <div className="contenedorBannerServiciosBici">
        <h6>
          {" "}
          <b>Otros clientes también eligieron</b> estos productos
        </h6>
        <CardsServicios vivienda vida cartera />
      </div>
      <LegalesSeguros tipo="extracciones-protegidas" />
      <FooterAll showModal={showModal} handleModal={handleModal} />
    </>
  );
};
