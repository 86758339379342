import React from 'react';
import { Container } from "react-bootstrap";

import portada from "../../assets/images/Nosotros/portada.svg";

export const Portada = () => {
  return (
    <Container>
      <section className='contenedorHeader'>
        <h1>Somos <br/>
        Zurich Santander</h1>
        <img src={portada} alt="portada" />
      </section>  
    </Container>
    
  )
}
