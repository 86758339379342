import React from "react";
import { Container } from "react-bootstrap";

import rectangulo1 from "../../assets/images/Nosotros/rectangulo1.png";
import rectangulo2 from "../../assets/images/Nosotros/rectangulo2.png";
import rectangulo3 from "../../assets/images/Nosotros/rectangulo3.png";
import BtnMasInformacion from "../Global/BtnMasInformacion/BtnMasInformacion";

export const NuestroProposito = () => {
  return (
    <Container>
      <section className="contenedorNuestroProposito">
        <div className="contenedorNuestroProposito-title">
          <h3>
            {" "}
            Nuestro propósito, es brindar protección a las personas y empresas que lo necesiten a través de una amplia
            gama de seguros.
          </h3>
          <p>
            Somos un equipo comprometido con la sociedad. Sumamos esfuerzos para mejorar la calidad de vida de las
            personas y para ayudar a quienes más lo necesitan.
          </p>
          <BtnMasInformacion url="/sostenibilidad" texto="Conocé nuestros aportes" />
        </div>
        <div className="contenedorNuestroProposito-imgs">
          <div>
            <img src={rectangulo1} alt="Icon" />
          </div>
          <div>
            <img src={rectangulo2} alt="Icon" className="imagenIntermedia" />
            <img src={rectangulo3} alt="Icon" />
          </div>
        </div>
      </section>
    </Container>
  );
};
