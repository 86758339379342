import React from "react";
import BtnDenunciarUnSiniestro from "../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";
import Footer from "../components/Global/Footer/Footer";
import Header from "../components/Global/Header/Header";
import LegalesSeguros from "../components/LegalesSeguros/LegalesSeguros";
import { ContenidoBici } from "../components/seguroDeBici/ContenidoBici";

export const SeguroDeBici = () => {
  return (
    <div>
      <Header />
      <ContenidoBici />
      <LegalesSeguros tipo="bici" />
      <Footer />
      <BtnDenunciarUnSiniestro />
    </div>
  );
};
