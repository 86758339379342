import React, { useState } from "react";
import Header from "../../Global/Header/Header";
import { Portada } from "../../TecnologiaProtegida/PageItem/Portada";
import { Contenido } from "../../TecnologiaProtegida/PageItem/Contenido";
import CardsServicios from "../../Home/CardsServicios/CardsServicios";

import portadaEstatica from "../../../assets/images/VidaYsalud/Portadas/PortadaSeguroDeVida.svg";
import primerIcon from "../../../assets/images/VidaYsalud/IconsItems/IconCorazon.svg";
import segundoIcon from "../../../assets/images/VidaYsalud/IconsItems/IconDinero.svg";
import tercerIcon from "../../../assets/images/VidaYsalud/IconsItems/IconDescuento.svg";
import medicos from "../../../assets/images/VidaYsalud/IconsItems/medicos.png";
import ambulancia from "../../../assets/images/VidaYsalud/IconsItems/ambulancia.png";
import ortopedia from "../../../assets/images/VidaYsalud/IconsItems/ortopedia.png";
import masajes from "../../../assets/images/VidaYsalud/IconsItems/masajes.png";
import psicologia from "../../../assets/images/VidaYsalud/IconsItems/psicologia.png";
import LegalesSeguros from "../../LegalesSeguros/LegalesSeguros";
import { Container } from "react-bootstrap";
import AccordionAsistencias from "../../AccordionAsistencias/AccordionAsistencias";
import { dataAsistencias } from "../../../utils/resources/nuevasAsistencias";
import FooterAll from "../../FooterAll";
import InfoCompras from "../../ComprasYgastos/InformacionCompras";

export const AccidentesPersonales = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModal = (v) => {
    setShowModal(v);
  };

  const ArrayItems = [
    {
      icon: primerIcon,
      title: "Indemnización en caso de pérdida de la vida por accidentes",
      parrafo: "Cobertura por fallecimiento con motivo de accidente.",
    },
    {
      icon: segundoIcon,
      title: "Indemnización en caso de invalidez total o parcial y permanente por accidente.",
      parrafo: "",
    },
  ];

  const ArrayItems2 = [
    {
      icon: tercerIcon,
      title: "Medicamentos",
    },
    {
      icon: medicos,
      title: "Gastos médicos",
    },
    {
      icon: ortopedia,
      title: "Artículos ortopédicos",
      parrafo: "",
    },
    {
      icon: medicos,
      title: "Kinesilogía",
    },
  ];

  const ArrayItems3 = [
    {
      icon: medicos,
      title: "Realización de radiografias / ecografías",
    },
    {
      icon: ambulancia,
      title: "Traslados de urgencias",
    },
    {
      icon: masajes,
      title: "Masajes descontracturantes (postoperatorio)",
    },
    {
      icon: psicologia,
      title: "Asistencia Psicológica por estrés post traumático",
    },
  ];

  return (
    <>
      <Header />
      <Portada
        portada={portadaEstatica}
        titulo="Accidentes Personales"
        subtitulo="Vos y tu familia, siempre protegidos"
        height="450px"
      />
      <Contenido opcion="b" UrlBtn="/" NombreBtn="Descargar Condiciones" ArrayItems={ArrayItems} />
      <Container>
        <div className="contenedorSegundaOpcionPageItem mt-0">
          <div className="contenedorSegundaOpcionPageItem-lists">
            <div className="contenedorSegundaOpcionPageItem-lists-card">
              <div className="contenedorSegundaOpcionPageItem-lists-items">
                <h6>Además, contás con diferentes servicios de asistencias y reintegros: </h6>
              </div>
            </div>
            {ArrayItems2.map((item, index) => {
              return (
                <div key={index} className="contenedorSegundaOpcionPageItem-lists-card">
                  <img src={item.icon} alt="Icon" />
                  <div className="contenedorSegundaOpcionPageItem-lists-items">
                    <p>{item.title}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="contenedorSegundaOpcionPageItem  mt-0">
          <div className="contenedorSegundaOpcionPageItem-lists">
            {/* <div className="contenedorSegundaOpcionPageItem-lists-card">
              <div className="contenedorSegundaOpcionPageItem-lists-items">
                <h6>Y las mejores asistencias:</h6>
              </div>
            </div> */}

            {ArrayItems3.map((item, index) => {
              return (
                <div key={index} className="contenedorSegundaOpcionPageItem-lists-card">
                  <img src={item.icon} alt="Icon" style={{ width: "45px" }} />
                  <div className="contenedorSegundaOpcionPageItem-lists-items">
                    <p>{item.title}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>

      <AccordionAsistencias data={dataAsistencias[0].accidentesPersonales} />

      <InfoCompras callback={() => handleModal(true)} />
      <div className="contenedorBannerServiciosBici">
        <h6>
          {" "}
          <b>Otros clientes también eligieron</b> estos productos
        </h6>
        <CardsServicios vida vivienda extracciones />
      </div>
      <LegalesSeguros tipo="accidentes-personales" />
      <FooterAll showModal={showModal} handleModal={handleModal} />
    </>
  );
};
