import React from "react";
import Reviews from "../../../components/Global/Reviews/Reviews";
import Footer from "../../../components/Global/Footer/Footer";
import Header from "../../../components//Global/Header/Header";
import BtnDenunciarUnSiniestro from "../../../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";
import BannerContactate from "../../../components/Global/BannerContactate/BannerContactate";
import { Portada } from "../../../components/TecnologiaProtegida/Portada";
import { Cards } from "../../../components/TecnologiaProtegida/Cards";

import iconGastronomia from "../../../assets/images/HogarYnegocio/IntegralDelNegocio/iconGastronomia.svg";
import iconLocalRopa from "../../../assets/images/HogarYnegocio/IntegralDelNegocio/iconLocalRopa.svg";
import iconOficina from "../../../assets/images/HogarYnegocio/IntegralDelNegocio/iconOficina.svg";
import iconComercio from "../../../assets/images/HogarYnegocio/IntegralDelNegocio/iconComercio.svg";
import iconDoctor from "../../../assets/images/HogarYnegocio/IntegralDelNegocio/iconDoctor.svg";
import imgOtrosNegocios2 from "../../../assets/images/icons/imgOtrosNegocios.png";

export const IntegralDelNegocio = () => {
  const items = [
    {
      icono: iconGastronomia,
      subtitulo: "Cobertura para",
      titulo: "Gastronomía",
      url: "/hogar-y-negocio/negocio-protegido/gastronomia",
    },
    {
      icono: iconLocalRopa,
      subtitulo: "Cobertura para",
      titulo: "Tiendas de ropa",
      url: "/hogar-y-negocio/negocio-protegido/tiendas-de-ropa",
    },
    {
      icono: iconOficina,
      subtitulo: "Cobertura para",
      titulo: "Oficinas",
      url: "/hogar-y-negocio/negocio-protegido/oficinas",
    },
    {
      icono: iconComercio,
      subtitulo: "Cobertura para",
      titulo: "Local de Shoppings",
      url: "/hogar-y-negocio/negocio-protegido/local-de-shoppings",
    },
    {
      icono: iconDoctor,
      subtitulo: "Cobertura para",
      titulo: "Consultorios Médicos",
      url: "/hogar-y-negocio/negocio-protegido/consultorios-medicos",
    },
    {
      icono: imgOtrosNegocios2,
      subtitulo: "Cobertura para",
      titulo: "Otros negocios",
      url: "/hogar-y-negocio/negocio-protegido/otros-negocios",
    },
  ];
  return (
    <div>
      <Header />
      <Portada subTitulo="La cobertura justa para cada negocio" titulo="Negocio Protegido" />
      <Cards items={items} />
      <Reviews />
      <div className="contenedorBannerContactateTecnologiaProtegida">
        <BannerContactate />
      </div>
      <Footer />
      <BtnDenunciarUnSiniestro />
    </div>
  );
};
