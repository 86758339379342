import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const getRequest = (path, config) => {
  return axiosInstance.get(path, config);
};

export const putRequest = (path, body, config) => {
  return axiosInstance.put(path, body, config);
};

export const postRequest = (path, body, config) => {
  return axiosInstance.post(path, body, config);
};

export const deleteRequest = (path, body, config) => {
  return axiosInstance.delete(path);
};
