import React from "react";
import BtnMasInformacion from "../../Global/BtnMasInformacion/BtnMasInformacion";

const CardsItems = ({ icono, title, category, direccion }) => {
  return (
    <div className="cards-items">
      <img src={icono} className="icon-card-items" alt={title} />
      <p className="subtitulo-gris"> {category} </p>
      <h3 className="titulos-cards"> {title} </h3>
      <BtnMasInformacion url={direccion} texto="Más información" />
    </div>
  );
};

export default CardsItems;
