import { Container } from "react-bootstrap";
import BtnMasInformacion from "../../Global/BtnMasInformacion/BtnMasInformacion";

const HeaderTop = ({ extract, title, subtitle, desc, url, containerClass, sectionClass, placeholder }) => {
  return (
    <Container fluid className={["section-portada-top", containerClass]}>
      <Container>
        <section className="p-0 p-3">
          <div className={sectionClass}>
            {extract && <h2 className="extract-azul">{extract}</h2>}
            {title && <h2 className="subtitulo-gris">{title}</h2>}
            {subtitle && <h1 className="titulo-principal">{subtitle}</h1>}
            {desc && <p className="parrafo-general pb-5">{desc}</p>}
            {url && placeholder && <BtnMasInformacion url={url} texto={placeholder} />}
          </div>
        </section>
      </Container>
    </Container>
  );
};

export default HeaderTop;
