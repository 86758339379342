import React from "react";
import { Container } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import IconTooltip from "../../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/IconTooltip.svg";

export const Contenido = ({
  opcion,
  UrlBtn,
  NombreBtn,
  ArrayItems,
  asistenciasEspeciales = false,
  tituloProteccion,
  proteccion,
}) => {
  return (
    <Container>
      <section>
        {opcion === "b" && (
          <div className="contenedorSegundaOpcionPageItem">
            <div className="contenedorSegundaOpcionPageItem-title">
              <h3>
                <b>La protección que necesitás</b> <br />
                ante siniestros inesperados
              </h3>
            </div>
            <div className="contenedorSegundaOpcionPageItem-lists">
              {ArrayItems.map((item, index) => {
                return (
                  <div key={index} className="contenedorSegundaOpcionPageItem-lists-card">
                    <img src={item.icon} alt="Icon" />
                    <div className="contenedorSegundaOpcionPageItem-lists-items">
                      <h6>{item.title}</h6>
                      <p>{item.parrafo}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {opcion === "c" && (
          <div className="contenedorSegundaOpcionPageItem">
            <div className="contenedorSegundaOpcionPageItem-title">
              <h3>
                <b>Tu {tituloProteccion ? tituloProteccion : "hogar"} más protegido</b> <br />
                que nunca
              </h3>
            </div>
            <div className="contenedorSegundaOpcionPageItem-lists">
              {ArrayItems[0].contenido.map((item, index) => {
                return (
                  <div key={index} className="contenedorSegundaOpcionPageItem-lists-card contenedorOpcionC">
                    <img src={item.icon} alt="Icon" />
                    <div className="contenedorSegundaOpcionPageItem-lists-items">
                      <h6>{item.title} </h6>
                      {item.parrafo && <p>{item.parrafo}</p>}
                    </div>
                    <OverlayTrigger
                      key="right"
                      placement="right"
                      overlay={<Tooltip id="tooltip-right">{item.toltip}</Tooltip>}
                    >
                      <img src={IconTooltip} alt="Icon" />
                    </OverlayTrigger>
                  </div>
                );
              })}
              <h2 className="tituloAdicionalesContenido">Adicionales</h2>
              {ArrayItems[1].contenido.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="contenedorSegundaOpcionPageItem-lists-card cardAdicionalesContenido contenedorOpcionC"
                  >
                    <img src={item.icon} alt="Icon" />
                    <div className="contenedorSegundaOpcionPageItem-lists-items">
                      <h6>{item.title}</h6>
                      {item.parrafo && <p>{item.parrafo}</p>}
                    </div>
                    {item.toltip && (
                      <OverlayTrigger
                        key="right"
                        placement="right"
                        overlay={<Tooltip id="tooltip-right">{item.toltip}</Tooltip>}
                      >
                        <img src={IconTooltip} alt="Icon" />
                      </OverlayTrigger>
                    )}
                  </div>
                );
              })}
              <h2 className="tituloAsistenciasContenido">Asistencias</h2>

              {!asistenciasEspeciales &&
                ArrayItems[2]?.contenido.map((itema, index) => {
                  return (
                    <div key={index}>
                      <h4 className="subTituloAsistenciasContenido">{itema.titulo}</h4>
                      {itema.contenido.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="contenedorSegundaOpcionPageItem-lists-card cardAsitenciasContenido d-flex justify-content-start align-content-start align-items-start"
                          >
                            <img src={item.icon} alt="Icon" />
                            <div className="contenedorSegundaOpcionPageItem-lists-items">
                              <h6>{item.title}</h6>
                              <p>{item.parrafo}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}

              {asistenciasEspeciales && (
                <div>
                  <div className="seccionGrisAsistenciasEspeciales">
                    <p>Contás con diferentes servicios de asistencias que podés necesitar ante algunas urgencias:</p>
                  </div>
                  {ArrayItems[2].contenido.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="contenedorSegundaOpcionPageItem-lists-card cardAdicionalesContenido contenedorOpcionC d-flex  justify-content-start flex-row align-content-start align-items-start"
                      >
                        <img src={item.icon} alt="Icon" />

                        <div className="contenedorSegundaOpcionPageItem-lists-items pt-2" style={{ marginLeft: 0 }}>
                          <h6 className="tituloSpanNuevo">
                            {item.title} {item.nuevo && <span>Nuevo</span>}{" "}
                          </h6>

                          {item.parrafo && <p>{item.parrafo}</p>}
                        </div>
                        <div className="pt-1">
                          <OverlayTrigger
                            key="right"
                            placement="right"
                            overlay={<Tooltip id="tooltip-right">{item.toltip}</Tooltip>}
                          >
                            <img src={IconTooltip} alt="Icon" />
                          </OverlayTrigger>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
        {opcion === "d" && (
          <div className="contenedorSegundaOpcionPageItem">
            <div className="contenedorSegundaOpcionPageItem-title">
              {proteccion === "cartera" && (
                <>
                  {" "}
                  <h3>Contás con las siguientes coberturas</h3>
                </>
              )}
              {proteccion === "compras" && (
                <>
                  {" "}
                  <h3>Tus compras protegidas durante 120 días</h3>
                </>
              )}

              {!proteccion && (
                <>
                  {" "}
                  <h3>¡Sacá tu dinero con tranquilidad donde estés!</h3>
                  <p className="text-center pt-3">Si sufris un robo luego de una extracción de dinero, te cubrimos:</p>
                </>
              )}
            </div>
            <div className="contenedorSegundaOpcionPageItem-lists">
              {ArrayItems[0].contenido.map((item, index) => {
                return (
                  <div key={index} className="contenedorSegundaOpcionPageItem-lists-card contenedorOpcionC">
                    <img src={item.icon} alt="Icon" />
                    <div className="contenedorSegundaOpcionPageItem-lists-items">
                      <h6>{item.title} </h6>
                      <p>{item.parrafo}</p>
                    </div>
                  </div>
                );
              })}
              {ArrayItems[1] && <h2 className="tituloAdicionalesContenido">CONDICIONES</h2>}
              {ArrayItems[1]?.contenido.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="contenedorSegundaOpcionPageItem-lists-card cardAdicionalesContenido contenedorOpcionC"
                  >
                    <img src={item.icon} alt="Icon" />
                    <div className="contenedorSegundaOpcionPageItem-lists-items">
                      <h6>{item.title}</h6>
                      <p>{item.parrafo}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </section>
    </Container>
  );
};
