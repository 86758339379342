import React from "react";
import { Container } from "react-bootstrap";

import logo1 from "../../assets/images/Sostenibilidad/logos/1.png";
import logo2 from "../../assets/images/Sostenibilidad/logos/2.png";
import logo3 from "../../assets/images/Sostenibilidad/logos/3.png";
import logo4 from "../../assets/images/Sostenibilidad/logos/4.png";
import logo5 from "../../assets/images/Sostenibilidad/logos/5.png";

export const TrabajamosJuntos = () => {
  return (
    <Container>
      <section className="contenedorTrabajemosJuntos">
        <h4>Trabajamos junto a las siguientes organizaciones</h4>
        <div className="contenedorTrabajemosJuntos-logos">
          <a href="https://fondationforge.org/" target={"_blank"} rel="noreferrer">
            <img src={logo1} alt="Logo" />
          </a>
          <a href="https://www.programaempujar.org/" target={"_blank"} rel="noreferrer">
            <img src={logo2} alt="Logo" />
          </a>
          <a href="https://fundaciontodaviaestiempo.org.ar/" target={"_blank"} rel="noreferrer">
            <img src={logo3} alt="Logo" />
          </a>
          <a href="https://conciencia.org/" target={"_blank"} rel="noreferrer">
            <img src={logo4} alt="Logo" />
          </a>
          <a href="https://www.zurich.foundation/" target={"_blank"} rel="noreferrer">
            <img src={logo5} alt="Logo" />
          </a>
        </div>
      </section>
    </Container>
  );
};
