import React from "react";
import { Container } from "react-bootstrap";
import { CardNotWithIcons } from "./CardNotWithIcons";

export const SectionSinIconos = () => {
  return (
    <Container className="contenedorPersonalizadoContacto">
      <section className="contenedorCardNotWithIcons">
        <CardNotWithIcons
          titulo="¿Tenés alguna duda o consulta sobre nuestros seguros?"
          parrafo={"Te ayudamos. Encontrá las respuestas a las preguntas más frecuentes o podés escribirnos a atencionalasegurado@zurich-santander.com.ar."}
          direccion="/preguntas-frecuentes"
          nombreBtn="Ir a preguntas frecuentes"
        />
        {/* <CardNotWithIcons
          titulo="Tu opinión nos interesa"
          parrafo="Realizamos encuestas de satisfacción para brindarte un mejor servicio."
          direccion="/"
          nombreBtn="Realizar encuesta"
        /> */}
        <CardNotWithIcons
          titulo="Trabajá en Zurich Santander"
          parrafo="Conocé todas nuestras búsquedas"
          direccion="https://www.linkedin.com/jobs/search/?currentJobId=3260134823&f_C=35485049&geoId=92000000"
          nombreBtn="Mas información"
          target={"_blank"}
        />
      </section>
    </Container>
  );
};
