import React from "react";
import Reviews from "../components/Global/Reviews/Reviews";
import Footer from "../components/Global/Footer/Footer";
import Header from "../components//Global/Header/Header";
import BtnDenunciarUnSiniestro from "../components/Global/BtnDenunciarUnSiniestro/BtnDenunciarUnSiniestro";
import BannerContactate from "../components/Global/BannerContactate/BannerContactate";
import { Portada } from "../components/TecnologiaProtegida/Portada";
import { Cards } from "../components/TecnologiaProtegida/Cards";

import iconVida from "../assets/images/VidaYsalud/iconVida.svg";
import iconAccidentesPersonales from "../assets/images/VidaYsalud/iconAccidentesPersonales.svg";

export const VidaYsalud = () => {
  const items = [
    {
      icono: iconVida,
      subtitulo: "Vos y tu familia, siempre protegidos",
      titulo: "Vida",
      url: "/vida-y-salud/vida",
    },
    {
      icono: iconAccidentesPersonales,
      subtitulo: "la protección que necesitas",
      titulo: "Accidentes Personales",
      url: "/vida-y-salud/accidentes-personales",
    },
  ];
  return (
    <div>
      <Header />
      <Portada subTitulo="Vos y tu familia, siempre protegidos" titulo="Vida y Salud" />
      <Cards items={items} />

      <Reviews />
      <div className="contenedorBannerContactateTecnologiaProtegida">
        <BannerContactate />
      </div>

      <Footer />
      <BtnDenunciarUnSiniestro />
    </div>
  );
};
