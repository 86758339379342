import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import BtnMasInformacion from "../Global/BtnMasInformacion/BtnMasInformacion";

import IconClose from "../../assets/images/TuveUnSiniestro/IconClose.svg";

import IconDocument from "../../assets/images/TuveUnSiniestro/IconDocument.svg";

import Step1 from "../../assets/images/TuveUnSiniestro/tipoA/Step1.svg";
import Step2 from "../../assets/images/TuveUnSiniestro/tipoA/Step2.svg";
import Step3 from "../../assets/images/TuveUnSiniestro/tipoA/Step3.svg";
import Step4 from "../../assets/images/TuveUnSiniestro/tipoA/Step4.svg";

export const ModalSiniestro = ({ estadoBoton, cerrarModal, Icono, Titulo, URLdocumentacion, formato }) => {
  return (
    <div className="contenedorModal">
      <Modal show={estadoBoton} onHide={cerrarModal} className="contenedorModal" centered>
        <div className="contenedorBodyModal">
          <div className="contenedorBodyModal-title">
            <div>
              <img src={Icono} alt={Titulo} />
              <h4>
                Denunciá siniestro <span style={{ textTransform: "capitalize" }}>{Titulo}</span>{" "}
              </h4>
            </div>
            <img onClick={() => cerrarModal()} src={IconClose} alt="Icon Close" />
          </div>

          {formato === "tipoA" ? (
            <div className="contenedorDinamico-formato-a">
              <div className="contenedorBodyModal-text">
                <p>
                  Para realizar la denuncia de un siniestro, seguí estos simples pasos desde tu Online Banking
                  Santander.
                </p>
              </div>
              <div className="contenedorBodyModal-icons">
                <div>
                  <img src={Step1} alt="Icon Step 1" />
                  <p>
                    Desde Online Banking ingresá en la sección <b> “Seguros”</b>
                  </p>
                </div>

                <div>
                  <img src={Step2} alt="Icon Step 2" />
                  <p>
                    <b>Seleccioná el seguro</b> y hac click en <b>“Denuncia de Siniestro”</b>
                  </p>
                </div>

                <div>
                  <img src={Step3} alt="Icon Step 3" />
                  <p>
                    <b>Completá la información</b> solicitada
                  </p>
                </div>

                <div>
                  <img src={Step4} alt="Icon Step 4" />
                  <p>
                    <b>Ingresá un mail de contacto</b> para recibir las novedades de tu siniestro <b>y ¡listo!</b>
                  </p>
                </div>
              </div>
              <div className="contenedorBodyModal-btn">
                <a
                  href="https://www2.personas.santander.com.ar/obp-webapp/angular/#!/login"
                  className="btnMasInformacion"
                  target="_blank"
                  texto=""
                  rel="noreferrer"
                >
                  Ingresar a mi Online Banking
                </a>
              </div>
            </div>
          ) : null}
          {formato === "tipoB" ? (
            <div className="contenedorDinamico-formato-b">
              <div className="contenedorBodyModal-text">
                <p>
                  Para realizar la denuncia de un siniestro de{" "}
                  <span style={{ textTransform: "capitalize" }}>{Titulo}</span> , es necesario acercarse a una sucursal
                  del Banco Santander.
                </p>
              </div>
              <div className="contenedorBodyModal-btn">
                <BtnMasInformacion url="/" texto="Ver sucursales más cercanas" />
              </div>
              <p className="textGrey">Se abrirá el sitio en una nueva ventana.</p>
            </div>
          ) : null}
          {formato === "tipoC" ? (
            <div className="contenedorDinamico-formato-c">
              <div className="contenedorBodyModal-text">
                <p>Podés hacer la denuncia de un siniestro, de manera fácil y rápida llamando al:</p>
              </div>
              <div className="contenedorBodyModal-btn-c">
                <a href="tel:08001220905">0800 122 0905</a>
                <a href="tel:08001220906">0800 122 0906</a>
              </div>
              <p className="text-center pt-4">De lunes a viernes de 9 a 18hs</p>
            </div>
          ) : null}

          <div className="contenedorBodyModal-pdf">
            <p>
              <img src={IconDocument} alt="Icon Document" /> Descargá la documentación necesaria desde{" "}
              <a href={URLdocumentacion} target={"_blank"} rel="noreferrer">
                acá
              </a>
            </p>
          </div>
          <div className="contenedorBodyModal-tel">
            {formato === "tipoC" ? (
              <p>
                {" "}
                Si tenés dudas consultá nuestras <Link to="/preguntas-frecuentes">Preguntas frecuentes</Link>
              </p>
            ) : (
              <p>
                {" "}
                Si tenés dudas consultá nuestras <Link to="/preguntas-frecuentes">Preguntas frecuentes</Link> o llamános
                al <a href="tel:08001220905">0800-122-0905</a> ó <a href="tel:08001220906">0800-122-0906</a>
              </p>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
