import React from "react";
import { Container } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import IconInformationCircle from "../../../assets/images/Global/IconInformationCircle.svg";
import LogoSantander from "../../../assets/images/HogarYnegocio/Vivienda/logoSantander.png";

export const SantanderSelect = ({ items }) => {
  return (
    <Container fluid style={{ backgroundColor: "#7C8587" }}>
      <Container>
        <section className="contenedorSantanderSelect">
          <div className="contenedorSantanderSelect-title">
            <img src={LogoSantander} alt="Logo Santander" className="contenedorSantanderSelect-logo" />
            <p className="contenedorSantanderSelect-parrafo">
              Con Santander Select <b>tenés estos beneficios</b>{" "}
            </p>
          </div>
          <div className="contenedorSantanderSelect-cards">
            {items.map((item, index) => {
              return (
                <div className="contenedorSantanderSelect-card-individual" key={index}>
                  <div className="contenedorSantanderSelect-card-individual-img">
                    <img src={item.icono} alt="Icon" />
                  </div>
                  <div className="contenedorSantanderSelect-card-individual-texto">
                    <p>{item.parrafo} </p>
                    <OverlayTrigger
                      key="right"
                      placement="right"
                      overlay={<Tooltip id="tooltip-right">{item.title}</Tooltip>}
                    >
                      <img src={IconInformationCircle} alt="Icon" />
                    </OverlayTrigger>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </Container>
    </Container>
  );
};
