import Header from "../../../Global/Header/Header";
import { Portada } from "../../../TecnologiaProtegida/PageItem/Portada";
import portadaEstatica from "../../../../assets/images/HogarYnegocio/IntegralDelNegocio/Gastronomia/portada.svg";
import { gastronomia } from "../../../../utils/resources/coberturasyasistencias";
import ContenidoNegocio from "./ContenidoNegocio";

const Gastronomia = () => {
  const ArrayItems = gastronomia;

  return (
    <>
      <Header />
      <Portada portada={portadaEstatica} titulo="Gastronomía" subtitulo="La cobertura justa para tu negocio" />
      <ContenidoNegocio ArrayItems={ArrayItems} />
    </>
  );
};

export default Gastronomia;
